import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CookiesService } from 'src/app/services/cookie.service';

@Component({
    selector: 'app-cookie-banner',
    templateUrl: './cookie-banner.component.html',
    styleUrls: ['./cookie-banner.component.css']
})
export class CookieBannerComponent {
    constructor(
        private router: Router,
        private cookiesService: CookiesService
    ) {}

    navigateTo(link: string): void {
        this.router.navigate([link]);
    }

    closeBanner(): void {
        this.cookiesService.cookieAccepted();
    }
}
