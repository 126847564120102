import {
    Component,
    Inject,
    OnInit,
    OnDestroy,
    ViewChild,
    ElementRef
} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AvailableContentTypes } from 'src/app/modules/diliboard/diliboard.enums';
import { Diliboard } from 'src/app/models/diliboard';
import { Store, select, ActionsSubject, Action } from '@ngrx/store';
import {
    StartWizard,
    GoToFirstStep,
    GoToSecondStep,
    StartWizardWithAttachment,
    UpdateWizard,
    ErrorWizard,
    DiliboardActionType
} from '../../../store/diliboard.actions';
import {
    selectWizardData,
    selectWizardDataLoader,
    selectWizardDataErrorMessage
} from '../../../store/diliboard.selector';
import { Observable, Subscription } from 'rxjs';
import { WizardSessionModel } from '../../../models/wizard-session.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-add-content',
    templateUrl: './add-content.component.html',
    styleUrls: ['./add-content.component.css']
})
export class AddContentComponent implements OnInit, OnDestroy {
    @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;
    files = [];
    boardId: number;
    boardTitle: string;
    contentStep: number = 0;
    url: string = null;
    selectedDisplay: string = null;
    wizardData$: Observable<WizardSessionModel>;
    wizardLoader$: Observable<boolean>;
    wizardError$: Observable<{
        error: string;
        errorStep: number;
    }>;
    embedded: string = AvailableContentTypes.embedded;
    descriptionWithLink: string = AvailableContentTypes.descriptionWithLink;
    additionalInformation = {
        title: {
            value: null,
            required: false
        },
        description: {
            value: null,
            required: false
        }
    };
    attachmentForm: FormGroup;
    disableURL: boolean = false;
    disableUploadSection: boolean = false;
    attachment: string;
    tooBigAttachment: boolean;
    private subscription: Subscription = new Subscription();

    constructor(
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) data: Diliboard,
        private store: Store<any>,
        private formBuilder: FormBuilder,
        private actions: ActionsSubject
    ) {
        this.boardTitle = data.title;
        this.boardId = data.id;
    }

    ngOnInit() {
        this.wizardData$ = this.store.pipe(select(selectWizardData));
        this.wizardLoader$ = this.store.pipe(select(selectWizardDataLoader));

        this.subscription.add(
            this.wizardData$.subscribe((res: WizardSessionModel) => {
                if (res && res.available_extra_attributes) {
                    Object.keys(res.available_extra_attributes).map((value) => {
                        this.additionalInformation[value]['required'] =
                            res.available_extra_attributes[value]['required'];
                    });
                }
            })
        );

        this.wizardError$ = this.store.pipe(
            select(selectWizardDataErrorMessage)
        );

        this.subscription.add(
            this.store
                .pipe(select(selectWizardDataErrorMessage))
                .subscribe((res: { error: string; errorStep: number }) => {
                    if (res.error && res.errorStep === 0) {
                        this.contentStep = res.errorStep;
                    }
                })
        );

        this.attachmentForm = this.formBuilder.group({
            attachment: [null, Validators.required]
        });

        this.subscription.add(
            this.actions.subscribe((action: Action) => {
                if (
                    action.type ===
                    DiliboardActionType.RESET_DILIBOARDS_AND_CONTENT
                ) {
                    this.closeDialog();
                } else if (
                    action.type === DiliboardActionType.TOO_BIG_ATTACHMENT
                ) {
                    this.tooBigAttachment = true;
                    this.contentStep = 0;
                } else if (action.type === DiliboardActionType.UPDATE_WIZARD) {
                    this.tooBigAttachment = false;
                }
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    closeDialog(): void {
        this.dialog.closeAll();
    }

    setHowToContentDisplay(params: string): void {
        this.selectedDisplay = params;
    }

    back(): void {
        this.contentStep = this.contentStep > 0 ? this.contentStep - 1 : 0;
    }

    next(): void {
        this.dispatchWizardStep(this.contentStep);
        this.contentStep = this.contentStep < 2 ? this.contentStep + 1 : 2;
    }

    stepValidation(): boolean {
        if (this.contentStep === 0) {
            return !this.url && !this.attachment;
        } else if (this.contentStep === 1) {
            return !this.selectedDisplay;
        } else if (this.contentStep === 2) {
            let outs: boolean = true;
            Object.keys(this.additionalInformation).map((val) => {
                if (this.additionalInformation[val]['required']) {
                    outs = outs && !this.additionalInformation[val]['value'];
                }
            });
            return outs;
        }
    }

    filterSecondStep(arr: string[], params: string): boolean {
        return arr.includes(params);
    }

    detectInputURLChange(value: string): void {
        this.disableURL = false;
        if (value.length > 0) {
            this.disableUploadSection = true;
        } else {
            this.disableUploadSection = false;
        }
    }

    onFileSelect(event): void {
        if (event.target.files.length > 0) {
            this.disableUploadSection = false;
            this.disableURL = true;
            const file = event.target.files[0];
            this.attachmentForm.get('attachment').setValue(file);
            this.attachment = 'added';
        }
    }

    removeFile(): void {
        this.fileUpload.nativeElement.value = '';
        this.attachmentForm.get('attachment').setValue(null);
        this.disableURL = false;
        this.disableUploadSection = false;
        this.attachment = null;
        this.tooBigAttachment = false;
    }

    validateYoutubeTitleStepTwo(): boolean {
        return !!this.url
            ? !!this.url.match(
                  /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})?$/
              )
            : false;
    }

    private dispatchWizardStep(step: number): void {
        switch (step) {
            case 0:
                if (!!this.attachment) {
                    const attachment = this.attachmentForm.get('attachment')
                        .value;
                    this.store.dispatch(
                        new StartWizardWithAttachment({
                            boardId: this.boardId,
                            attachment
                        })
                    );
                } else {
                    this.store.dispatch(
                        new StartWizard({
                            url: this.url,
                            boardId: this.boardId
                        })
                    );
                }

                break;
            case 1:
                this.store.dispatch(
                    new GoToFirstStep({
                        body: { display_type: this.selectedDisplay },
                        boardId: this.boardId
                    })
                );
                break;
            case 2:
                this.store.dispatch(
                    new GoToSecondStep({
                        body: {
                            extra_attributes: {
                                title: this.additionalInformation.title.value,
                                description: this.additionalInformation
                                    .description.value
                            }
                        },
                        boardId: this.boardId
                    })
                );
                break;
        }
    }
}
