import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

const defaultInterval: number = 10000;

@Component({
    selector: 'app-home-carousel',
    templateUrl: './home-carousel.component.html',
    styleUrls: ['./home-carousel.component.css']
})
export class HomeCarouselComponent implements OnInit, OnDestroy {
    videoOverlayFlag: boolean = false;
    language: string;
    interval = defaultInterval;
    private subscription: Subscription = new Subscription();

    constructor(private translateService: TranslateService) {}

    ngOnInit() {
        this.language = this.translateService.currentLang;

        this.subscription.add(
            this.translateService.onLangChange.subscribe(
                (event: LangChangeEvent) => {
                    this.language = event.lang;
                }
            )
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    showHideVideoOverlay(): void {
        this.videoOverlayFlag = !this.videoOverlayFlag;
    }
}
