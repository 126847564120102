import { NgModule } from '@angular/core';
import { DataChangeService } from 'src/app/services';
import { SharedModule } from 'src/app/shared.module';
import { SharedStuffModule } from 'src/app/modules/shared-stuff/shared-stuff.module';
import { StoreModule } from '@ngrx/store';
import { userStateName } from './store/user.state';
import { userReducer } from './store/user.reducer';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './store/user.effect';
import { UserService } from './user.service';
import {
    AccountComponent,
    ForgotPasswordComponent,
    LoginComponent,
    RegisterComponent,
    ResetPasswordComponent,
    UserPhotoComponent
} from './components';

@NgModule({
    declarations: [
        AccountComponent,
        ForgotPasswordComponent,
        LoginComponent,
        RegisterComponent,
        ResetPasswordComponent,
        UserPhotoComponent
    ],
    imports: [
        SharedModule,
        SharedStuffModule,
        StoreModule.forFeature(userStateName, userReducer),
        EffectsModule.forFeature([UserEffects])
    ],
    providers: [UserService, DataChangeService],
    exports: [
        AccountComponent,
        ForgotPasswordComponent,
        LoginComponent,
        RegisterComponent,
        ResetPasswordComponent,
        UserPhotoComponent
    ]
})
export class UserModule {}
