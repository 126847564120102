import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Diliboard } from 'src/app/models/diliboard';
import { Store } from '@ngrx/store';
import { CreateBoard } from '../../../store/diliboard.actions';

@Component({
    selector: 'app-create-board',
    templateUrl: './create-board.component.html',
    styleUrls: ['./create-board.component.css']
})
export class CreateBoardComponent implements OnInit {
    diligramForm: FormGroup;

    constructor(
        private diligramBuilder: FormBuilder,
        private dialogRef: MatDialogRef<CreateBoardComponent>,
        private store: Store<any>
    ) {}

    ngOnInit() {
        this.diligramForm = this.diligramBuilder.group({
            title: ['', [Validators.required, Validators.maxLength(90)]],
            description: ['abcd', [Validators.required]],
            color: ['']
        });
    }

    saveDiligram(): void {
        const diliboard: Diliboard = Object.assign(
            {},
            this.diligramForm.value,
            {
                public: false
            }
        );

        this.store.dispatch(new CreateBoard({ diliboard }));
    }

    closeDialog(): void {
        this.dialogRef.close();
    }
}
