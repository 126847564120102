<h1 class="register-title">
    {{ 'user.register.title' | translate }}
</h1>
<form [formGroup]="registerForm">
    <div class="form-group">
        <label class="register-label">{{
            'user.register.first-name' | translate
        }}</label>
        <input
            matInput
            formControlName="first_name"
            placeholder="John"
            type="text"
            (keyup.enter)="register()"
        />
    </div>
    <div class="form-group">
        <label class="register-label">{{
            'user.register.last-name' | translate
        }}</label>
        <input
            matInput
            formControlName="last_name"
            placeholder="Smith"
            type="text"
            (keyup.enter)="register()"
        />
    </div>
    <div class="form-group">
        <label class="register-label">{{
            'user.register.email' | translate
        }}</label>
        <input
            [ngClass]="{
                'email-field-error': emailErrorMessage
            }"
            matInput
            formControlName="email"
            placeholder="john@gmail.com"
            type="email"
            (keyup.enter)="register()"
        />
        <p *ngIf="emailErrorMessage" class="email-error-msg">
            <img
                class="close-icons"
                src="/assets/img/icons/alert.svg"
                alt="alert_icon"
            />
            {{ emailErrorMessage | translate }}
        </p>
    </div>
    <div class="form-group">
        <label class="register-label">{{
            'user.register.password' | translate
        }}</label>
        <input
            matInput
            formControlName="password"
            type="password"
            (keyup.enter)="register()"
        />
    </div>
    <div class="form-group">
        <label class="register-label">{{
            'user.register.confirm-password' | translate
        }}</label>
        <input
            matInput
            formControlName="confirmPassword"
            type="password"
            (keyup.enter)="register()"
        />
        <p *ngIf="passwordErrorMessage" class="password-error-msg">
            <img
                class="close-icons"
                src="/assets/img/icons/alert.svg"
                alt="alert_icon"
            />
            {{ passwordErrorMessage | translate }}
        </p>
    </div>
</form>
<button
    class="btn-primary center-btn"
    type="submit"
    (click)="register()"
    [disabled]="registerForm.invalid"
>
    {{ 'user.register.register-action' | translate }}
</button>
<div class="register-got-account">
    <p>{{ 'user.register.got-account' | translate }}</p>
    <p class="register-login" (click)="login()">
        {{ 'user.register.sign-in' | translate }}
    </p>
</div>
