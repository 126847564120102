<div *ngIf="!isResetSuccess">
    <h1 class="dialog-title">{{ 'user.reset-password.title' | translate }}</h1>
    <p class="description">
        {{ 'user.reset-password.description' | translate }}
    </p>
    <mat-dialog-content>
        <form [formGroup]="resetPasswordForm">
            <input
                formControlName="password"
                placeholder="{{
                    'user.reset-password.new-password' | translate
                }}"
                (keyup.enter)="changePassword()"
                type="password"
            />
            <input
                formControlName="confirmPassword"
                placeholder="{{
                    'user.reset-password.re-enter-password' | translate
                }}"
                type="password"
                (keyup.enter)="changePassword()"
            />
            <div
                class="error-msg"
                *ngIf="resetPasswordForm.hasError('notSame')"
            >
                <img
                    class="close-icons"
                    src="/assets/img/icons/alert.svg"
                    alt="alert_icon"
                />
                {{ 'user.reset-password.password-mismatch' | translate }}
            </div>
        </form>
    </mat-dialog-content>
</div>
<div *ngIf="!isResetSuccess">
    <button
        class="btn-primary center-btn"
        type="submit"
        [disabled]="resetPasswordForm.invalid"
        (click)="changePassword()"
    >
        {{ 'user.reset-password.reset-password-action' | translate }}
    </button>
</div>
<div *ngIf="isResetSuccess">
    <h1 class="dialog-title">
        {{ 'user.reset-password.change-success-title' | translate }}
    </h1>
    <p class="description">
        {{ 'user.reset-password.change-success-description' | translate }}
    </p>
</div>
