<mdb-carousel [animation]="'slide'" [interval]="interval">
    <mdb-carousel-item>
        <div class="journey">
            <p class="home-empower-text">
                {{ 'home.journey.title' | translate }}
            </p>
            <div class="carousel-wrapper" *ngIf="!videoOverlayFlag">
                <div class="intro hide-desktop">
                    <ul>
                        <li>
                            {{ 'home.journey.intro.first-bullet' | translate }}
                        </li>
                        <li>
                            {{ 'home.journey.intro.second-bullet' | translate }}
                        </li>
                        <li>
                            {{ 'home.journey.intro.third-bullet' | translate }}
                        </li>
                        <li>
                            {{ 'home.journey.intro.fourth-bullet' | translate }}
                        </li>
                    </ul>
                    <button
                        class="btn-primary watch-btn"
                        (click)="showHideVideoOverlay()"
                    >
                        {{ 'home.journey.watch' | translate }}
                        <span>
                            <img
                                src="/assets/img/icons/play.svg"
                                alt="play.svg"
                                class="play"
                        /></span>
                    </button>
                </div>
                <img
                    src="/assets/img/new-img/welcome-to-diligram.svg"
                    alt="welcome-to-diligram.svg"
                    class="welcome-to-diligram"
                />
                <div class="intro hide-mobile">
                    <ul>
                        <li>
                            {{ 'home.journey.intro.first-bullet' | translate }}
                        </li>
                        <li>
                            {{ 'home.journey.intro.second-bullet' | translate }}
                        </li>
                        <li>
                            {{ 'home.journey.intro.third-bullet' | translate }}
                        </li>
                        <li>
                            {{ 'home.journey.intro.fourth-bullet' | translate }}
                        </li>
                    </ul>
                    <button
                        class="btn-primary"
                        (click)="showHideVideoOverlay()"
                    >
                        {{ 'home.journey.watch' | translate }}
                        <span>
                            <img
                                src="/assets/img/icons/play.svg"
                                alt="play.svg"
                                class="play"
                        /></span>
                    </button>
                </div>
            </div>
            <img
                src="/assets/img/icons/cancel_icon_white.svg"
                alt="cancel_icon"
                class="close-icon"
                *ngIf="videoOverlayFlag"
                (click)="showHideVideoOverlay()"
            />
            <div class="video-overlay" *ngIf="videoOverlayFlag">
                <iframe
                    src="https://player.vimeo.com/video/408318437"
                    height="360"
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    allowfullscreen
                    *ngIf="language === 'en'"
                ></iframe>
                <iframe
                    src="https://player.vimeo.com/video/408318512"
                    height="360"
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    allowfullscreen
                    *ngIf="language === 'de'"
                ></iframe>
            </div>
        </div> </mdb-carousel-item
    ><mdb-carousel-item
        class="first-part"
        [ngClass]="{ hide: videoOverlayFlag }"
    >
        <p class="home-empower-text">
            {{ 'home.empower' | translate }}
        </p>
        <img
            src="/assets/img/icons/sign-in-image.svg"
            alt="First slide"
            class="home-image"
        />
    </mdb-carousel-item>
</mdb-carousel>
