import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserService } from '../modules/user/user.service';

@Injectable()
export class DataService {
    headers = this.userService.getHeaders();
    options = this.userService.getOptions();

    constructor(private http: HttpClient, private userService: UserService) {}

    // Diliboard

    getAllDiliboards(query?: string): Observable<any> {
        return this.getData(`diliboards.json${query ? query : ''}`, {
            headers: this.headers,
            options: this.options
        });
    }

    rejectInvite(body: any): Observable<any> {
        return this.putData(`invites/reject.json`, body, {
            headers: this.headers,
            options: this.options
        });
    }

    //  Diliboard Content
    createDiliboardContent(body: any, boardId: number): Observable<any> {
        return this.postData(
            `diliboards/${boardId}/diliboard_contents.json`,
            body,
            {
                headers: this.headers,
                options: this.options
            }
        );
    }

    getUrl(): string {
        return environment.apiBaseURL;
    }

    getData(params: string, dataOptions: any): Observable<any> {
        const url: string = this.getUrl() + params;
        return this.http.get(url, dataOptions).pipe(
            map((response) => {
                return response;
            }),
            catchError((error: HttpErrorResponse) => this.handleError(error))
        );
    }

    postData(params: string, body: any, dataOptions: any): Observable<any> {
        const url: string = this.getUrl() + params;
        return this.http.post(url, body, dataOptions).pipe(
            map((response) => {
                return response;
            }),
            catchError((error: HttpErrorResponse) => {
                return this.handleError(error);
            })
        );
    }

    putData(params: string, body?: any, dataOptions?: any): Observable<any> {
        const url: string = this.getUrl() + params;
        return this.http.put(url, body, dataOptions).pipe(
            map((response) => {
                return response;
            }),
            catchError((error: HttpErrorResponse) => {
                return this.handleError(error);
            })
        );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.status === 400 && error.error['error_code'] === 'NO_RECORD') {
            // const landingUrl = 'https://' + window.location.host + '/not-found';
            // window.location.href = landingUrl;
            // return NEVER;
        } else {
            let message: any;

            if (error instanceof HttpErrorResponse) {
                if (error.error instanceof ErrorEvent) {
                    // A client-side or network error occurred
                    message = error.error.message;
                } else {
                    // The backend returned an unsuccessful response code
                    message = error.message;
                }
            } else {
                message = error;
            }

            // logError(message);

            return throwError(message);
        }
    }
}
