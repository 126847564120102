<div class="add-content-wrapper">
    <div class="title-wrapper">
        <img
            src="/assets/img/icons/cancel_icon.svg"
            alt="cancel_icon"
            class="close-icon"
            (click)="closeDialog()"
        />
        <h1 class="title">
            {{ 'modules.add-content.title' | translate }}
        </h1>
        <p class="description" *ngIf="contentStep === 0">
            {{
                'modules.add-content.description-0'
                    | translate: { title: boardTitle }
            }}
        </p>
        <p class="description" *ngIf="contentStep === 1">
            {{ 'modules.add-content.description-1' | translate }}
        </p>
        <p class="description" *ngIf="contentStep === 2">
            {{ 'modules.add-content.description-2' | translate }}
        </p>
    </div>
    <div class="content-parent">
        <div class="content-wrapper first-part" *ngIf="contentStep === 0">
            <label
                >{{ 'modules.add-content.url' | translate }}
                <span
                    class="error-message"
                    *ngIf="(wizardError$ | async)?.error"
                >
                    {{ (wizardError$ | async)?.error }}</span
                ></label
            >
            <input
                class="url-input"
                type="text"
                [(ngModel)]="url"
                [disabled]="disableURL"
                (keyup)="detectInputURLChange($event.target.value)"
            />
            <div class="split">
                <span class="line"></span>
                <span class="or">
                    {{ 'modules.add-content.or' | translate }}</span
                >
                <span class="line"></span>
            </div>
            <label> {{ 'modules.add-content.upload-file' | translate }}</label>
            <div
                class="upload"
                [ngClass]="{ 'disable-section': disableUploadSection }"
            >
                <div
                    class="upload-section"
                    *ngIf="!attachmentForm.get('attachment').value"
                    onclick="document.getElementById('contentFileInput').click();"
                ></div>
                <div
                    class="upload-section"
                    *ngIf="attachmentForm.get('attachment').value"
                >
                    <span>{{
                        attachmentForm.get('attachment').value.name
                    }}</span>
                    <img
                        src="/assets/img/icons/cancel_icon.svg"
                        alt="remove_icon"
                        class="remove-icon"
                        (click)="removeFile()"
                    />
                </div>
                <input
                    #fileUpload
                    style="opacity: 0;"
                    class="file"
                    type="file"
                    id="contentFileInput"
                    diligram-files-accept
                    (change)="onFileSelect($event)"
                />
                <button
                    class="select"
                    onclick="document.getElementById('contentFileInput').click();"
                >
                    {{ 'modules.add-content.select' | translate }}
                </button>
            </div>
            <p class="error-attachment" *ngIf="tooBigAttachment">
                {{ 'error.too-big-attachment' | translate }}
            </p>
        </div>
        <div
            class="content-wrapper second-part"
            *ngIf="contentStep === 1 && !(wizardLoader$ | async)"
        >
            <p class="title" *ngIf="validateYoutubeTitleStepTwo()">
                {{ 'modules.add-content.title-step-one' | translate }}
            </p>
            <p class="description">
                {{ 'modules.add-content.description-step-one' | translate }}
            </p>
            <div class="content-type">
                <div
                    class="type embedded"
                    [ngClass]="{ selected: embedded === selectedDisplay }"
                    (click)="setHowToContentDisplay(embedded)"
                    *ngIf="
                        filterSecondStep(
                            (wizardData$ | async)?.available_display_types,
                            embedded
                        )
                    "
                >
                    <img
                        src="/assets/img/new-img/embedded.svg"
                        alt="embedded"
                    />
                    <p>
                        {{ 'modules.add-content.embedded' | translate }}
                    </p>
                </div>
                <div
                    class="type description-with-link"
                    [ngClass]="{
                        selected: descriptionWithLink === selectedDisplay
                    }"
                    (click)="setHowToContentDisplay(descriptionWithLink)"
                    *ngIf="
                        filterSecondStep(
                            (wizardData$ | async)?.available_display_types,
                            descriptionWithLink
                        )
                    "
                >
                    <img
                        src="/assets/img/new-img/desc-with-link.svg"
                        alt="desc-with-link"
                    />
                    <p>
                        {{ 'modules.add-content.desc-with-link' | translate }}
                    </p>
                </div>
            </div>
        </div>
        <div
            class="content-wrapper third-part"
            *ngIf="contentStep === 2 && !(wizardLoader$ | async)"
        >
            <label
                *ngIf="
                    !!(wizardData$ | async)?.available_extra_attributes?.title
                "
                >{{ 'modules.add-content.title-content' | translate }}
                <span
                    *ngIf="
                        (wizardData$ | async)?.available_extra_attributes?.title
                            ?.required
                    "
                    >*</span
                ></label
            >
            <input
                type="text"
                [(ngModel)]="additionalInformation.title.value"
                *ngIf="
                    !!(wizardData$ | async)?.available_extra_attributes?.title
                "
            />
            <label
                >{{ 'modules.add-content.description' | translate }}
                <span
                    *ngIf="
                        (wizardData$ | async)?.available_extra_attributes
                            ?.description?.required
                    "
                    >*</span
                ></label
            >
            <textarea
                class="description"
                [(ngModel)]="additionalInformation.description.value"
                *ngIf="
                    !!(wizardData$ | async)?.available_extra_attributes
                        ?.description
                "
            ></textarea>
        </div>
        <app-data-loader
            class="loader"
            *ngIf="wizardLoader$ | async"
        ></app-data-loader>
    </div>
    <div class="footer-wrapper" [ngClass]="{ 'first-step': contentStep === 0 }">
        <button
            class="back step btn-primary center-btn"
            (click)="back()"
            *ngIf="contentStep > 0 && !(wizardLoader$ | async)"
        >
            {{ 'modules.add-content.back' | translate }}
        </button>
        <button
            class="next step btn-primary center-btn"
            (click)="next()"
            *ngIf="contentStep < 2 && !(wizardLoader$ | async)"
            [disabled]="stepValidation()"
        >
            {{ 'modules.add-content.next' | translate }}
        </button>
        <button
            class="finish step btn-primary center-btn"
            (click)="next()"
            *ngIf="contentStep === 2 && !(wizardLoader$ | async)"
            [disabled]="stepValidation()"
        >
            {{ 'modules.add-content.finish' | translate }}
        </button>
    </div>
</div>
