import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectIsLoggedIn } from 'src/app/modules/user/store/user.selector';

@Component({
    selector: 'app-imprint',
    templateUrl: './imprint.component.html',
    styleUrls: ['./imprint.component.css']
})
export class ImprintComponent implements OnInit {
    isLoggedIn$: Observable<boolean>;

    constructor(private router: Router, private store: Store<any>) {}

    ngOnInit() {
        this.isLoggedIn$ = this.store.pipe(select(selectIsLoggedIn));
    }

    navigateTo(link: string): void {
        this.router.navigate([link]);
    }
}
