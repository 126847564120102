import { Injectable } from '@angular/core';
import { Observable, of, NEVER, merge } from 'rxjs';
import {
    catchError,
    map,
    mergeMap,
    withLatestFrom,
    switchMap
} from 'rxjs/operators';
import { Store, Action, select } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { DiliboardService } from '../diliboard.service';
import {
    GetAllDiliboards,
    DiliboardActionType,
    ResponseAllDiliboards,
    ErrorFetchingAllDiliboards,
    GetAllBoardContent,
    SearchDiliboards,
    ResponseSearchDiliboard,
    ErrorSearchDiliboards,
    GetBoardUsers,
    ResponseBoardUsers,
    ErrorFetchingBoardUsers,
    GetBoardComments,
    ResponseBoardComments,
    ErrorFetchingBoardComments,
    PostComment,
    SuccessfullyPostComment,
    ErrorPostComment,
    GetBoardDetails,
    ResponseBoardDetails,
    ErrorFetchingBoardDetails,
    DeleteBoard,
    ResetDiliboardsAndContent,
    DeleteBoardContent,
    CreateBoard,
    StartWizard,
    UpdateWizard,
    GoToFirstStep,
    GoToSecondStep,
    FinishWizard,
    StartWizardWithAttachment,
    ErrorStartWizard,
    SetSortingDiliboard,
    EditBoard,
    EditDiliboardContent,
    GetUserStatusInBoard,
    ResponseUserStatusInBoard,
    ChangeFriendRole,
    ChangeInviteRole,
    InviteUser,
    SuccessfullyInviteUser,
    RemoveUser,
    RemoveInvitedUser,
    AcceptInvite,
    TooBigAttachment
} from './diliboard.actions';
import { Diliboard, Comments } from 'src/app/models/diliboard';
import {
    selectPageOptionsMyClassroomDashboard,
    selectWizardID
} from './diliboard.selector';
import {
    FriendListInterface,
    UserInviteInterface
} from 'src/app/models/friend-list.model';
import { selectUser } from 'src/app/modules/user/store/user.selector';
import { CommentResponse } from 'src/app/models/comment-response';
import { MatDialog } from '@angular/material/dialog';
import { SortOrder } from 'src/app/util/sort.enum';

const boardFeatureName: string = 'board';
const myClassroomFeatureName: string = 'myClassroom';

@Injectable()
export class DiliboardEffects {
    @Effect()
    getAllDiliboards$: Observable<Action> = this.actions$.pipe(
        ofType<GetAllDiliboards>(DiliboardActionType.GET_ALL_DILIBOARDS),
        withLatestFrom(
            this.store.pipe(
                select(selectPageOptionsMyClassroomDashboard, {
                    featureName: myClassroomFeatureName
                })
            )
        ),
        mergeMap(([action, pageOptions]) => {
            return this.diliboardService
                .getAllDiliboards(
                    pageOptions.offset,
                    pageOptions.limit,
                    pageOptions.sort
                )
                .pipe(
                    map((response) => {
                        const dataCount: number = +response.headers.getAll(
                            'x-pagination-total'
                        );
                        return new ResponseAllDiliboards({
                            data: response.body as Diliboard[],
                            dataCount,
                            featureName: myClassroomFeatureName
                        });
                    }),
                    catchError(() => {
                        return of(
                            new ErrorFetchingAllDiliboards({
                                featureName: myClassroomFeatureName
                            })
                        );
                    })
                );
        })
    );

    @Effect()
    getAllBoardContent$: Observable<Action> = this.actions$.pipe(
        ofType<GetAllBoardContent>(DiliboardActionType.GET_ALL_BOARD_CONTENT),
        withLatestFrom(
            this.store.pipe(
                select(selectPageOptionsMyClassroomDashboard, {
                    featureName: boardFeatureName
                })
            )
        ),
        mergeMap(([action, pageOptions]) => {
            return this.diliboardService
                .showDiliboardContent(
                    action.payload.boardId,
                    pageOptions.offset,
                    pageOptions.limit,
                    pageOptions.sort
                )
                .pipe(
                    map((response) => {
                        const dataCount: number = +response.headers.getAll(
                            'x-pagination-total'
                        );
                        return new ResponseAllDiliboards({
                            data: response.body as Diliboard[],
                            dataCount,
                            featureName: boardFeatureName
                        });
                    }),
                    catchError(() => {
                        return of(
                            new ErrorFetchingAllDiliboards({
                                featureName: boardFeatureName
                            })
                        );
                    })
                );
        })
    );

    @Effect()
    searchDiliboards$: Observable<Action> = this.actions$.pipe(
        ofType<SearchDiliboards>(DiliboardActionType.SEARCH_DILIBOARDS),
        mergeMap((action: SearchDiliboards) => {
            const { featureName, term } = action.payload;
            return of([featureName, term] as [string, string]).pipe(
                withLatestFrom(
                    this.store.pipe(
                        select(selectPageOptionsMyClassroomDashboard, {
                            featureName
                        })
                    )
                )
            );
        }),
        mergeMap(([[featureName, term], pageOptions]) => {
            return this.diliboardService
                .getSearchResult(pageOptions.offset, pageOptions.limit, term)
                .pipe(
                    map((response) => {
                        const dataCount: number = +response.headers.getAll(
                            'x-pagination-total'
                        );
                        return new ResponseSearchDiliboard({
                            data: response.body['results'][
                                'diliboards'
                            ] as Diliboard[],
                            dataCount,
                            featureName
                        });
                    }),
                    catchError(() => {
                        return of(
                            new ErrorSearchDiliboards({
                                featureName
                            })
                        );
                    })
                );
        })
    );

    @Effect()
    getBoardUsers$: Observable<Action> = this.actions$.pipe(
        ofType<GetBoardUsers>(DiliboardActionType.GET_BOARD_USERS),
        mergeMap((action) => {
            return this.diliboardService
                .getBoardUsers(action.payload.boardId)
                .pipe(
                    mergeMap((response) => {
                        return of(
                            new ResponseBoardUsers({
                                users: response.body as FriendListInterface[]
                            }),
                            new GetUserStatusInBoard({
                                boardId: action.payload.boardId
                            })
                        );
                    }),
                    catchError((error) => {
                        return of(new ErrorFetchingBoardUsers());
                    })
                );
        })
    );

    @Effect()
    getUsersStatusInBoard$: Observable<Action> = this.actions$.pipe(
        ofType<GetUserStatusInBoard>(
            DiliboardActionType.GET_USER_STATUS_IN_BOARD
        ),
        mergeMap((action) => {
            return this.diliboardService
                .getUsersStatusInBoard(action.payload.boardId)
                .pipe(
                    map((response) => {
                        return new ResponseUserStatusInBoard({
                            users: response.body as UserInviteInterface[]
                        });
                    }),
                    catchError((error) => {
                        return of(new ErrorFetchingBoardUsers());
                    })
                );
        })
    );

    @Effect()
    getBoardComments$: Observable<Action> = this.actions$.pipe(
        ofType<GetBoardComments>(DiliboardActionType.GET_BOARD_COMMENTS),
        mergeMap((action) => {
            return this.diliboardService
                .getBoardComments(action.payload.boardId)
                .pipe(
                    map((response) => {
                        return new ResponseBoardComments({
                            comments: response.body as Comments[]
                        });
                    }),
                    catchError((error) => {
                        return of(new ErrorFetchingBoardComments());
                    })
                );
        })
    );

    @Effect()
    postComment$: Observable<Action> = this.actions$.pipe(
        ofType<PostComment>(DiliboardActionType.POST_COMMENT),
        withLatestFrom(this.store.pipe(select(selectUser))),
        mergeMap(([action, userData]) => {
            return this.diliboardService
                .postComment(
                    action.payload.comment,
                    action.payload.boardId,
                    action.payload.userId
                )
                .pipe(
                    map((response) => {
                        const commentResponse: CommentResponse =
                            response.body['comment'];
                        const comment: Comments = {
                            ...commentResponse,
                            author: {
                                id: userData.id,
                                first_name: userData.first_name,
                                last_name: userData.last_name,
                                image_url_thumb: userData.image_url_thumb,
                                image_url_medium: userData.image_url_medium,
                                image_url_original: userData.image_url_original
                            }
                        };
                        return new SuccessfullyPostComment({
                            comment
                        });
                    }),
                    catchError((error) => {
                        return of(new ErrorPostComment());
                    })
                );
        })
    );

    @Effect()
    getBoardDetails$: Observable<Action> = this.actions$.pipe(
        ofType<GetBoardDetails>(DiliboardActionType.GET_BOARD_DETAILS),
        mergeMap((action) => {
            return this.diliboardService
                .getBoardDetails(action.payload.boardId)
                .pipe(
                    map((response) => {
                        return new ResponseBoardDetails({
                            boardDetails: response.body as Diliboard
                        });
                    }),
                    catchError((error) => {
                        return of(new ErrorFetchingBoardDetails());
                    })
                );
        })
    );

    @Effect()
    deleteBoard$ = this.actions$.pipe(
        ofType<DeleteBoard>(DiliboardActionType.DELETE_BOARD),
        mergeMap((action) => {
            return this.diliboardService
                .deleteBoard(action.payload.boardId)
                .pipe(
                    mergeMap((response) => {
                        this.dialog.closeAll();
                        const outs: Action[] = [
                            new ResetDiliboardsAndContent(),
                            new GetAllDiliboards()
                        ];
                        return of(...outs);
                    }),
                    catchError((error) => {
                        return NEVER;
                    })
                );
        })
    );

    @Effect()
    deleteBoardContent$ = this.actions$.pipe(
        ofType<DeleteBoardContent>(DiliboardActionType.DELETE_BOARD_CONTENT),
        mergeMap((action) => {
            return this.diliboardService
                .deleteBoardContent(
                    action.payload.boardId,
                    action.payload.contentId
                )
                .pipe(
                    mergeMap((response) => {
                        this.dialog.closeAll();
                        const outs: Action[] = [
                            new SetSortingDiliboard({
                                value: SortOrder.title_ASC,
                                featureName: 'board'
                            })
                        ];
                        return of(...outs);
                    }),
                    catchError((error) => {
                        return NEVER;
                    })
                );
        })
    );

    @Effect()
    createBoard$ = this.actions$.pipe(
        ofType<CreateBoard>(DiliboardActionType.CREATE_BOARD),
        mergeMap((action) => {
            return this.diliboardService
                .createDiliboard(action.payload.diliboard)
                .pipe(
                    mergeMap((response) => {
                        this.dialog.closeAll();
                        const outs: Action[] = [
                            new ResetDiliboardsAndContent(),
                            new GetAllDiliboards()
                        ];
                        return of(...outs);
                    }),
                    catchError((error) => {
                        return NEVER;
                    })
                );
        })
    );

    @Effect()
    startWizard$: Observable<Action> = this.actions$.pipe(
        ofType<StartWizard>(DiliboardActionType.START_WIZARD),
        mergeMap((action) => {
            return this.diliboardService
                .startWizard(action.payload.boardId, action.payload.url)
                .pipe(
                    map((response) => {
                        return new UpdateWizard({ data: response.body });
                    }),
                    catchError((error) => {
                        const errorMessage: string = !!error['error']['url'][0]
                            ? error['error']['url'][0]
                            : null;
                        return of(
                            new ErrorStartWizard({
                                error: errorMessage,
                                step: 0
                            })
                        );
                    })
                );
        })
    );

    @Effect()
    goToFirstStep$: Observable<Action> = this.actions$.pipe(
        ofType<GoToFirstStep>(DiliboardActionType.GO_TO_FIRST_STEP),
        withLatestFrom(this.store.pipe(select(selectWizardID))),
        mergeMap(([action, wizardID]) => {
            return this.diliboardService
                .updateWizard(
                    action.payload.boardId,
                    action.payload.body,
                    wizardID
                )
                .pipe(
                    map((response) => {
                        return new UpdateWizard({ data: response.body });
                    }),
                    catchError((error) => {
                        return NEVER;
                    })
                );
        })
    );

    @Effect()
    goToSecondStep$: Observable<Action> = this.actions$.pipe(
        ofType<GoToSecondStep>(DiliboardActionType.GO_TO_SECOND_STEP),
        withLatestFrom(this.store.pipe(select(selectWizardID))),
        mergeMap(([action, wizardID]) => {
            return this.diliboardService
                .updateWizard(
                    action.payload.boardId,
                    action.payload.body,
                    wizardID
                )
                .pipe(
                    mergeMap((response) => {
                        return of(
                            new UpdateWizard({ data: response.body }),
                            new FinishWizard({
                                boardId: action.payload.boardId
                            })
                        );
                    }),
                    catchError((error) => {
                        return NEVER;
                    })
                );
        })
    );

    @Effect()
    finishWizard$: Observable<Action> = this.actions$.pipe(
        ofType<FinishWizard>(DiliboardActionType.FINISH_WIZARD),
        withLatestFrom(this.store.pipe(select(selectWizardID))),
        mergeMap(([action, wizardID]) => {
            return this.diliboardService
                .finishWizard(action.payload.boardId, wizardID)
                .pipe(
                    mergeMap((response) => {
                        this.dialog.closeAll();
                        return of(
                            new UpdateWizard({ data: null }),
                            new SetSortingDiliboard({
                                value: SortOrder.title_ASC,
                                featureName: 'board'
                            })
                        );
                    }),
                    catchError((error) => {
                        return NEVER;
                    })
                );
        })
    );

    @Effect()
    startWizardWithAttachment$: Observable<Action> = this.actions$.pipe(
        ofType<StartWizardWithAttachment>(
            DiliboardActionType.START_WIZARD_WITH_ATTACHMENT
        ),
        mergeMap((action) => {
            return this.diliboardService
                .startWizardWithAttachment(
                    action.payload.boardId,
                    action.payload.attachment
                )
                .pipe(
                    map((response) => {
                        return new UpdateWizard({ data: response.body });
                    }),
                    catchError((error) => {
                        console.log(error.status);
                        if (error.status === 413) {
                            return of(new TooBigAttachment());
                        } else {
                            return NEVER;
                        }
                    })
                );
        })
    );

    @Effect()
    editBoard$: Observable<Action> = this.actions$.pipe(
        ofType<EditBoard>(DiliboardActionType.EDIT_BOARD),
        mergeMap((action) => {
            return this.diliboardService
                .editBoard(action.payload.board, action.payload.boardId)
                .pipe(
                    mergeMap((response) => {
                        this.dialog.closeAll();
                        return of(
                            new ResetDiliboardsAndContent(),
                            new GetAllDiliboards()
                        );
                    }),
                    catchError((error) => {
                        return NEVER;
                    })
                );
        })
    );

    @Effect()
    editDiliboardContent$: Observable<Action> = this.actions$.pipe(
        ofType<EditDiliboardContent>(
            DiliboardActionType.EDIT_DILIBOARD_CONTENT
        ),
        mergeMap((action) => {
            return this.diliboardService
                .editDiliboardContent(
                    action.payload.boardId,
                    action.payload.contentId,
                    action.payload.body
                )
                .pipe(
                    mergeMap((response) => {
                        this.dialog.closeAll();
                        return of(
                            new SetSortingDiliboard({
                                value: SortOrder.title_ASC,
                                featureName: 'board'
                            })
                        );
                    }),
                    catchError((error) => {
                        return NEVER;
                    })
                );
        })
    );

    @Effect()
    changeFriendRole$: Observable<Action> = this.actions$.pipe(
        ofType<ChangeFriendRole>(DiliboardActionType.CHANGE_FRIEND_ROLE),
        mergeMap((action) => {
            const diliboardId: number = action.payload.diliboardId;
            const user_id: number = action.payload.user_id;
            const role: string = action.payload.role;
            return this.diliboardService
                .changeFriendRole(diliboardId, user_id, { role })
                .pipe(
                    map((response) => {
                        return new GetBoardUsers({ boardId: diliboardId });
                    }),
                    catchError((error) => {
                        return NEVER;
                    })
                );
        })
    );

    @Effect()
    changeInviteRole$: Observable<Action> = this.actions$.pipe(
        ofType<ChangeInviteRole>(DiliboardActionType.CHANGE_INVITE_ROLE),
        mergeMap((action) => {
            const diliboardId: number = action.payload.diliboardId;
            const inviteId: number = action.payload.inviteId;
            const role: string = action.payload.role;
            return this.diliboardService
                .changeInviteRole(inviteId, { role })
                .pipe(
                    map((response) => {
                        return new GetBoardUsers({ boardId: diliboardId });
                    }),
                    catchError((error) => {
                        return NEVER;
                    })
                );
        })
    );

    @Effect()
    inviteUser$: Observable<Action> = this.actions$.pipe(
        ofType<InviteUser>(DiliboardActionType.INVITE_USER),
        mergeMap((action) => {
            return this.diliboardService.inviteUser(action.payload.body).pipe(
                mergeMap((response) => {
                    return of(
                        new GetBoardUsers({
                            boardId: action.payload.diliboardId
                        }),
                        new SuccessfullyInviteUser()
                    );
                }),
                catchError((error) => {
                    return NEVER;
                })
            );
        })
    );

    @Effect()
    removeUser$: Observable<Action> = this.actions$.pipe(
        ofType<RemoveUser>(DiliboardActionType.REMOVE_USER),
        mergeMap((action) => {
            return this.diliboardService
                .removeUser(action.payload.diliboardId, action.payload.friendId)
                .pipe(
                    map((response) => {
                        return new GetBoardUsers({
                            boardId: action.payload.diliboardId
                        });
                    }),
                    catchError((error) => {
                        return NEVER;
                    })
                );
        })
    );

    @Effect()
    removeInvitedUser$: Observable<Action> = this.actions$.pipe(
        ofType<RemoveInvitedUser>(DiliboardActionType.REMOVE_INVITED_USER),
        mergeMap((action) => {
            return this.diliboardService
                .removeInvitedUser(action.payload.inviteId)
                .pipe(
                    map((response) => {
                        return new GetBoardUsers({
                            boardId: action.payload.diliboardId
                        });
                    }),
                    catchError((error) => {
                        return NEVER;
                    })
                );
        })
    );

    @Effect()
    acceptInvite$: Observable<Action> = this.actions$.pipe(
        ofType<AcceptInvite>(DiliboardActionType.ACCEPT_INVITE),
        mergeMap((action) => {
            return this.diliboardService.acceptInvite(action.payload.body).pipe(
                mergeMap((response) => {
                    const boardId: number = action.payload.boardId;
                    return of(
                        new ResetDiliboardsAndContent(),
                        new GetAllBoardContent({ boardId }),
                        new GetBoardDetails({ boardId })
                    );
                }),
                catchError((error) => {
                    return NEVER;
                })
            );
        })
    );

    constructor(
        private readonly actions$: Actions,
        private store: Store<any>,
        private diliboardService: DiliboardService,
        private dialog: MatDialog
    ) {}
}
