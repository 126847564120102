<div class="footer-wrapper">
    <div class="container">
        <div class="diligram-column">
            <img
                src="/assets/img/diligram-logo.svg"
                alt="diligram_typo_logo"
                class="diligram-logo footer-logo"
            />
        </div>
        <div class="diligram-column">
            <h1>{{ 'footer.contact-us' | translate }}</h1>
            <a href="mailto:info@diligram.com">info@diligram.com</a>
            <a href="tel:+41415670132">+41 41 567 0132</a>
        </div>
        <div class="diligram-column">
            <h1>{{ 'footer.location' | translate }}</h1>
            <a
                target="_blank"
                href="https://maps.google.com/?q=Talstrasse 23, 6403 Küssnacht am Rigi/SZ, Switzerland"
            >
                <a>Talstrasse 23</a>
                <a>6403 Küssnacht am Rigi/SZ</a>
                <a>Switzerland</a>
            </a>
        </div>
        <div class="diligram-column">
            <h1>{{ 'footer.information' | translate }}</h1>
            <a target="_blank" href="https://diligram.com/">{{
                'footer.about' | translate
            }}</a>

            <a (click)="navigateTo('privacy-policy')">{{
                'footer.terms' | translate
            }}</a>
            <a (click)="navigateTo('imprint-diligram')">{{
                'footer.imprint' | translate
            }}</a>
        </div>
    </div>
</div>
