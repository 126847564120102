import { Action } from '@ngrx/store';
import { PayloadAction } from 'src/app/util/payload.util';
import { Diliboard, Comments } from 'src/app/models/diliboard';
import {
    FriendListInterface,
    UserInviteInterface
} from 'src/app/models/friend-list.model';
import { AddNewContentSessionModel } from '../models/wizard-session.model';

export const enum DiliboardActionType {
    RESET_DILIBOARDS_AND_CONTENT = '[DILIBOARD MODULE] RESET_DILIBOARDS_AND_CONTENT',
    RESET_CONTENT = '[DILIBOARD MODULE] RESET_CONTENT',
    GET_ALL_DILIBOARDS = '[DILIBOARD MODULE] GET_ALL_DILIBOARDS',
    ERROR_FETCHING_ALL_DILIBOARDS = '[DILIBOARD MODULE] ERROR_FETCHING_ALL_DILIBOARDS',
    RESPONSE_ALL_DILIBOARDS = '[DILIBOARD MODULE] RESPONSE_ALL_DILIBOARDS',
    LOAD_MORE_DILIBOARD = '[DILIBOARD MODULE] LOAD_MORE_DILIBOARD',
    SET_SORTING_DILIBOARD = '[DILIBOARD MODULE] SET_SORTING_DILIBOARD',
    GET_ALL_BOARD_CONTENT = '[DILIBOARD MODULE] GET_ALL_BOARD_CONTENT',
    SEARCH_DILIBOARDS = '[DILIBOARD MODULE] SEARCH_DILIBOARDS',
    RESPONSE_SEARCH_DILIBOARDS = '[DILIBOARD MODULE] RESPONSE_SEARCH_DILIBOARDS',
    ERROR_SEARCH_DILIBOARDS = '[DILIBOARD MODULE] ERROR_SEARCH_DILIBOARDS',
    GET_BOARD_USERS = '[DILIBOARD MODULE] GET_BOARD_USERS',
    ERROR_FETCHING_BOARD_USERS = '[DILIBOARD MODULE] ERROR_FETCHING_BOARD_USERS',
    RESPONSE_BOARD_USERS = '[DILIBOARD MODULE] RESPONSE_BOARD_USERS',
    GET_BOARD_COMMENTS = '[DILIBOARD MODULE] GET_BOARD_COMMENTS',
    ERROR_FETCHING_BOARD_COMMENTS = '[DILIBOARD MODULE] ERROR_FETCHING_BOARD_COMMENTS',
    RESPONSE_BOARD_COMMENTS = '[DILIBOARD MODULE] RESPONSE_BOARD_COMMENTS',
    POST_COMMENT = '[DILIBOARD MODULE] POST_COMMENT',
    ERROR_POST_COMMENT = '[DILIBOARD MODULE] ERROR_POST_COMMENT',
    SUCCESSFULLY_POST_COMMENT = '[DILIBOARD MODULE] SUCCESSFULLY_POST_COMMENT',
    GET_BOARD_DETAILS = '[DILIBOARD MODULE] GET_BOARD_DETAILS',
    ERROR_FETCHING_BOARD_DETAILS = '[DILIBOARD MODULE] ERROR_FETCHING_BOARD_DETAILS',
    RESPONSE_BOARD_DETAILS = '[DILIBOARD MODULE] RESPONSE_BOARD_DETAILS',
    DELETE_BOARD = '[DILIBOARD MODULE] DELETE_BOARD',
    DELETE_BOARD_CONTENT = '[DILIBOARD MODULE] DELETE_BOARD_CONTENT',
    CREATE_BOARD = '[DILIBOARD MODULE] CREATE_BOARD',
    EDIT_DILIBOARD_CONTENT = '[DILIBOARD MODULE] EDIT_DILIBOARD_CONTENT',
    GET_USER_STATUS_IN_BOARD = '[DILIBOARD MODULE] GET_USER_STATUS_IN_BOARD',
    RESPONSE_USER_STATUS_IN_BOARD = '[DILIBOARD MODULE] RESPONSE_USER_STATUS_IN_BOARD',
    CHANGE_FRIEND_ROLE = '[DILIBOARD MODULE] CHANGE_FRIEND_ROLE',
    CHANGE_INVITE_ROLE = '[DILIBOARD MODULE] CHANGE_INVITE_ROLE',
    INVITE_USER = '[DILIBOARD MODULE] INVITE_USER',
    SUCCESSFULLY_INVITE_USER = '[DILIBOARD MODULE] SUCCESSFULLY_INVITE_USER',
    REMOVE_USER = '[DILIBOARD MODULE] REMOVE_USER',
    REMOVE_INVITED_USER = '[DILIBOARD MODULE] REMOVE_INVITED_USER',
    ACCEPT_INVITE = '[DILIBOARD MODULE] ACCEPT_INVITE',

    // NEW CONTENT WIZARD
    START_WIZARD = '[DILIBOARD MODULE] START_WIZARD',
    ERROR_START_WIZARD = '[DILIBOARD MODULE] ERROR_START_WIZARD',
    START_WIZARD_WITH_ATTACHMENT = '[DILIBOARD MODULE] START_WIZARD_WITH_ATTACHMENT',
    GO_TO_FIRST_STEP = '[DILIBOARD MODULE] GO_TO_FIRST_STEP',
    GO_TO_SECOND_STEP = '[DILIBOARD MODULE] GO_TO_SECOND_STEP',
    UPDATE_WIZARD = '[DILIBOARD MODULE] UPDATE_WIZARD',
    ERROR_WIZARD = '[DILIBOARD MODULE] ERROR_WIZARD',
    FINISH_WIZARD = '[DILIBOARD MODULE] FINISH_WIZARD',
    EDIT_BOARD = '[DILIBOARD MODULE] EDIT_BOARD',
    TOO_BIG_ATTACHMENT = '[DILIBOARD MODULE] TOO_BIG_ATTACHMENT'
}

export const DiliboardActionTypes: DiliboardActionType[] = [
    DiliboardActionType.RESET_CONTENT,
    DiliboardActionType.RESET_DILIBOARDS_AND_CONTENT,
    DiliboardActionType.GET_ALL_DILIBOARDS,
    DiliboardActionType.ERROR_FETCHING_ALL_DILIBOARDS,
    DiliboardActionType.RESPONSE_ALL_DILIBOARDS,
    DiliboardActionType.LOAD_MORE_DILIBOARD,
    DiliboardActionType.SET_SORTING_DILIBOARD,
    DiliboardActionType.GET_ALL_BOARD_CONTENT,
    DiliboardActionType.SEARCH_DILIBOARDS,
    DiliboardActionType.RESPONSE_SEARCH_DILIBOARDS,
    DiliboardActionType.ERROR_SEARCH_DILIBOARDS,
    DiliboardActionType.GET_BOARD_USERS,
    DiliboardActionType.ERROR_FETCHING_BOARD_USERS,
    DiliboardActionType.RESPONSE_BOARD_USERS,
    DiliboardActionType.GET_BOARD_COMMENTS,
    DiliboardActionType.ERROR_FETCHING_BOARD_COMMENTS,
    DiliboardActionType.RESPONSE_BOARD_COMMENTS,
    DiliboardActionType.POST_COMMENT,
    DiliboardActionType.ERROR_POST_COMMENT,
    DiliboardActionType.SUCCESSFULLY_POST_COMMENT,
    DiliboardActionType.GET_BOARD_DETAILS,
    DiliboardActionType.ERROR_FETCHING_BOARD_DETAILS,
    DiliboardActionType.RESPONSE_BOARD_DETAILS,
    DiliboardActionType.DELETE_BOARD,
    DiliboardActionType.DELETE_BOARD_CONTENT,
    DiliboardActionType.CREATE_BOARD,
    DiliboardActionType.START_WIZARD,
    DiliboardActionType.ERROR_START_WIZARD,
    DiliboardActionType.START_WIZARD_WITH_ATTACHMENT,
    DiliboardActionType.GO_TO_FIRST_STEP,
    DiliboardActionType.GO_TO_SECOND_STEP,
    DiliboardActionType.UPDATE_WIZARD,
    DiliboardActionType.FINISH_WIZARD,
    DiliboardActionType.ERROR_WIZARD,
    DiliboardActionType.EDIT_BOARD,
    DiliboardActionType.EDIT_DILIBOARD_CONTENT,
    DiliboardActionType.GET_USER_STATUS_IN_BOARD,
    DiliboardActionType.RESPONSE_USER_STATUS_IN_BOARD,
    DiliboardActionType.CHANGE_FRIEND_ROLE,
    DiliboardActionType.CHANGE_INVITE_ROLE,
    DiliboardActionType.INVITE_USER,
    DiliboardActionType.SUCCESSFULLY_INVITE_USER,
    DiliboardActionType.REMOVE_USER,
    DiliboardActionType.REMOVE_INVITED_USER,
    DiliboardActionType.ACCEPT_INVITE,
    DiliboardActionType.TOO_BIG_ATTACHMENT
];

export class GetAllDiliboards implements Action {
    readonly type: DiliboardActionType = DiliboardActionType.GET_ALL_DILIBOARDS;
}

export class ErrorFetchingAllDiliboards implements PayloadAction {
    readonly type: DiliboardActionType =
        DiliboardActionType.ERROR_FETCHING_ALL_DILIBOARDS;

    constructor(public payload: { featureName: string }) {}
}

export class ResponseAllDiliboards implements PayloadAction {
    readonly type: DiliboardActionType =
        DiliboardActionType.RESPONSE_ALL_DILIBOARDS;

    constructor(
        public payload: {
            data: Diliboard[];
            dataCount: number;
            featureName: string;
        }
    ) {}
}

export class LoadMoreDiliboard implements PayloadAction {
    readonly type: DiliboardActionType =
        DiliboardActionType.LOAD_MORE_DILIBOARD;

    constructor(public payload: { featureName: string }) {}
}

export class SetSortingDiliboard implements PayloadAction {
    readonly type: DiliboardActionType =
        DiliboardActionType.SET_SORTING_DILIBOARD;

    constructor(public payload: { value: string; featureName: string }) {}
}

export class GetAllBoardContent implements PayloadAction {
    readonly type: DiliboardActionType =
        DiliboardActionType.GET_ALL_BOARD_CONTENT;

    constructor(public payload: { boardId: number; resetFlag?: boolean }) {}
}

export class ResetDiliboardsAndContent implements Action {
    readonly type: DiliboardActionType =
        DiliboardActionType.RESET_DILIBOARDS_AND_CONTENT;
}

export class SearchDiliboards implements PayloadAction {
    readonly type: DiliboardActionType = DiliboardActionType.SEARCH_DILIBOARDS;

    constructor(public payload: { term: string; featureName: string }) {}
}

export class ResponseSearchDiliboard implements PayloadAction {
    readonly type: DiliboardActionType =
        DiliboardActionType.RESPONSE_SEARCH_DILIBOARDS;

    constructor(
        public payload: {
            data: Diliboard[];
            dataCount: number;
            featureName: string;
        }
    ) {}
}

export class ErrorSearchDiliboards implements PayloadAction {
    readonly type: DiliboardActionType =
        DiliboardActionType.ERROR_SEARCH_DILIBOARDS;

    constructor(public payload: { featureName: string }) {}
}

export class ResetContent implements Action {
    readonly type: DiliboardActionType = DiliboardActionType.RESET_CONTENT;
}

export class GetBoardUsers implements PayloadAction {
    readonly type: DiliboardActionType = DiliboardActionType.GET_BOARD_USERS;

    constructor(public payload: { boardId: number }) {}
}

export class ErrorFetchingBoardUsers implements Action {
    readonly type: DiliboardActionType =
        DiliboardActionType.ERROR_FETCHING_BOARD_USERS;
}

export class ResponseBoardUsers implements PayloadAction {
    readonly type: DiliboardActionType =
        DiliboardActionType.RESPONSE_BOARD_USERS;

    constructor(public payload: { users: FriendListInterface[] }) {}
}

export class GetBoardComments implements PayloadAction {
    readonly type: DiliboardActionType = DiliboardActionType.GET_BOARD_COMMENTS;

    constructor(public payload: { boardId: number }) {}
}

export class ErrorFetchingBoardComments implements Action {
    readonly type: DiliboardActionType =
        DiliboardActionType.ERROR_FETCHING_BOARD_COMMENTS;
}

export class ResponseBoardComments implements Action {
    readonly type: DiliboardActionType =
        DiliboardActionType.RESPONSE_BOARD_COMMENTS;

    constructor(public payload: { comments: Comments[] }) {}
}

export class PostComment implements PayloadAction {
    readonly type: DiliboardActionType = DiliboardActionType.POST_COMMENT;

    constructor(
        public payload: { comment: string; boardId: number; userId: number }
    ) {}
}

export class ErrorPostComment implements Action {
    readonly type: DiliboardActionType = DiliboardActionType.ERROR_POST_COMMENT;
}

export class SuccessfullyPostComment implements PayloadAction {
    readonly type: DiliboardActionType =
        DiliboardActionType.SUCCESSFULLY_POST_COMMENT;

    constructor(public payload: { comment: Comments }) {}
}

export class GetBoardDetails implements PayloadAction {
    readonly type: DiliboardActionType = DiliboardActionType.GET_BOARD_DETAILS;

    constructor(public payload: { boardId: number }) {}
}

export class ErrorFetchingBoardDetails implements Action {
    readonly type: DiliboardActionType =
        DiliboardActionType.ERROR_FETCHING_BOARD_DETAILS;
}

export class ResponseBoardDetails implements PayloadAction {
    readonly type: DiliboardActionType =
        DiliboardActionType.RESPONSE_BOARD_DETAILS;

    constructor(public payload: { boardDetails: Diliboard }) {}
}

export class DeleteBoard implements PayloadAction {
    readonly type: DiliboardActionType = DiliboardActionType.DELETE_BOARD;

    constructor(public payload: { boardId: number }) {}
}

export class DeleteBoardContent implements PayloadAction {
    readonly type: DiliboardActionType =
        DiliboardActionType.DELETE_BOARD_CONTENT;

    constructor(public payload: { boardId: number; contentId: number }) {}
}

export class CreateBoard implements PayloadAction {
    readonly type: DiliboardActionType = DiliboardActionType.CREATE_BOARD;

    constructor(public payload: { diliboard: Diliboard }) {}
}

export class StartWizard implements PayloadAction {
    readonly type: DiliboardActionType = DiliboardActionType.START_WIZARD;

    constructor(public payload: { url: string; boardId: number }) {}
}

export class ErrorStartWizard implements PayloadAction {
    readonly type: DiliboardActionType = DiliboardActionType.ERROR_START_WIZARD;

    constructor(public payload: { error: string; step: number }) {}
}

export class StartWizardWithAttachment implements PayloadAction {
    readonly type: DiliboardActionType =
        DiliboardActionType.START_WIZARD_WITH_ATTACHMENT;

    constructor(public payload: { boardId: number; attachment: any }) {}
}

export class TooBigAttachment implements Action {
    readonly type: DiliboardActionType = DiliboardActionType.TOO_BIG_ATTACHMENT;
}

export class GoToFirstStep implements PayloadAction {
    readonly type: DiliboardActionType = DiliboardActionType.GO_TO_FIRST_STEP;

    constructor(
        public payload: { body: { display_type: string }; boardId: number }
    ) {}
}

export class GoToSecondStep implements PayloadAction {
    readonly type: DiliboardActionType = DiliboardActionType.GO_TO_SECOND_STEP;

    constructor(
        public payload: {
            body: {
                extra_attributes: {
                    title: string;
                    description?: string;
                };
            };
            boardId: number;
        }
    ) {}
}

export class UpdateWizard implements PayloadAction {
    readonly type: DiliboardActionType = DiliboardActionType.UPDATE_WIZARD;

    constructor(public payload: { data: AddNewContentSessionModel }) {}
}

export class FinishWizard implements Action {
    readonly type: DiliboardActionType = DiliboardActionType.FINISH_WIZARD;

    constructor(public payload: { boardId: number }) {}
}

export class ErrorWizard implements Action {
    readonly type: DiliboardActionType = DiliboardActionType.ERROR_WIZARD;
}

export class EditBoard implements PayloadAction {
    readonly type: DiliboardActionType = DiliboardActionType.EDIT_BOARD;

    constructor(public payload: { board: Diliboard; boardId: number }) {}
}

export class EditDiliboardContent implements PayloadAction {
    readonly type: DiliboardActionType =
        DiliboardActionType.EDIT_DILIBOARD_CONTENT;

    constructor(
        public payload: {
            boardId: number;
            contentId: number;
            body: any;
        }
    ) {}
}

export class GetUserStatusInBoard implements PayloadAction {
    readonly type: DiliboardActionType =
        DiliboardActionType.GET_USER_STATUS_IN_BOARD;

    constructor(public payload: { boardId: number }) {}
}

export class ResponseUserStatusInBoard implements PayloadAction {
    readonly type: DiliboardActionType =
        DiliboardActionType.RESPONSE_USER_STATUS_IN_BOARD;

    constructor(public payload: { users: UserInviteInterface[] }) {}
}

export class ChangeFriendRole implements PayloadAction {
    readonly type: DiliboardActionType = DiliboardActionType.CHANGE_FRIEND_ROLE;

    constructor(
        public payload: { diliboardId: number; user_id: number; role: string }
    ) {}
}

export class ChangeInviteRole implements PayloadAction {
    readonly type: DiliboardActionType = DiliboardActionType.CHANGE_INVITE_ROLE;

    constructor(
        public payload: { diliboardId: number; inviteId: number; role: string }
    ) {}
}

export class InviteUser implements PayloadAction {
    readonly type: DiliboardActionType = DiliboardActionType.INVITE_USER;

    constructor(public payload: { body: any; diliboardId: number }) {}
}

export class SuccessfullyInviteUser implements Action {
    readonly type: DiliboardActionType =
        DiliboardActionType.SUCCESSFULLY_INVITE_USER;
}

export class RemoveUser implements PayloadAction {
    readonly type: DiliboardActionType = DiliboardActionType.REMOVE_USER;

    constructor(public payload: { diliboardId: number; friendId: number }) {}
}

export class RemoveInvitedUser implements PayloadAction {
    readonly type: DiliboardActionType =
        DiliboardActionType.REMOVE_INVITED_USER;

    constructor(public payload: { diliboardId: number; inviteId: number }) {}
}

export class AcceptInvite implements PayloadAction {
    readonly type: DiliboardActionType = DiliboardActionType.ACCEPT_INVITE;

    constructor(public payload: { body: any; boardId: number }) {}
}

export type DiliboardAction =
    | GetAllDiliboards
    | ErrorFetchingAllDiliboards
    | ResponseAllDiliboards
    | LoadMoreDiliboard
    | SetSortingDiliboard
    | GetAllBoardContent
    | ResetDiliboardsAndContent
    | SearchDiliboards
    | ResponseSearchDiliboard
    | ErrorSearchDiliboards
    | ResetContent
    | GetBoardUsers
    | ErrorFetchingBoardUsers
    | ResponseBoardUsers
    | GetBoardComments
    | ErrorFetchingBoardComments
    | ResponseBoardComments
    | PostComment
    | ErrorPostComment
    | SuccessfullyPostComment
    | GetBoardDetails
    | ErrorFetchingBoardDetails
    | ResponseBoardDetails
    | DeleteBoard
    | DeleteBoardContent
    | CreateBoard
    | StartWizard
    | ErrorStartWizard
    | StartWizardWithAttachment
    | TooBigAttachment
    | GoToFirstStep
    | GoToSecondStep
    | UpdateWizard
    | FinishWizard
    | ErrorWizard
    | EditBoard
    | EditDiliboardContent
    | GetUserStatusInBoard
    | ResponseUserStatusInBoard
    | ChangeFriendRole
    | ChangeInviteRole
    | InviteUser
    | SuccessfullyInviteUser
    | RemoveUser
    | RemoveInvitedUser
    | AcceptInvite;
