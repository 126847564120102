import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription, combineLatest } from 'rxjs';
import {
    selectSearchResult,
    selectSearchResultLoader,
    selectSearchResultCounter,
    selectSearchResultPageOptions
} from '../../store/diliboard.selector';
import { PageOptions } from '../../store/diliboard.state';
import { Diliboard } from 'src/app/models/diliboard';
import {
    LoadMoreDiliboard,
    SearchDiliboards
} from '../../store/diliboard.actions';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { distinctUntilChanged } from 'rxjs/operators';

const featureName: string = 'searchBoard';

@Component({
    selector: 'app-search-result',
    templateUrl: './search-result.component.html',
    styleUrls: ['./search-result.component.css']
})
export class SearchResultComponent implements OnInit, OnDestroy {
    loader$: Observable<boolean>;
    boards$: Observable<Diliboard[]>;
    boardsCount$: Observable<number>;
    searchTerm: string;
    private previousUrl: string = undefined;
    private currentUrl: string = undefined;
    private subscription: Subscription = new Subscription();
    constructor(
        private store: Store<any>,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.currentUrl = this.router.url;
        router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.previousUrl = this.currentUrl;
                this.currentUrl = event.url;
            }
        });
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            if (!!params['term']) {
                this.searchTerm = params['term'];
            }
        });

        this.boards$ = this.store.pipe(
            select(selectSearchResult, {
                featureName
            })
        );

        this.loader$ = this.store.pipe(
            select(selectSearchResultLoader, {
                featureName
            })
        );

        this.boardsCount$ = this.store.pipe(
            select(selectSearchResultCounter, {
                featureName
            }),
            distinctUntilChanged()
        );

        const pageOptions$: Observable<PageOptions> = this.store.pipe(
            select(selectSearchResultPageOptions, {
                featureName
            })
        );

        const subscription = combineLatest(pageOptions$).subscribe(
            ([pageOptions]: [PageOptions]) => {
                this.store.dispatch(
                    new SearchDiliboards({
                        term: this.searchTerm,
                        featureName
                    })
                );
            }
        );
        this.subscription.add(subscription);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    loadMore(): void {
        this.store.dispatch(new LoadMoreDiliboard({ featureName }));
    }
}
