<div class="welcome-wrapper">
    <img
        src="/assets/img/new-img/welcome-to-diligram.svg"
        alt="welcome-to-diligram.svg"
        class="welcome-to-diligram"
    />
    <div class="messages">
        <h1 class="title" *ngIf="type !== 'search'">
            {{ 'welcome.title' | translate }}
        </h1>
        <h1 class="title" *ngIf="type === 'search'">
            {{ 'welcome.search-title' | translate }}
        </h1>
        <p
            class="description"
            [innerHTML]="'welcome.' + type | translate"
            *ngIf="type !== 'search'"
        ></p>
        <p class="description" *ngIf="type === 'search'">
            {{ 'welcome.search-description' | translate }}
            <span
                class="link-search"
                routerLink="/my-diligrams"
                [queryParams]="{ createBoard: true }"
                >{{ 'welcome.search-link' | translate }}</span
            >
        </p>
    </div>
    <div class="white-background"></div>
</div>
