import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Diliboard } from 'src/app/models/diliboard';
import { Store } from '@ngrx/store';
import { EditDiliboardContent } from '../../../store/diliboard.actions';

@Component({
    selector: 'app-edit-content',
    templateUrl: './edit-content.component.html'
})
export class EditContentComponent implements OnInit {
    video: Diliboard;
    videoForm: FormGroup;

    constructor(
        private store: Store<any>,
        private dialog: MatDialog,
        private videoBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        this.video = data;
    }

    ngOnInit() {
        this.videoForm = this.videoBuilder.group({
            title: [
                this.video.title,
                [Validators.required, Validators.maxLength(90)]
            ]
        });
    }

    saveVideo(): void {
        const body = this.videoForm.value;
        const boardId: number = this.video.diliboard_id;
        const contentId: number = this.video.id;
        this.store.dispatch(
            new EditDiliboardContent({
                boardId,
                contentId,
                body
            })
        );
    }

    closeDialog(): void {
        this.dialog.closeAll();
    }
}
