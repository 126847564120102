import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DataChangeService {
    private dataChangeSubject = new Subject<string>();
    dataChangeState = this.dataChangeSubject.asObservable();

    setChange(params: string): void {
        this.dataChangeSubject.next(params);
    }
}
