<div class="board-action-wrapper">
    <div class="first-action-wrapper">
        <div class="back-to-classroom" (click)="backToClassroom()">
            <img
                src="/assets/img/icons/back-btn.svg"
                alt="back-btn"
                class="back-icon"
            />
        </div>
        <div class="board-select-wrapper">
            <app-dropdown
                [values]="sortValues"
                [placeholder]="dropdownPlaceholder"
                (emitValue$)="setSorting($event)"
            ></app-dropdown>
        </div>
    </div>
    <button
        class="btn-primary board-add-more"
        (click)="addVideo()"
        *ngIf="board.capabilities.add_contents"
    >
        <span class="hide-mobile">{{
            'diliboard.videos.add-content' | translate
        }}</span>
        <span class="hide-desktop">{{ 'diliboard.add-more' | translate }}</span>
        <span>+</span>
    </button>
</div>
<div
    class="board-wrapper"
    [ngClass]="{ 'transparent-wrapper': videos.length === 0 }"
>
    <div
        class="board-column"
        *ngFor="let video of videos"
        (click)="openOverlay(video)"
        [ngClass]="{
            text:
                video.type ===
                'Content::GenericFileContent::DescriptionWithLink',
            video: video.content_type === 'video',
            website: video.content_type === 'iframe' || video.source === 'www',
            quiz: video.content_type === 'quiz'
        }"
    >
        <div class="board-intro">
            <app-board-menu
                *ngIf="showBoardMenu(video)"
                [diliData]="video"
                [showPin]="showPin"
            ></app-board-menu>
            <p class="diliboard-title">
                {{ video.title }}
            </p>
        </div>
        <div class="board-info">
            <app-user-data [user]="video?.owner"></app-user-data>
        </div>
        <div class="board-role">
            {{
                video.type ===
                'Content::GenericFileContent::DescriptionWithLink'
                    ? ('diliboard.types.file' | translate)
                    : ('diliboard.types.' + video.content_type | translate)
            }}
        </div>
    </div>

    <app-welcome-message
        *ngIf="videos.length === 0"
        [type]="'board'"
    ></app-welcome-message>
</div>
