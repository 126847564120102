<div class="board-action-wrapper" *ngIf="type === 'my-classsrooms'">
    <div class="board-select-wrapper">
        <app-dropdown
            [values]="sortValues"
            [placeholder]="dropdownPlaceholder"
            (emitValue$)="setSorting($event)"
        ></app-dropdown>
    </div>
    <button class="btn-primary board-add-more" (click)="createBoard()">
        <span class="hide-mobile">{{
            boards.length > 1
                ? ('diliboard.add-more-diligrams' | translate)
                : ('diliboard.add-diligram' | translate)
        }}</span>
        <span class="hide-desktop">{{ 'diliboard.add-more' | translate }}</span>
        <span>+</span>
    </button>
</div>
<div
    class="board-wrapper"
    [ngClass]="{ 'transparent-wrapper': boards.length === 0 }"
>
    <div
        class="board-column {{ getBoardBackground(i + 1) }}"
        *ngFor="let board of boards; let i = index"
        (click)="navigateTo(board.id)"
    >
        <div class="board-intro">
            <app-board-menu
                *ngIf="showBoardMenu(board)"
                [diliData]="board"
            ></app-board-menu>
            <p class="diliboard-title">
                {{ board.title }}
            </p>
        </div>
        <div class="board-info">
            <app-user-data
                style="
                    display: block;
                    height: 100%;
                    display: flex;
                    align-items: center;
                "
                [user]="board.owner"
                [role]="board.user_role"
                [diliboardContentsCount]="board.diliboard_contents_count"
                [type]="'board'"
            >
            </app-user-data>
        </div>
        <div class="board-role">
            {{
                'modules.add-friend.roles.' + getUserRole(board.user_role)
                    | translate
            }}
        </div>
    </div>
    <app-welcome-message
        *ngIf="boards.length === 0 && !loader"
        [type]="type"
    ></app-welcome-message>
</div>
