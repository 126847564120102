import {
    Component,
    OnDestroy,
    OnInit,
    Output,
    EventEmitter
} from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { selectIsLoggedIn } from '../../store/user.selector';
import {
    UserForgotPassword,
    UserActionType,
    UserForgotPasswordSuccess
} from '../../store/user.actions';
import { Actions, ofType } from '@ngrx/effects';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
    forgotPasswordForm: FormGroup;
    private subscription: Subscription = new Subscription();
    @Output() changeShownComponent: EventEmitter<string> = new EventEmitter<
        string
    >();
    errorMessage: string;
    isLoggedIn: boolean;

    constructor(
        private router: Router,
        private forgotPasswordBuilder: FormBuilder,
        private _actions$: Actions,
        private store: Store<any>
    ) {}

    ngOnInit() {
        this.subscription.add(
            this.store.pipe(select(selectIsLoggedIn)).subscribe((loggedIn) => {
                this.isLoggedIn = loggedIn;
            })
        );
        this.setForm();
        if (this.isLoggedIn) {
            this.router.navigateByUrl('');
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    setForm(): void {
        this.forgotPasswordForm = this.forgotPasswordBuilder.group({
            email: ['', [Validators.email, Validators.required]]
        });
    }

    forgotPassword(): void {
        this.store.dispatch(
            new UserForgotPassword({
                email: this.forgotPasswordForm.value.email
            })
        );
        this.subscription.add(
            this._actions$
                .pipe(
                    ofType<UserForgotPasswordSuccess>(
                        UserActionType.FORGOT_PASSWORD_SUCCESS
                    )
                )
                .subscribe(() => {
                    this.login();
                })
        );
    }

    login(): void {
        this.router.navigateByUrl('/home/login');
    }
}
