import { Directive, ElementRef } from '@angular/core';
import {
    textFiles,
    dataFiles,
    vectorImagesFiles,
    rasterImagesFiles,
    pageLayoutFiles,
    spreadsheetFiles
} from './available.files.settings';

const availableFiles: string[] = [].concat(
    textFiles,
    dataFiles,
    vectorImagesFiles,
    rasterImagesFiles,
    pageLayoutFiles,
    spreadsheetFiles
);

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[diligram-files-accept]'
})
export class DiligramFileAcceptDirective {
    constructor(elementRef: ElementRef) {
        elementRef.nativeElement.accept = availableFiles;
    }
}
