<div class="wrapper">
    <div class="container">
        <div class="row">
            <div class="col-12 header" *ngIf="!(isLoggedIn$ | async)">
                <img
                    class="home-logo"
                    src="/assets/img/diligram-logo.svg"
                    alt="diligram-logo"
                    (click)="navigateTo('home')"
                />
                <app-language></app-language>
            </div>
            <div class="col-12 col-sm-12 imprint">
                <h1>{{ 'imprint.title' | translate }}</h1>
                <p>
                    Diligram AG
                    <br />Talstrasse 23 <br />6403 Küssnacht SZ
                    <br />Switzerland
                </p>
                <p>
                    {{ 'imprint.telephone' | translate }}
                    <a href="tel:+41415670132">+41 41 567 0132</a>
                </p>
                <p>
                    {{ 'imprint.email' | translate }}:
                    <a href="mailto:info@diligram.com">info@diligram.com</a>
                </p>
                <p>
                    {{ 'imprint.managing-director' | translate }} <br />Uwe
                    Placzek <br />{{ 'imprint.headquarters' | translate }}: 6403
                    Küssnacht SZ / Switzerland <br />{{
                        'imprint.legal-form' | translate
                    }}: Stock corporation <br />{{ 'imprint.uid' | translate }}:
                    CHE-357.808.076 <br />{{
                        'imprint.registry-office' | translate
                    }}: Canton of Schwyz
                </p>
                <p>
                    {{ 'imprint.content-part1' | translate }}
                    <br />{{ 'imprint.content-part2' | translate }} <br />{{
                        'imprint.content-part3' | translate
                    }}
                </p>
            </div>
        </div>
    </div>
</div>
