<app-account-info></app-account-info>
<div
    class="container"
    [ngClass]="{ 'transparent-wrapper': (boards$ | async).length === 0 }"
>
    <app-board
        [boards]="boards$ | async"
        (emitSorting$)="setSorting($event)"
        [type]="'my-classsrooms'"
        [loader]="loader$ | async"
    ></app-board>
    <button
        class="load-more"
        *ngIf="
            !(loader$ | async) &&
            (boards$ | async).length > 0 &&
            (boards$ | async).length < (myClassroomCount$ | async)
        "
        (click)="loadMore()"
    >
        {{ 'actions.load-more' | translate }}
    </button>
</div>
<app-data-loader *ngIf="loader$ | async"></app-data-loader>
