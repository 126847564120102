import {
    AfterContentChecked,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
    DataChangeService,
    DataService,
    NotificationCountService,
    NotificationsService
} from 'src/app/services';
import { Notification } from 'src/app/models/notification';

@Component({
    selector: 'app-notifications',
    templateUrl: 'notifications.component.html',
    styleUrls: ['notifications.component.css']
})
export class NotificationsComponent
    implements OnInit, AfterContentChecked, OnDestroy {
    public notifications: Notification[];
    private subscription: Subscription = new Subscription();

    constructor(
        private notifCountService: NotificationCountService,
        private notificationsService: NotificationsService,
        private dataService: DataService,
        private dataChangeService: DataChangeService,
        private router: Router,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.getNotifications();
    }

    ngAfterContentChecked(): void {
        this.cd.detectChanges();
    }

    ngOnDestroy() {
        this.subscription && this.subscription.unsubscribe();
    }

    // getUserData(notificationId, userId): void {
    //     const subscription = this.dataService
    //         .getUserPublicData(userId)
    //         .subscribe(userData => {
    //             const index = this.notifications.findIndex(
    //                 notification =>
    //                     notification.notifiable_id === notificationId
    //             );

    //             if (index >= 0) {
    //                 this.notifications[index] = Object.assign(
    //                     this.notifications[index],
    //                     userData
    //                 );
    //             }
    //         });
    //     this.subscription.add(subscription);
    // }

    getTimeSinceRead(timestamp: string) {
        const returnValue = {
            value: '',
            title: ''
        };
        if (!timestamp) {
            returnValue.title = 'diliboard.notifications.timestamp-error';
            return returnValue;
        }

        const currentTime = new Date().getTime();
        const readTime = new Date(timestamp).getTime();

        const timeDiff = (currentTime - readTime) / 1000; // Seconds

        if (timeDiff < 3600) {
            // Minutes
            const min = Math.floor(timeDiff / 60);
            returnValue.title = 'diliboard.notifications.minutes-ago';
            returnValue.value = `${min === 0 ? 1 : min}`;
            return returnValue;
        } else if (timeDiff < 86400) {
            // Hours
            const hour = Math.floor(timeDiff / 3600);
            returnValue.title = 'diliboard.notifications.hours-ago';
            returnValue.value = `${hour}`;
            return returnValue;
        } else if (timeDiff >= 86400 && timeDiff < 172800) {
            // Yesterday
            returnValue.title = 'diliboard.notifications.yesterday';
            return returnValue;
        } else {
            // Other dates
            const date = new Date(timestamp);
            returnValue.value =
                date.getDate() +
                '/' +
                (date.getMonth() + 1) +
                '/' +
                date.getFullYear();
            return returnValue;
        }
    }

    navigateTo(action: string, id: number): void {
        const notification = this.notifications.filter(
            (value) => value.id === id
        )[0];
        if (notification.notifiable_type === 'Invite') {
            return;
        }
        const subscription = this.notificationsService
            .readNotification(notification)
            .subscribe((res) => {
                this.dataChangeService.setChange('notification');
                notification.read_at = Date.now().toString();
                this.setNotificationCount();
            });
        this.subscription.add(subscription);

        if (
            action === 'DiliboardContent' ||
            action === 'Comment' ||
            action === 'Diliboard'
        ) {
            this.router.navigateByUrl(`/board/${notification.diliboard_id}`);
            this.dataChangeService.setChange('board');
        }
    }

    setNotificationCount(): void {
        const count = this.notifications.filter(
            (notification) => !notification.read_at
        ).length;
        this.notifCountService.setNotificationCount(count);
    }

    acceptInvite(notification, index): void {
        // const body = {
        //     invite_token: notification.invite_token
        // };
        // const subscription = this.dataService
        //     .acceptInvite(body)
        //     .subscribe(response => {
        //         const subscriptionNotificaion = this.notificationsService
        //             .readNotification(notification)
        //             .subscribe(res => {
        //                 notification.read_at = Date.now().toString();
        //                 this.setNotificationCount();
        //             });
        //         this.router.navigate(['/board', notification.diliboard_id]);
        //         this.subscription.add(subscriptionNotificaion);
        //     });
        // this.subscription.add(subscription);
        // this.hideElement(index);
    }

    rejectInvite(notification, index): void {
        const body = {
            invite_token: notification.invite_token
        };
        const subscription = this.dataService
            .rejectInvite(body)
            .subscribe((response) => {
                const subscriptionNotificaion = this.notificationsService
                    .readNotification(notification)
                    .subscribe((res) => {
                        notification.read_at = Date.now().toString();
                        this.setNotificationCount();
                    });
                this.subscription.add(subscriptionNotificaion);
            });
        this.subscription.add(subscription);
        this.hideElement(index);
    }

    getNotifications(): void {
        const subscription = this.notificationsService
            .getNotificationsSubject()
            .subscribe((notifications: Notification[]) => {
                this.notifications = notifications;
            });
        this.subscription.add(subscription);
    }

    hideElement(index: number): void {
        this.notifications.splice(index, 1);
        this.notifCountService.decrementNotificationCount();
    }
}
