import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ILanguage } from 'src/app/models/language.model';
import { CookiesService } from './cookie.service';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    constructor(
        private translateService: TranslateService,
        private cookieService: CookiesService
    ) {
        translateService.addLangs(this.supportedLanguages.map((el) => el.id));
    }

    private supportedLanguages: ILanguage[] = [
        { id: 'en', title: 'English' },
        { id: 'de', title: 'Deutsch' }
    ];

    currentLanguage: string;

    public getCurrentLanguage(): string {
        for (const el of this.supportedLanguages) {
            if (el.id === this.currentLanguage) {
                return el.id;
            }
        }
    }

    public getSupportedLanguages(): ILanguage[] {
        return this.supportedLanguages;
    }

    public setLanguage(language: string): void {
        this.cookieService.setCookie('displayLanguage', language, 365);
        this.currentLanguage = language;
        this.translateService.setDefaultLang(this.currentLanguage);
        this.translateService.use(language);
    }
}
