import {
    Component,
    OnInit,
    Input,
    ViewChild,
    ElementRef,
    HostListener,
    AfterViewInit
} from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, Observable, pipe } from 'rxjs';
import { Diliboard } from 'src/app/models/diliboard';
import { EditBoardComponent } from 'src/app/modules/diliboard/components/board/edit-board/edit-board.component';
import { AddRemoveFriendComponent } from 'src/app/modules/dialogs';
import { RegisterComponent } from 'src/app/modules/user/components/register/register.component';
import { Store } from '@ngrx/store';
import { GetBoardUsers } from '../../store/diliboard.actions';
import { FriendListInterface } from 'src/app/models/friend-list.model';
import {
    selectBoardUsers,
    selectTotalCollaboratorsInBoard,
    selectTotalViewersInBoard
} from '../../store/diliboard.selector';

@Component({
    selector: 'app-account-info',
    templateUrl: './account-info.component.html',
    styleUrls: ['./account-info.component.css']
})
export class AccountInfoComponent implements OnInit, AfterViewInit {
    @ViewChild('accountWrapper', { static: false }) accountWrapper: ElementRef;
    @Input() board: Diliboard;
    @Input() type: string;
    @Input() searchTerm: string;
    totalCollaborators$: Observable<number>;
    totalViewers$: Observable<number>;
    boardUsers: FriendListInterface[];
    accountWrapperHeight: number;

    private subscription: Subscription = new Subscription();

    constructor(
        private dialog: MatDialog,
        private router: Router,
        private store: Store<any>
    ) {}

    ngOnInit() {
        this.getDiliboardUsers();
    }

    ngAfterViewInit() {
        this.accountWrapperHeight = this.accountWrapper.nativeElement.offsetHeight;
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.accountWrapperHeight = this.accountWrapper.nativeElement.offsetHeight;
    }

    capitalize(name: string): string {
        if (!name) {
            return '';
        }

        const charArray = name.split('');
        charArray[0] = charArray[0].toUpperCase();
        return charArray.join('');
    }

    openDialog(params: string): void {
        const dialogClass = 'edit-dialog';
        this.dialog.closeAll();
        if (params === 'edit') {
            this.dialog.open(EditBoardComponent, {
                panelClass: dialogClass,
                data: {
                    data: this.board
                }
            });
        } else if (params === 'add-friend') {
            this.dialog.open(AddRemoveFriendComponent, {
                panelClass: dialogClass,
                data: {
                    boardId: this.board.id,
                    capabilities: this.board.capabilities
                }
            });
        } else if (params === 'register') {
            this.dialog.open(RegisterComponent, {
                panelClass: 'standard-dialog'
            });
        }
    }

    checkIfRouteIsMyDiligrams(): boolean {
        return this.router.url.includes('/my-diligrams');
    }

    checkIfRouteIsBoard(): boolean {
        return this.router.url.includes('board');
    }

    getDiliboardUsers(): void {
        if (this.board) {
            this.store.dispatch(new GetBoardUsers({ boardId: this.board.id }));
            this.subscription.add(
                this.store
                    .select(pipe(selectBoardUsers))
                    .subscribe((res) => (this.boardUsers = res))
            );
            this.totalCollaborators$ = this.store.select(
                pipe(selectTotalCollaboratorsInBoard)
            );
            this.totalViewers$ = this.store.select(
                pipe(selectTotalViewersInBoard)
            );
        }
    }
}
