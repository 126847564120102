<div
    class="home-container"
    [ngClass]="{
        'cookie-shown': showCookieBanner$ | async,
        background: (show$ | async) === ''
    }"
>
    <div *ngIf="!isMobile" class="home-left">
        <div class="home-header">
            <img
                class="home-logo"
                src="/assets/img/diligram-logo.svg"
                alt="diligram-logo"
                (click)="navigateTo('home')"
            />
            <app-language></app-language>
        </div>
        <div class="home-body">
            <router-outlet></router-outlet>
            <div class="legal-container">
                <p class="legal-link" (click)="navigateTo('terms')">
                    {{ 'home.terms' | translate }}
                </p>
                <p class="legal-link" (click)="navigateTo('imprint')">
                    {{ 'home.imprint' | translate }}
                </p>
            </div>
        </div>
    </div>
    <div *ngIf="!isMobile" class="home-right">
        <app-home-carousel></app-home-carousel>
    </div>

    <!-- Mobile -->
    <div
        *ngIf="isMobile"
        class="home-mobile"
        [ngClass]="{ background: (show$ | async) === '' }"
        autoscroll
    >
        <div class="home-header mobile">
            <img
                *ngIf="(show$ | async) === ''"
                class="home-logo"
                src="/assets/img/diligram-logo-white.svg"
                alt="diligram-logo"
                (click)="navigateTo('home')"
            />
            <img
                *ngIf="(show$ | async) !== ''"
                class="home-logo"
                src="/assets/img/diligram-logo.svg"
                alt="diligram-logo"
                (click)="navigateTo('home')"
            />
            <app-language [show]="show$"></app-language>
        </div>
        <div *ngIf="(show$ | async) === ''">
            <app-home-carousel></app-home-carousel>
        </div>
        <div class="home-body mobile">
            <button
                *ngIf="(show$ | async) === ''"
                class="btn-primary center-btn"
                type="submit"
                (click)="setShow('login')"
            >
                {{ 'home.sign-in' | translate }}
            </button>
            <div *ngIf="(show$ | async) === ''" class="home-no-account">
                <p>{{ 'home.no-account-yet' | translate }}</p>
                <p class="home-register" (click)="navigateTo('home/register')">
                    {{ 'home.register' | translate }}
                </p>
            </div>

            <router-outlet *ngIf="(show$ | async) !== ''"></router-outlet>
            <div class="legal-container mobile">
                <p
                    class="legal-link"
                    (click)="navigateTo('terms')"
                    [ngClass]="{ mobile: (show$ | async) === '' }"
                >
                    {{ 'home.terms' | translate }}
                </p>
                <p
                    class="legal-link"
                    (click)="navigateTo('imprint')"
                    [ngClass]="{ mobile: (show$ | async) === '' }"
                >
                    {{ 'home.imprint' | translate }}
                </p>
            </div>
        </div>
    </div>
</div>
