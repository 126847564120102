import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-data-loader',
    template: `
        <div
            style="width:100%;height:100%"
            class="lds-rolling {{ !!className ? className : '' }}"
        >
            <div></div>
        </div>
    `,
    styleUrls: ['./data-loader.component.css']
})
export class DataLoaderComponent {
    @Input() className: string;
}
