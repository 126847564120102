import { Component, Input } from '@angular/core';
import { DiliboardUserData } from 'src/app/models/diliboard';

@Component({
    selector: 'app-user-data',
    templateUrl: './user-data.component.html',
    styleUrls: ['./user-data.component.css']
})
export class UserDataComponent {
    @Input() user: DiliboardUserData;
    @Input() role?: string;
    @Input() diliboardContentsCount: number;
    @Input() type: string;
}
