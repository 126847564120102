<div class="container">
    <div class="first-wrapper">
        <img
            [routerLink]="['/my-diligrams']"
            src="/assets/img/diligram-logo.svg"
            alt="diligram-logo"
            class="diligram-logo"
        />
        <div class="search-wrapper hide-under-700">
            <input
                placeholder="{{ 'header.search' | translate }}"
                [(ngModel)]="searchTerm"
                valueChangeDelay="1000"
                (afterValueChanged)="search($event.target.value)"
                [matMenuTriggerFor]="searchMenu"
                #menuTrigger="matMenuTrigger"
            />
            <img
                class="search-icon"
                src="/assets/img/icons/search-icon.svg"
                *ngIf="!(loader$ | async)"
            />
            <app-data-loader
                [className]="'header'"
                *ngIf="loader$ | async"
            ></app-data-loader>
        </div>
        <mat-menu
            #searchMenu="matMenu"
            class="search-menu account-menu {{
                (searchResult$ | async).length === 0 ? 'hide-menu' : ''
            }}"
        >
            <div class="search-wrapper">
                <div class="search-item-container">
                    <p
                        class="search-menu-item"
                        *ngFor="let board of searchResult$ | async | slice: 0:6"
                        (click)="navigateToResult(board.id)"
                    >
                        {{ board.title }}
                    </p>
                    <p
                        class="search-more-link"
                        *ngIf="(searchResult$ | async).length > 0"
                        (click)="navigateToSearch()"
                    >
                        See more
                    </p>
                </div>
            </div>
        </mat-menu>
    </div>
    <div class="second-wrapper">
        <div
            class="header-wrapper show-under-700"
            (click)="showHideMobileSearch()"
        >
            <img class="search-icon" src="/assets/img/icons/search-icon.svg" />
        </div>
        <div
            class="header-wrapper menu-wrapper notifications-wrapper"
            [ngClass]="{ 'menu-open': notifications.menuOpen }"
            [matMenuTriggerFor]="notificationMenu"
            #notifications="matMenuTrigger"
        >
            <span class="notification-icon">
                <img src="/assets/img/icons/notifications.svg" />
                <span
                    class="notification-indicator"
                    *ngIf="notificationsCount > 0"
                ></span>
            </span>
            <span class="text">{{
                'header.navigation.notifications' | translate
            }}</span>
        </div>
        <mat-menu #notificationMenu="matMenu">
            <ng-template matMenuContent>
                <app-notifications></app-notifications>
            </ng-template>
        </mat-menu>
        <div
            class="header-wrapper menu-wrapper account-wrapper"
            [ngClass]="{ 'menu-open': account.menuOpen }"
            [matMenuTriggerFor]="accountMenu"
            #account="matMenuTrigger"
        >
            <img src="/assets/img/icons/header-user.svg" />
            <span class="text">{{ (diligramUser$ | async).first_name }}</span>
        </div>

        <mat-menu #accountMenu="matMenu" class="account-menu">
            <app-header-account></app-header-account>
        </mat-menu>
        <div class="language-wrapper">
            <app-language></app-language>
        </div>
    </div>
</div>
<div class="mobile-search-wrapper" *ngIf="showMobileSearch">
    <img
        class="close-icon"
        src="/assets/img/icons/exit.svg"
        (click)="showHideMobileSearch()"
    />
    <input
        class="search"
        [(ngModel)]="searchTerm"
        placeholder="{{ 'header.search' | translate }} Diligrams"
    />
    <button class="btn-primary search" (click)="navigateToSearch()">
        {{ 'header.search' | translate }}
    </button>
</div>
