import { NgModule, Injectable, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Angulartics2Module } from 'angulartics2';
import { DragScrollModule } from 'ngx-drag-scroll';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { AppComponent } from 'src/app/app.component';
import {
    HeaderComponent,
    FooterComponent,
    NotificationsComponent,
    NotFoundComponent,
    HomeCarouselComponent,
    HomeComponent
} from 'src/app/components';
import { DiliboardModule } from 'src/app/modules/diliboard/diliboard.module';
import {
    DataService,
    AuthGuard,
    DataChangeService,
    NotificationsService,
    NotificationCountService
} from 'src/app/services';
import { SharedModule } from 'src/app/shared.module';
import { SharedStuffModule } from 'src/app/modules/shared-stuff/shared-stuff.module';
import { ImprintComponent } from 'src/app/components/imprint/imprint.component';
import * as Sentry from '@sentry/browser';
import { environment } from 'src/environments/environment';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { HeaderAccountComponent } from './components/header-account/header-account.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer } from '@ngrx/router-store';
import { metaReducers } from './app.reducer';
import {
    AuthServiceConfig,
    GoogleLoginProvider,
    FacebookLoginProvider,
    SocialLoginModule
} from 'angularx-social-login';
import { UserModule } from './modules/user/user.module';
import { UserService } from './modules/user/user.service';
import { ResetPasswordComponent } from './modules/user/components';
import { CookiesService } from './services/cookie.service';
import { TermAndConditionsComponent } from './components/term-and-conditions/terms-and-conditions.component';

Sentry.init({
    // dsn: 'https://ea069ae832cf4257bece0fa758b0ddee@sentry.dev.c85.de/22',
    environment: environment.production
        ? 'production'
        : 'development or staging'
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    constructor() {}

    handleError(error) {
        const eventId = Sentry.captureException(error.originalError || error);
        Sentry.showReportDialog({ eventId });
    }
}

const config = new AuthServiceConfig([
    {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider(
            '950288597903-oj0i8kfqnnl8licv94ed0iblgb29mn2j.apps.googleusercontent.com'
        )
    },
    {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider('9E7L3GjK0d2UuP7ojkcjiUWu')
    }
]);

export function provideConfig() {
    return config;
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        NotificationsComponent,
        NotFoundComponent,
        ImprintComponent,
        HomeComponent,
        HeaderAccountComponent,
        HomeCarouselComponent,
        TermAndConditionsComponent
    ],
    entryComponents: [NotificationsComponent, ResetPasswordComponent],
    imports: [
        DiliboardModule,
        UserModule,
        SharedStuffModule,
        SocialLoginModule,
        SharedModule,
        Angulartics2Module.forRoot(),
        DragScrollModule,
        ClipboardModule,
        BrowserModule.withServerTransition({ appId: 'diligramwebinterface' }),
        TranslateModule,
        MDBBootstrapModule.forRoot(),
        StoreModule.forRoot(
            {
                routerReducer
            },
            {
                metaReducers
            }
        ),
        EffectsModule.forRoot([])
    ],
    providers: [
        DataService,
        AuthGuard,
        DataChangeService,
        NotificationsService,
        NotificationCountService,
        UserService,
        CookiesService,
        { provide: ErrorHandler, useClass: SentryErrorHandler },
        {
            provide: AuthServiceConfig,
            useFactory: provideConfig
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
