import {
    Component,
    OnDestroy,
    OnInit,
    Output,
    EventEmitter
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Register } from 'src/app/models/user';
import { Store, select } from '@ngrx/store';
import { selectIsLoggedIn } from '../../store/user.selector';
import {
    UserRegister,
    UserActionType,
    UserRegisterSuccess,
    UserRegisterFailed
} from '../../store/user.actions';
import { Actions, ofType } from '@ngrx/effects';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit, OnDestroy {
    registerForm: FormGroup;
    emailErrorMessage: string = null;
    passwordErrorMessage: string = null;
    private subscription: Subscription = new Subscription();
    @Output() changeShownComponent: EventEmitter<string> = new EventEmitter<
        string
    >();
    isLoggedIn: boolean;

    constructor(
        private registerBuilder: FormBuilder,
        private router: Router,
        private _actions$: Actions,
        private store: Store<any>
    ) {}

    ngOnInit() {
        this.subscription.add(
            this.store.pipe(select(selectIsLoggedIn)).subscribe((loggedIn) => {
                this.isLoggedIn = loggedIn;
            })
        );
        this.setForm();
        if (this.isLoggedIn) {
            this.router.navigateByUrl('');
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    setForm(): void {
        this.registerForm = this.registerBuilder.group({
            first_name: ['', [Validators.required]],
            last_name: ['', [Validators.required]],
            email: ['', [Validators.email, Validators.required]],
            password: ['', [Validators.minLength(6), Validators.required]],
            confirmPassword: [
                '',
                [Validators.minLength(6), Validators.required]
            ]
        });
    }

    register(): void {
        if (
            !!this.registerForm.value.password &&
            this.registerForm.value.confirmPassword ===
                this.registerForm.value.password
        ) {
            this.passwordErrorMessage = null;
            const body: Register = {
                email: this.registerForm.value.email,
                password: this.registerForm.value.password,
                first_name: this.registerForm.value.first_name,
                last_name: this.registerForm.value.last_name
            };
            this.store.dispatch(new UserRegister({ registerData: body }));

            this.subscription.add(
                this._actions$
                    .pipe(
                        ofType<UserRegisterSuccess>(
                            UserActionType.REGISTER_SUCCESS
                        )
                    )
                    .subscribe(() => {
                        this.login();
                    })
            );
            this.subscription.add(
                this._actions$
                    .pipe(
                        ofType<UserRegisterFailed>(
                            UserActionType.REGISTER_FAILED
                        )
                    )
                    .subscribe(() => {
                        this.emailErrorMessage = 'user.register.account-exists';
                    })
            );
        } else {
            this.passwordErrorMessage = 'user.register.password-mismatch';
        }
    }

    login(): void {
        this.router.navigateByUrl('/home/login');
    }
}
