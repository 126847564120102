import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';
import { UserState, userStateName } from './user.state';

const selectUserModule = createFeatureSelector<UserState>(userStateName);

export const selectUser: Selector<object, any> = createSelector(
    selectUserModule,
    (state: UserState) => state.userData
);

export const selectIsLoggedIn: Selector<object, any> = createSelector(
    selectUserModule,
    (state: UserState) => state.loggedIn
);

export const selectSocialLogin: Selector<object, any> = createSelector(
    selectUserModule,
    (state: UserState) => state.socialLogin
);

export const selectRememberLogin: Selector<object, any> = createSelector(
    selectUserModule,
    (state: UserState) => state.rememberLogin
);
export const selectRegisterData: Selector<object, any> = createSelector(
    selectUserModule,
    (state: UserState) => state.registerData
);
