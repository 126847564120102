import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    Validators,
    FormControl,
    AbstractControl
} from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { UserData, UpdateUser } from 'src/app/models/user';
import { Store, select } from '@ngrx/store';
import { selectUser } from '../../store/user.selector';
import { UserUpdateData } from '../../store/user.actions';

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit, OnDestroy {
    diligramUser$: Observable<UserData>;
    userForm: FormGroup;
    showAddIcon: boolean;
    description: string;
    private subscription: Subscription = new Subscription();

    get password(): AbstractControl {
        return this.userForm.controls['password'];
    }

    get password_confirmation(): AbstractControl {
        return this.userForm.controls['password_confirmation'];
    }

    constructor(
        private userBuilder: FormBuilder,
        private store: Store<object>
    ) {
        this.showAddIcon = false;
    }

    ngOnInit() {
        this.diligramUser$ = this.store.pipe(select(selectUser));
        this.setForm();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    setForm(): void {
        this.subscription.add(
            this.diligramUser$.subscribe((userData) => {
                this.userForm = this.userBuilder.group({
                    first_name: [
                        userData.first_name,
                        [
                            Validators.required,
                            Validators.pattern(/^[a-zA-Z0-9À-ž]*$/)
                        ]
                    ],
                    last_name: [
                        userData.last_name,
                        [
                            Validators.required,
                            Validators.pattern(/^[a-zA-Z0-9À-ž]*$/)
                        ]
                    ],
                    email: new FormControl({
                        value: userData.email,
                        disabled: true
                    }),
                    profile_text: [''],
                    language: ['en'],
                    password: [null, Validators.minLength(6)],
                    password_confirmation: [null, Validators.minLength(6)]
                });
            })
        );
    }

    updateUser(): void {
        const userData: UpdateUser = this.userForm.value;

        for (let key in userData) {
            if (!userData[key]) {
                delete userData[key];
            }
        }

        this.store.dispatch(new UserUpdateData({ userData }));
    }

    capitalize(name: string): string {
        if (!name) {
            return '';
        }

        const charArray = name.split('');
        charArray[0] = charArray[0].toUpperCase();
        return charArray.join('');
    }

    onPasswordChange() {
        if (this.password_confirmation.value == this.password.value) {
            this.password_confirmation.setErrors(null);
        } else {
            this.password_confirmation.setErrors({ mismatch: true });
        }
    }
}
