import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AppRoutingModule } from './app.routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BoardMenuComponent } from './components';
import { UserDataComponent } from './modules/user/components';
import {
    AddRemoveFriendComponent,
    PinDialogComponent
} from './modules/dialogs';
import { SafePipe, FilerIdPipe } from './pipe';
import { MatRadioModule } from '@angular/material/radio';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateOptionsPipe } from './pipe/selectTranslation.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AfterValueChangedDirective } from './directive/after-value-changed.directive';
import { DiligramFileAcceptDirective } from './directive/diligram-file-accept.directive';
import { CookieBannerComponent } from './components/cookie-banner/cookie-banner.component';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        SafePipe,
        FilerIdPipe,
        BoardMenuComponent,
        AddRemoveFriendComponent,
        PinDialogComponent,
        UserDataComponent,
        TranslateOptionsPipe,
        AfterValueChangedDirective,
        DiligramFileAcceptDirective,
        CookieBannerComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        AppRoutingModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        MatRadioModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            isolate: false
        }),
        MatCheckboxModule
    ],
    providers: [],
    exports: [
        TranslateModule,
        TranslateOptionsPipe,
        SafePipe,
        FilerIdPipe,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        AppRoutingModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        BoardMenuComponent,
        UserDataComponent,
        MatMenuModule,
        MatRadioModule,
        MatCheckboxModule,
        AfterValueChangedDirective,
        DiligramFileAcceptDirective,
        CookieBannerComponent
    ]
})
export class SharedModule {}
