import { UserState, initialUserState } from './user.state';
import {
    UserAction,
    UserActionType,
    UserLoginSuccess,
    UserGetDataSuccess,
    UserUpdateDataSuccess,
    UserLogin,
    UserUploadImageSuccess,
    UserRegister,
    UserUpdateToken
} from './user.actions';

export function userReducer(
    oldState: UserState = initialUserState,
    action: UserAction
): UserState {
    switch (action.type) {
        case UserActionType.LOGIN_SUCCESS: {
            const userData = (action as UserLoginSuccess).payload.userData;

            return {
                ...oldState,
                userData,
                loggedIn: true
            };
        }
        case UserActionType.LOGIN: {
            const socialLogin = (action as UserLogin).payload.socialLogin;
            const rememberLogin = (action as UserLogin).payload.rememberLogin;

            return {
                ...oldState,
                socialLogin,
                rememberLogin
            };
        }
        case UserActionType.GET_USER_DATA_SUCCESS: {
            const userData = (action as UserGetDataSuccess).payload.userData;

            return {
                ...oldState,
                userData
            };
        }
        case UserActionType.UPDATE_USER_DATA_SUCCESS: {
            const userData = Object.assign({}, oldState.userData);
            const updateData = (action as UserUpdateDataSuccess).payload
                .updateData;
            for (const key in updateData) {
                if (userData.hasOwnProperty(key)) {
                    userData[key] = updateData[key];
                }
            }

            return {
                ...oldState,
                userData
            };
        }
        case UserActionType.UPDATE_USER_TOKEN: {
            const userData = Object.assign({}, oldState.userData);
            const token = (action as UserUpdateToken).payload.token;
            const isSocial = (action as UserUpdateToken).payload.socialLogin;
            const rememberLog = (action as UserUpdateToken).payload
                .rememberLogin;
            let socialLogin = false;
            let rememberLogin = false;
            if (isSocial === 'true') {
                socialLogin = true;
            }
            if (rememberLog === 'true') {
                rememberLogin = true;
            }

            userData['authentication_token'] = token;

            return {
                ...oldState,
                userData,
                loggedIn: true,
                socialLogin,
                rememberLogin
            };
        }
        case UserActionType.UPLOAD_IMAGE_SUCCESS: {
            const userData = (action as UserUploadImageSuccess).payload
                .userData;

            return {
                ...oldState,
                userData
            };
        }
        case UserActionType.REGISTER: {
            const registerData = (action as UserRegister).payload.registerData;

            return {
                ...oldState,
                registerData
            };
        }
        case UserActionType.LOGOUT: {
            return {
                ...oldState,
                loggedIn: false
            };
        }
        default:
            return oldState;
    }
}
