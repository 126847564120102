import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NavigationLink } from 'src/app/models/navigation-links';
import { UserData } from 'src/app/models/user';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { selectUser, selectSocialLogin } from '../../modules/user/store/user.selector';
import { Observable } from 'rxjs';
import { UserLogout } from '../../modules/user/store/user.actions';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-header-account',
    templateUrl: './header-account.component.html',
    styleUrls: ['./header-account.component.css']
})
export class HeaderAccountComponent implements OnInit {
    diligramUser$: Observable<UserData>;
    links: NavigationLink[];
    socialLogin: boolean = false;

    constructor(private router: Router, private store: Store<any>) {}

    ngOnInit(): void {
        this.diligramUser$ = this.store.pipe(select(selectUser));
        this.links = [
            {
                name: 'header.navigation.account',
                link: 'account',
                icon: '/assets/img/icons/account_icon.svg'
            },
            {
                name: 'header.navigation.logout',
                link: 'logout',
                icon: '/assets/img/icons/log_out_icon.svg'
            }
        ];

        this.store
            .pipe(select(selectSocialLogin))
            .pipe(take(1))
            .subscribe((socialLogin) => {
                this.socialLogin = socialLogin;
            });
    }

    navigateTo(link: string): void {
        if (link === 'logout') {
            this.store.dispatch(
                new UserLogout({ socialLogin: this.socialLogin })
            );
        } else {
            this.router.navigateByUrl(link);
        }
    }
}
