import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import {
    NotificationCountService,
    NotificationsService
} from 'src/app/services';
import { Notification } from 'src/app/models/notification';
import { UserData } from 'src/app/models/user';
import { Store, select } from '@ngrx/store';
import {
    SearchDiliboards,
    GetAllBoardContent,
    ResetContent
} from 'src/app/modules/diliboard/store/diliboard.actions';
import { Diliboard } from 'src/app/models/diliboard';
import {
    selectSearchResult,
    selectSearchResultLoader
} from 'src/app/modules/diliboard/store/diliboard.selector';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { UserGetData } from '../../modules/user/store/user.actions';
import { selectUser } from '../../modules/user/store/user.selector';

const featureName: string = 'searchBar';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
    @ViewChild('menuTrigger', { static: false }) menuTrigger: MatMenuTrigger;
    diligramUser$: Observable<UserData>;
    notificationsCount: number = 0;
    searchTerm: string;
    showMobileSearch: boolean = false;
    searchResult$: Observable<Diliboard[]>;
    loader$: Observable<boolean>;
    private subscription: Subscription = new Subscription();

    constructor(
        private notificationCountService: NotificationCountService,
        private notificationsService: NotificationsService,
        private store: Store<any>,
        private router: Router
    ) {}

    ngOnInit() {
        this.store.dispatch(new UserGetData());
        this.diligramUser$ = this.store.pipe(select(selectUser));

        setTimeout(() => {
            this.checkNotification();
        }, 1000);

        let subscription = this.notificationCountService.onChangeNotificationCount.subscribe(
            (count) => {
                this.notificationsCount = count;
            }
        );
        this.subscription.add(subscription);

        this.searchResult$ = this.store.pipe(
            select(selectSearchResult, {
                featureName
            })
        );

        this.loader$ = this.store.pipe(
            select(selectSearchResultLoader, {
                featureName
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    setNotificationCount(inputCount: number): void {
        this.notificationCountService.setNotificationCount(inputCount);
    }

    getNotificationCount() {
        return this.notificationCountService.getNotificationCount();
    }

    showHideMobileSearch(): void {
        this.showMobileSearch = !this.showMobileSearch;
    }

    search(term: string): void {
        this.store.dispatch(new SearchDiliboards({ term, featureName }));
    }

    navigateToResult(boardId: number): void {
        this.menuTrigger.closeMenu();
        this.store.dispatch(new ResetContent());
        this.router.navigate([`/board/${boardId}`]);
    }

    navigateToSearch(): void {
        this.showMobileSearch = false;
        this.menuTrigger.closeMenu();
        if (this.router.url.includes('search')) {
            this.store.dispatch(
                new SearchDiliboards({
                    term: this.searchTerm,
                    featureName: 'searchBoard'
                })
            );
        }
        this.router.navigate(['/search'], {
            queryParams: { term: this.searchTerm }
        });
    }

    private checkNotification(): void {
        if (typeof window === 'undefined') {
            return;
        }

        const subscription = this.notificationsService
            .getNotificationsSubject()
            .subscribe((notifications: Notification[]) => {
                this.notificationsCount = notifications.filter(
                    (notification) => !notification.read_at
                ).length;
                this.setNotificationCount(this.notificationsCount);
            });

        this.subscription.add(subscription);
    }
}
