<div
    class="action-wrapper"
    [ngClass]="{
        active: diliboard.showBoardOptions
    }"
>
    <div
        class="action-circle action-menu first-menu"
        *ngIf="diliboard.capabilities.edit"
        (click)="edit(); $event.stopPropagation()"
    >
        <img src="/assets/img/icons/edit-white.svg" />
    </div>
    <div
        class="action-circle action-menu first-menu"
        *ngIf="diliboard.capabilities.destroy"
        (click)="delete(); $event.stopPropagation()"
    >
        <img class="delete" src="/assets/img/icons/delete_icon_white.svg" />
    </div>
    <div
        class="action-circle action-menu first-menu"
        *ngIf="showPin && diliboard.capabilities.pin"
        (click)="pinVideo(); $event.stopPropagation()"
    >
        <img src="/assets/img/icons/plus.svg" />
    </div>
    <div
        class="action-circle action-menu close-icon"
        (click)="hideMenuOrSocial(); $event.stopPropagation()"
    >
        <img
            class="close-icons"
            src="/assets/img/icons/cancel_icon_white.svg"
            alt="cancel_icon"
        />
    </div>
    <div
        class="action-circle action-menu menu-icon"
        (click)="showMenu(); $event.stopPropagation()"
    >
        <img src="/assets/img/icons/menu_icon.svg" />
    </div>
</div>
