import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DataLoaderComponent } from 'src/app/modules/shared-stuff/components/data-loader/data-loader.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { LanguageComponent } from './components/language/language.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared.module';
import { ConfirmationPopUpComponent } from './components/confirmation-pop-up/confirmation-pop-up.component';
import { WelcomeMessageComponent } from './components/welcome-message/welcome-message.component';

@NgModule({
    declarations: [
        DataLoaderComponent,
        DropdownComponent,
        LanguageComponent,
        ConfirmationPopUpComponent,
        WelcomeMessageComponent
    ],
    imports: [SharedModule, BrowserModule, NgSelectModule, FormsModule],
    exports: [
        DataLoaderComponent,
        DropdownComponent,
        LanguageComponent,
        ConfirmationPopUpComponent,
        WelcomeMessageComponent
    ]
})
export class SharedStuffModule {}
