import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    OnDestroy
} from '@angular/core';
import { Diliboard, DiliboardCapabilities } from 'src/app/models/diliboard';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CreateBoardComponent } from 'src/app/modules/diliboard/components';
import { Router, ActivatedRoute } from '@angular/router';
import { IDropDown } from '../../../../models/dropdown.model';
import { SortOrder } from 'src/app/util/sort.enum';
import { Store, select } from '@ngrx/store';
import { selectIsLoggedIn } from 'src/app/modules/user/store/user.selector';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-board',
    templateUrl: './board.component.html',
    styleUrls: ['./board.component.css'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class BoardComponent implements OnInit, OnDestroy {
    @Input() boards: Diliboard[];
    @Output() emitSorting$: EventEmitter<string> = new EventEmitter<string>();
    @Input() type: string;
    @Input() loader: boolean;
    checkDiliboard: boolean;
    dropdownPlaceholder: string = 'diliboard.sorting.placeholder';
    sortValues: IDropDown[] = [
        {
            value: SortOrder.title_ASC,
            translation: 'diliboard.sorting.title_ASC'
        },
        {
            value: SortOrder.title_DESC,
            translation: 'diliboard.sorting.title_DESC'
        },
        {
            value: SortOrder.created_at_ASC,
            translation: 'diliboard.sorting.created_at_ASC'
        },
        {
            value: SortOrder.created_at_DESC,
            translation: 'diliboard.sorting.created_at_DESC'
        }
    ];
    isLoggedIn: boolean;
    private subscription: Subscription = new Subscription();

    constructor(
        private dialog: MatDialog,
        private router: Router,
        private store: Store<any>,
        route: ActivatedRoute
    ) {
        this.subscription = route.queryParams.subscribe((params) => {
            if (params['createBoard'] && router.url.includes('my-diligrams')) {
                this.createBoard();
            }
        });
    }

    ngOnInit() {
        this.subscription.add(
            this.store.pipe(select(selectIsLoggedIn)).subscribe((loggedIn) => {
                this.isLoggedIn = loggedIn;
            })
        );
        if (
            (this.router.url.includes('/my-diligrams') ||
                this.router.url.includes('/forget_password')) &&
            this.isLoggedIn
        ) {
            this.checkDiliboard = true;
        }
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    createBoard(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'standard-dialog';

        this.dialog.open(CreateBoardComponent, dialogConfig);
    }

    navigateTo(boardId: number): void {
        this.router.navigate(['/board', boardId]);
    }

    getBoardBackground(boardIndex: number): string {
        switch (boardIndex % 8) {
            case 0:
            case 1:
                return 'heather';
            case 7:
            case 2:
                return 'lynch';
            case 6:
            case 3:
                return 'regent-grau';
            case 5:
            case 4:
                return 'bali-hai';
        }
    }

    getUserRole(userRole: string): string {
        return userRole ? userRole : 'public';
    }

    setSorting(event: IDropDown): void {
        this.emitSorting$.emit(event.value);
    }

    showBoardMenu(board: Diliboard): boolean {
        const capabilities: DiliboardCapabilities = board.capabilities;

        return capabilities.edit || capabilities.destroy;
    }
}
