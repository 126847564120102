<h1 class="forgot-password-title">
    {{ 'user.forgot-password.title' | translate }}
</h1>
<form [formGroup]="forgotPasswordForm">
    <div class="form-group">
        <label class="forgot-password-label">
            {{ 'user.forgot-password.description' | translate }}</label
        >
        <input
            matInput
            formControlName="email"
            placeholder="john@gmail.com"
            type="email"
            (keyup.enter)="forgotPassword()"
        />
        <p *ngIf="errorMessage" class="password-error-msg">
            <img
                class="close-icons"
                src="/assets/img/icons/alert.svg"
                alt="alert_icon"
            />
            {{ errorMessage | translate }}
        </p>
    </div>
</form>
<button
    class="btn-primary center-btn"
    type="submit"
    (click)="forgotPassword()"
    [disabled]="forgotPasswordForm.invalid"
>
    {{ 'user.forgot-password.reset-password' | translate }}
</button>
<hr class="half" />
<span class="or">{{ 'user.forgot-password.or' | translate }}</span>
<hr class="half" />
<button class="btn btn-transparent" type="submit" (click)="login()">
    {{ 'user.forgot-password.got-password' | translate }}
</button>
