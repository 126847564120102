import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnDestroy,
    OnInit,
    Output,
    EventEmitter
} from '@angular/core';
import { Diliboard, DiliboardCapabilities } from 'src/app/models/diliboard';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
    AddContentComponent,
    OverlayComponent
} from 'src/app/modules/diliboard/components';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IDropDown } from '../../../../../models/dropdown.model';
import { SortOrder } from 'src/app/util/sort.enum';
import { Store, select } from '@ngrx/store';
import {
    selectIsLoggedIn,
    selectUser
} from 'src/app/modules/user/store/user.selector';
import { UserData } from 'src/app/models/user';
import {
    UpdateWizard,
    ResetDiliboardsAndContent,
    GetAllBoardContent
} from '../../../store/diliboard.actions';

@Component({
    selector: 'app-diliboard-videos',
    templateUrl: './diliboard-videos.component.html',
    styleUrls: ['./diliboard-videos.component.css'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class DiliboardVideosComponent implements OnInit, OnDestroy {
    @Input() videos: Diliboard[];
    @Input() board: Diliboard;
    @Input() diligramsLength: number;
    @Output() emitSorting$: EventEmitter<string> = new EventEmitter<string>();
    searchResultView: boolean = true;
    showPin = true;
    checkDiliboard: boolean = false;
    makeItMineToggle: boolean;
    boardId: number;
    private subscription: Subscription = new Subscription();
    private isLoggedIn: boolean;
    dropdownPlaceholder: string = 'diliboard.sorting.placeholder';
    sortValues: IDropDown[] = [
        {
            value: SortOrder.title_ASC,
            translation: 'diliboard.sorting.title_ASC'
        },
        {
            value: SortOrder.title_DESC,
            translation: 'diliboard.sorting.title_DESC'
        },
        {
            value: SortOrder.created_at_ASC,
            translation: 'diliboard.sorting.created_at_ASC'
        },
        {
            value: SortOrder.created_at_DESC,
            translation: 'diliboard.sorting.created_at_DESC'
        }
    ];
    user: UserData;

    constructor(
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private router: Router,
        private store: Store<any>
    ) {}

    ngOnInit() {
        this.subscription.add(
            this.store.pipe(select(selectIsLoggedIn)).subscribe(loggedIn => {
                this.isLoggedIn = loggedIn;
            })
        );
        this.subscription.add(
            this.store.pipe(select(selectUser)).subscribe(user => {
                this.user = user;
            })
        );
        const subscription = this.route.params.subscribe(params => {
            this.boardId = +params['boardId'];
        });

        this.subscription.add(subscription);

        if (
            (this.router.url.includes('/my-diligrams') ||
                this.router.url.includes('/board')) &&
            this.isLoggedIn
        ) {
            this.checkDiliboard = true;
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    addVideo(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'standard-dialog';
        dialogConfig.data = this.board;
        this.dialog.open(AddContentComponent, dialogConfig);
    }

    openOverlay(video: Diliboard): void {
        if (typeof window !== 'undefined') {
            (<any>window).ga('send', 'event', {
                eventCategory: 'eventCategory',
                eventLabel: 'eventLabel',
                eventAction: 'eventAction',
                eventValue: 10
            });
        }
        const dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = `overlay-dialog`;
        dialogConfig.data = {
            video,
            allVideos: this.videos
        };
        this.dialog.open(OverlayComponent, dialogConfig);
    }

    backToClassroom(): void {
        this.router.navigate(['my-diligrams']);
    }

    setSorting(event: IDropDown): void {
        this.emitSorting$.emit(event.value);
    }

    showBoardMenu(video: Diliboard): boolean {
        const capabilities: DiliboardCapabilities = video.capabilities;

        return capabilities.edit || capabilities.destroy || capabilities.pin;
    }
}
