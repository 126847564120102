import { NgModule } from '@angular/core';
import {
    AccountInfoComponent,
    AddContentComponent,
    CommentsComponent,
    ContentComponent,
    CreateBoardComponent,
    EditBoardComponent,
    EditContentComponent,
    MyClassroomsComponent,
    OverlayComponent
} from './components';
import { DataChangeService, DataService } from 'src/app/services';
import { SharedModule } from 'src/app/shared.module';
import { SharedStuffModule } from 'src/app/modules/shared-stuff/shared-stuff.module';
import { DiliboardVideosComponent } from './components/content/diliboard-videos/diliboard-videos.component';
import { BoardComponent } from './components/board/board.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { diliboardStateName } from './store/diliboard.state';
import { diliboardReducer } from './store/diliboard.reducer';
import { DiliboardEffects } from './store/diliboard.effect';
import { SearchResultComponent } from './components/search-result/search-result.component';

@NgModule({
    declarations: [
        MyClassroomsComponent,
        BoardComponent,
        EditBoardComponent,
        CreateBoardComponent,
        OverlayComponent,
        ContentComponent,
        AddContentComponent,
        EditContentComponent,
        AccountInfoComponent,
        CommentsComponent,
        DiliboardVideosComponent,
        SearchResultComponent
    ],
    imports: [
        SharedModule,
        SharedStuffModule,
        StoreModule.forFeature(diliboardStateName, diliboardReducer),
        EffectsModule.forFeature([DiliboardEffects])
    ],
    providers: [DataService, DataChangeService]
})
export class DiliboardModule {}
