<ul type="none" class="notifications-list">
    <li
        *ngFor="let link of links; let i = index"
        class="item"
        (click)="navigateTo(link.link)"
    >
        <span class="links mob-account-menu-link">{{
            link.name | translate
        }}</span>
    </li>
</ul>
