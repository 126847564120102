import {
    DiliboardState,
    initialDiliboardState,
    PageOptions,
    DiliboardContentState,
    initialDiliboardContentState,
    initialSortOrder,
    DiliboardSearch,
    initialBoardState,
    BoardState
} from './diliboard.state';
import {
    DiliboardAction,
    DiliboardActionType,
    ResponseAllDiliboards,
    SetSortingDiliboard,
    ErrorFetchingAllDiliboards,
    LoadMoreDiliboard,
    ResponseSearchDiliboard,
    SearchDiliboards,
    ErrorSearchDiliboards,
    GetBoardUsers,
    ResponseBoardUsers,
    ResponseBoardComments,
    SuccessfullyPostComment,
    ResponseBoardDetails,
    UpdateWizard,
    ErrorStartWizard,
    GetAllBoardContent,
    ResponseUserStatusInBoard
} from './diliboard.actions';
import { Diliboard, Comments } from 'src/app/models/diliboard';
import {
    FriendListInterface,
    UserInviteInterface
} from 'src/app/models/friend-list.model';
import { AddNewContentSessionModel } from '../models/wizard-session.model';

export function diliboardReducer(
    oldState: DiliboardState = initialDiliboardState,
    action: DiliboardAction
): any {
    switch (action.type) {
        case DiliboardActionType.RESET_DILIBOARDS_AND_CONTENT: {
            const state: DiliboardState = {
                ...oldState,
                myClassroom: initialDiliboardContentState,
                board: { ...initialDiliboardContentState, ...initialBoardState }
            };

            return state;
        }
        case DiliboardActionType.GET_ALL_DILIBOARDS: {
            const myClassroom: DiliboardContentState = {
                ...oldState.myClassroom,
                dataLoader: true
            };
            const state: DiliboardState = {
                ...oldState,
                myClassroom
            };
            return state;
        }
        case DiliboardActionType.GET_ALL_BOARD_CONTENT: {
            const resetFlag = (action as GetAllBoardContent).payload.resetFlag;

            const board: DiliboardContentState & BoardState = {
                ...oldState.board,
                dataLoader: true
            };
            const state: DiliboardState = {
                ...oldState,
                board
            };

            return state;
        }
        case DiliboardActionType.ERROR_FETCHING_ALL_DILIBOARDS: {
            const featureName: string = (action as ErrorFetchingAllDiliboards)
                .payload.featureName;

            const diliboardContentData: DiliboardContentState = {
                ...oldState[featureName],
                dataLoader: false
            };
            const state: DiliboardState = {
                ...oldState,
                [featureName]: diliboardContentData
            };
            return state;
        }
        case DiliboardActionType.RESPONSE_ALL_DILIBOARDS: {
            const payload = (action as ResponseAllDiliboards).payload;

            const featureName: string = payload.featureName;

            const dataResponse: Diliboard[] = JSON.parse(
                JSON.stringify([...payload.data])
            );
            const data: Diliboard[] = oldState[featureName].data.concat(
                getDiliboardContent(dataResponse)
            );
            const dataCount: number = payload.dataCount;

            const diliboardContentData: DiliboardContentState = {
                ...oldState[featureName],
                data,
                dataLoader: false,
                dataCount
            };

            const state: DiliboardState = {
                ...oldState,
                [featureName]: diliboardContentData
            };

            return state;
        }
        case DiliboardActionType.LOAD_MORE_DILIBOARD: {
            const featureName: string = (action as LoadMoreDiliboard).payload
                .featureName;

            const dataRequest: PageOptions = {
                ...oldState[featureName].dataRequest,
                offset: oldState[featureName].dataRequest.limit,
                limit: oldState[featureName].dataRequest.limit + 8
            };

            const diliboardContentData: DiliboardContentState = {
                ...oldState[featureName],
                dataRequest
            };

            const state: DiliboardState = {
                ...oldState,
                [featureName]: diliboardContentData
            };

            return state;
        }
        case DiliboardActionType.SET_SORTING_DILIBOARD: {
            const payload = (action as SetSortingDiliboard).payload;

            const featureName: string = payload.featureName;
            const sort: string = payload.value;

            const dataRequest: PageOptions = {
                offset: initialSortOrder.offset,
                limit: initialSortOrder.limit,
                sort
            };

            const diliboardContentData: DiliboardContentState = {
                ...oldState[featureName],
                dataRequest,
                data: [],
                dataLoader: true
            };

            const state: DiliboardState = {
                ...oldState,
                [featureName]: diliboardContentData
            };

            return state;
        }
        case DiliboardActionType.SEARCH_DILIBOARDS: {
            const payload = (action as SearchDiliboards).payload;
            const term: string = payload.term;
            const featureName: string = payload.featureName;

            const featureData: DiliboardSearch = {
                ...oldState[featureName],
                data: [],
                loader: true,
                term,
                dataCount: 0
            };
            const state: DiliboardState = {
                ...oldState,
                [featureName]: featureData
            };

            return state;
        }
        case DiliboardActionType.RESPONSE_SEARCH_DILIBOARDS: {
            const payload = (action as ResponseSearchDiliboard).payload;
            const dataCount: number = payload.dataCount;
            const featureName: string = payload.featureName;

            const dataResponse: Diliboard[] = JSON.parse(
                JSON.stringify([...payload.data])
            );
            const data: Diliboard[] = oldState[featureName].data.concat(
                getDiliboardContent(dataResponse)
            );

            const featureData: DiliboardSearch = {
                ...oldState[featureName],
                data,
                loader: false,
                dataCount
            };
            const state: DiliboardState = {
                ...oldState,
                [featureName]: featureData
            };

            return state;
        }
        case DiliboardActionType.ERROR_SEARCH_DILIBOARDS: {
            const payload = (action as ErrorSearchDiliboards).payload;
            const featureName: string = payload.featureName;

            const featureData: DiliboardSearch = {
                ...oldState[featureName],
                loader: false
            };
            const state: DiliboardState = {
                ...oldState,
                [featureName]: featureData
            };

            return state;
        }
        case DiliboardActionType.RESET_CONTENT: {
            const state: DiliboardState = {
                ...oldState,
                board: { ...initialDiliboardContentState, ...initialBoardState }
            };

            return state;
        }
        case DiliboardActionType.GET_BOARD_USERS: {
            const boardId: number = (action as GetBoardUsers).payload.boardId;

            const board: DiliboardContentState & BoardState = {
                ...oldState.board,
                usersLoader: true,
                boardId
            };
            const state: DiliboardState = {
                ...oldState,
                board
            };

            return state;
        }
        case DiliboardActionType.ERROR_FETCHING_BOARD_USERS: {
            const board: DiliboardContentState & BoardState = {
                ...oldState.board,
                usersLoader: false
            };
            const state: DiliboardState = {
                ...oldState,
                board
            };

            return state;
        }
        case DiliboardActionType.RESPONSE_BOARD_USERS: {
            const users: FriendListInterface[] = (action as ResponseBoardUsers)
                .payload.users;

            const board: DiliboardContentState & BoardState = {
                ...oldState.board,
                users,
                usersLoader: false
            };

            const state: DiliboardState = {
                ...oldState,
                board
            };

            return state;
        }
        case DiliboardActionType.RESPONSE_USER_STATUS_IN_BOARD: {
            const usersStatus: UserInviteInterface[] = (action as ResponseUserStatusInBoard)
                .payload.users;

            const users: FriendListInterface[] = JSON.parse(
                JSON.stringify([...oldState.board.users])
            );

            const pendingUsers: FriendListInterface[] = [];

            usersStatus.map((value) => {
                if (value.status === 'pending') {
                    pendingUsers.push({
                        diliboard_id: value.diliboard_id,
                        id: value.id,
                        email: value.email,
                        status: value.status,
                        role: value.role
                    });
                }
            });

            const board: DiliboardContentState & BoardState = {
                ...oldState.board,
                users: users.concat(pendingUsers),
                usersLoader: false
            };

            const state: DiliboardState = {
                ...oldState,
                board
            };

            return state;
        }
        case DiliboardActionType.GET_BOARD_COMMENTS: {
            const boardId: number = (action as GetBoardUsers).payload.boardId;

            const board: DiliboardContentState & BoardState = {
                ...oldState.board,
                commentsLoader: true,
                boardId
            };
            const state: DiliboardState = {
                ...oldState,
                board
            };

            return state;
        }
        case DiliboardActionType.ERROR_FETCHING_BOARD_COMMENTS: {
            const board: DiliboardContentState & BoardState = {
                ...oldState.board,
                commentsLoader: false
            };
            const state: DiliboardState = {
                ...oldState,
                board
            };

            return state;
        }
        case DiliboardActionType.RESPONSE_BOARD_COMMENTS: {
            const comments: Comments[] = (action as ResponseBoardComments)
                .payload.comments;

            const board: DiliboardContentState & BoardState = {
                ...oldState.board,
                comments,
                commentsLoader: false
            };

            const state: DiliboardState = {
                ...oldState,
                board
            };

            return state;
        }
        case DiliboardActionType.POST_COMMENT: {
            const board: DiliboardContentState & BoardState = {
                ...oldState.board,
                commentPostingLoader: true,
                successfullyCommentLoader: false
            };
            const state: DiliboardState = {
                ...oldState,
                board
            };

            return state;
        }
        case DiliboardActionType.ERROR_POST_COMMENT: {
            const board: DiliboardContentState & BoardState = {
                ...oldState.board,
                commentPostingLoader: false,
                successfullyCommentLoader: false
            };
            const state: DiliboardState = {
                ...oldState,
                board
            };

            return state;
        }
        case DiliboardActionType.SUCCESSFULLY_POST_COMMENT: {
            const comment: Comments = (action as SuccessfullyPostComment)
                .payload.comment;

            const comments: Comments[] = oldState.board.comments.concat(
                comment
            );

            const board: DiliboardContentState & BoardState = {
                ...oldState.board,
                commentPostingLoader: false,
                successfullyCommentLoader: true,
                comments
            };
            const state: DiliboardState = {
                ...oldState,
                board
            };

            return state;
        }
        case DiliboardActionType.GET_BOARD_DETAILS: {
            const board: DiliboardContentState & BoardState = {
                ...oldState.board,
                boardDetailsLoader: true
            };
            const state: DiliboardState = {
                ...oldState,
                board
            };

            return state;
        }
        case DiliboardActionType.ERROR_FETCHING_BOARD_DETAILS: {
            const board: DiliboardContentState & BoardState = {
                ...oldState.board,
                boardDetailsLoader: false
            };
            const state: DiliboardState = {
                ...oldState,
                board
            };

            return state;
        }
        case DiliboardActionType.RESPONSE_BOARD_DETAILS: {
            const boardDetails: Diliboard = (action as ResponseBoardDetails)
                .payload.boardDetails;

            const board: DiliboardContentState & BoardState = {
                ...oldState.board,
                boardDetailsLoader: false,
                boardDetails
            };
            const state: DiliboardState = {
                ...oldState,
                board
            };

            return state;
        }
        case DiliboardActionType.GO_TO_FIRST_STEP: {
            const newContentSession = {
                ...oldState.newContentSession,
                loader: true
            };
            const state: DiliboardState = {
                ...oldState,
                newContentSession
            };

            return state;
        }
        case DiliboardActionType.START_WIZARD:
        case DiliboardActionType.START_WIZARD_WITH_ATTACHMENT:
        case DiliboardActionType.GO_TO_SECOND_STEP:
        case DiliboardActionType.FINISH_WIZARD: {
            const newContentSession = {
                ...oldState.newContentSession,
                loader: true,
                error: null,
                errorStep: null
            };
            const state: DiliboardState = {
                ...oldState,
                newContentSession
            };

            return state;
        }
        case DiliboardActionType.TOO_BIG_ATTACHMENT: {
            const newContentSession = {
                ...oldState.newContentSession,
                loader: false
            };
            const state: DiliboardState = {
                ...oldState,
                newContentSession
            };

            return state;
        }
        case DiliboardActionType.UPDATE_WIZARD: {
            const data: AddNewContentSessionModel = (action as UpdateWizard)
                .payload.data;

            const newContentSession = {
                ...oldState.newContentSession,
                data: {
                    ...oldState.newContentSession.data,
                    ...data
                },
                error: null,
                errorStep: null,
                loader: false
            };
            const state: DiliboardState = {
                ...oldState,
                newContentSession
            };

            return state;
        }
        case DiliboardActionType.ERROR_START_WIZARD: {
            const payload = (action as ErrorStartWizard).payload;
            const error: string = payload.error;
            const errorStep: number = payload.step;
            const newContentSession = {
                ...oldState.newContentSession,
                error,
                errorStep,
                loader: false
            };
            const state: DiliboardState = {
                ...oldState,
                newContentSession
            };

            return state;
        }
        case DiliboardActionType.ERROR_WIZARD: {
            const newContentSession = {
                ...oldState.newContentSession,
                loader: false
            };
            const state: DiliboardState = {
                ...oldState,
                newContentSession
            };

            return state;
        }
        default:
            return oldState;
    }
}

function getDiliboardContent(board: Diliboard[]): Diliboard[] {
    return board.map((value) => {
        value.showBoardOptions = false;
        return value;
    });
}
