import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { Diliboard } from 'src/app/models/diliboard';
import { Store, select } from '@ngrx/store';
import {
    GetAllDiliboards,
    LoadMoreDiliboard,
    SetSortingDiliboard,
    ResetDiliboardsAndContent
} from '../../store/diliboard.actions';
import {
    selectMyClassroomDashboard,
    selectLoaderMyClassroomDashboard,
    selectMyClassroomCount,
    selectPageOptionsMyClassroomDashboard
} from '../../store/diliboard.selector';
import { PageOptions } from '../../store/diliboard.state';

const featureName: string = 'myClassroom';

@Component({
    selector: 'app-my-classrooms',
    templateUrl: './my-classrooms.component.html',
    styleUrls: ['./my-classrooms.component.css']
})
export class MyClassroomsComponent implements OnInit, OnDestroy {
    loader$: Observable<boolean>;
    boards$: Observable<Diliboard[]>;
    myClassroomCount$: Observable<number>;
    private subscription: Subscription = new Subscription();

    constructor(private store: Store<any>) {}

    ngOnInit() {
        this.store.dispatch(new ResetDiliboardsAndContent());

        this.boards$ = this.store.pipe(
            select(selectMyClassroomDashboard, {
                featureName
            })
        );
        this.loader$ = this.store.pipe(
            select(selectLoaderMyClassroomDashboard, {
                featureName
            })
        );
        this.myClassroomCount$ = this.store.pipe(
            select(selectMyClassroomCount, {
                featureName
            })
        );
        const pageOptions$: Observable<PageOptions> = this.store.pipe(
            select(selectPageOptionsMyClassroomDashboard, {
                featureName
            })
        );

        const subscription = combineLatest(pageOptions$).subscribe(
            ([pageOptions]: [PageOptions]) => {
                this.store.dispatch(new GetAllDiliboards());
            }
        );
        this.subscription.add(subscription);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    loadMore(): void {
        this.store.dispatch(new LoadMoreDiliboard({ featureName }));
    }

    setSorting(event: string): void {
        this.store.dispatch(
            new SetSortingDiliboard({ value: event, featureName })
        );
    }
}
