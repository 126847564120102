import { Action } from '@ngrx/store';
import { PayloadAction } from 'src/app/util/payload.util';
import {
    Login,
    UserData,
    UpdateUser,
    Register,
    UserImage
} from 'src/app/models/user';

export const enum UserActionType {
    LOGIN = '[USER MODULE] LOGIN',
    LOGIN_SUCCESS = '[USER MODULE] LOGIN_SUCCESS',
    LOGIN_FAILED = '[USER MODULE] LOGIN_FAILED',
    REGISTER = '[USER MODULE] REGISTER',
    REGISTER_SUCCESS = '[USER MODULE] REGISTER_SUCCESS',
    REGISTER_FAILED = '[USER MODULE] REGISTER_FAILED',
    UPDATE_USER_DATA = '[USER MODULE] UPDATE_USER_DATA',
    UPDATE_USER_DATA_SUCCESS = '[USER MODULE] UPDATE_USER_DATA_SUCCESS',
    UPDATE_USER_TOKEN = '[USER MODULE] UPDATE_USER_TOKEN',
    GET_USER_TOKEN = '[USER MODULE] GET_USER_TOKEN',
    GET_USER_DATA = '[USER MODULE] GET_USER_DATA',
    GET_USER_DATA_SUCCESS = '[USER MODULE] GET_USER_DATA_SUCCESS',
    FORGOT_PASSWORD = '[USER MODULE] FORGOT_PASSWORD',
    FORGOT_PASSWORD_SUCCESS = '[USER MODULE] FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAILED = '[USER MODULE] FORGOT_PASSWORD_FAILED',
    RESET_PASSWORD = '[USER MODULE] RESET_PASSWORD',
    RESET_PASSWORD_SUCCESS = '[USER MODULE] RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILED = '[USER MODULE] RESET_PASSWORD_FAILED',
    UPLOAD_IMAGE = '[USER MODULE] UPLOAD_IMAGE',
    UPLOAD_IMAGE_SUCCESS = '[USER MODULE] UPLOAD_IMAGE_SUCCESS',
    UPLOAD_IMAGE_FAILED = '[USER MODULE] UPLOAD_IMAGE_FAILED',
    LOGOUT = '[USER MODULE] LOGOUT',
    LOGOUT_SUCCESS = '[USER MODULE] LOGOUT_SUCCESS',
    ERROR_OCCURRED = '[USER MODULE] ERROR_OCCURRED'
}

export const UserActionTypes: UserActionType[] = [
    UserActionType.LOGIN,
    UserActionType.LOGIN_SUCCESS,
    UserActionType.LOGIN_FAILED,
    UserActionType.REGISTER,
    UserActionType.REGISTER_SUCCESS,
    UserActionType.REGISTER_FAILED,
    UserActionType.UPDATE_USER_DATA,
    UserActionType.UPDATE_USER_DATA_SUCCESS,
    UserActionType.UPDATE_USER_TOKEN,
    UserActionType.GET_USER_TOKEN,
    UserActionType.GET_USER_DATA,
    UserActionType.GET_USER_DATA_SUCCESS,
    UserActionType.FORGOT_PASSWORD,
    UserActionType.FORGOT_PASSWORD_SUCCESS,
    UserActionType.FORGOT_PASSWORD_FAILED,
    UserActionType.RESET_PASSWORD,
    UserActionType.RESET_PASSWORD_SUCCESS,
    UserActionType.RESET_PASSWORD_FAILED,
    UserActionType.UPLOAD_IMAGE,
    UserActionType.UPLOAD_IMAGE_SUCCESS,
    UserActionType.UPLOAD_IMAGE_FAILED,
    UserActionType.LOGOUT,
    UserActionType.LOGOUT_SUCCESS,
    UserActionType.ERROR_OCCURRED
];

export class UserLogin implements PayloadAction {
    readonly type: UserActionType = UserActionType.LOGIN;

    constructor(
        public payload: {
            loginData: Login;
            rememberLogin: boolean;
            socialLogin: boolean;
        }
    ) {}
}

export class UserLoginSuccess implements PayloadAction {
    readonly type: UserActionType = UserActionType.LOGIN_SUCCESS;

    constructor(public payload: { userData: UserData }) {}
}

export class UserRegister implements PayloadAction {
    readonly type: UserActionType = UserActionType.REGISTER;

    constructor(public payload: { registerData: Register }) {}
}
export class UserRegisterSuccess implements Action {
    readonly type: UserActionType = UserActionType.REGISTER_SUCCESS;
}
export class UserRegisterFailed implements PayloadAction {
    readonly type: UserActionType = UserActionType.REGISTER_FAILED;

    constructor(public payload: { errorMessage: any }) {}
}

export class UserUpdateData implements PayloadAction {
    readonly type: UserActionType = UserActionType.UPDATE_USER_DATA;

    constructor(public payload: { userData: UpdateUser }) {}
}

export class UserUpdateToken implements PayloadAction {
    readonly type: UserActionType = UserActionType.UPDATE_USER_TOKEN;

    constructor(
        public payload: {
            token: string;
            socialLogin: string;
            rememberLogin: string;
        }
    ) {}
}
export class UserGetToken implements Action {
    readonly type: UserActionType = UserActionType.GET_USER_TOKEN;
}

export class UserUpdateDataSuccess implements PayloadAction {
    readonly type: UserActionType = UserActionType.UPDATE_USER_DATA_SUCCESS;

    constructor(public payload: { updateData: UpdateUser }) {}
}

export class UserGetData implements Action {
    readonly type: UserActionType = UserActionType.GET_USER_DATA;
}

export class UserGetDataSuccess implements PayloadAction {
    readonly type: UserActionType = UserActionType.GET_USER_DATA_SUCCESS;

    constructor(public payload: { userData: UserData }) {}
}

export class UserLoginFailed implements PayloadAction {
    readonly type: UserActionType = UserActionType.LOGIN_FAILED;

    constructor(public payload: { errorMessage: any }) {}
}
export class UserForgotPassword implements PayloadAction {
    readonly type: UserActionType = UserActionType.FORGOT_PASSWORD;

    constructor(public payload: { email: string }) {}
}
export class UserForgotPasswordSuccess implements Action {
    readonly type: UserActionType = UserActionType.FORGOT_PASSWORD_SUCCESS;
}
export class UserForgotPasswordFailed implements PayloadAction {
    readonly type: UserActionType = UserActionType.FORGOT_PASSWORD_FAILED;

    constructor(public payload: { errorMessage: string }) {}
}
export class UserResetPassword implements PayloadAction {
    readonly type: UserActionType = UserActionType.RESET_PASSWORD;

    constructor(public payload: { resetToken: string; newPassword: string }) {}
}
export class UserResetPasswordSuccess implements Action {
    readonly type: UserActionType = UserActionType.RESET_PASSWORD_SUCCESS;
}
export class UserResetPasswordFailed implements PayloadAction {
    readonly type: UserActionType = UserActionType.RESET_PASSWORD_FAILED;

    constructor(public payload: { errorMessage: string }) {}
}

export class UserUploadImage implements PayloadAction {
    readonly type: UserActionType = UserActionType.UPLOAD_IMAGE;

    constructor(public payload: { userImage: UserImage }) {}
}
export class UserUploadImageSuccess implements PayloadAction {
    readonly type: UserActionType = UserActionType.UPLOAD_IMAGE_SUCCESS;

    constructor(public payload: { userData: UserData }) {}
}
export class UserUploadImageFailed implements PayloadAction {
    readonly type: UserActionType = UserActionType.UPLOAD_IMAGE_FAILED;

    constructor(public payload: { errorMessage: string }) {}
}

export class UserLogout implements PayloadAction {
    readonly type: UserActionType = UserActionType.LOGOUT;

    constructor(public payload: { socialLogin: boolean }) {}
}

export class UserLogoutSuccess implements Action {
    readonly type: UserActionType = UserActionType.LOGOUT_SUCCESS;
}

export class UserErrorOccurred implements PayloadAction {
    readonly type: UserActionType = UserActionType.ERROR_OCCURRED;

    constructor(public payload: { method: string; error: string }) {}
}

export type UserAction =
    | UserLogin
    | UserLoginSuccess
    | UserLoginFailed
    | UserLogout
    | UserLogoutSuccess
    | UserRegister
    | UserRegisterSuccess
    | UserRegisterFailed
    | UserUpdateData
    | UserUpdateDataSuccess
    | UserUpdateToken
    | UserGetToken
    | UserGetData
    | UserGetDataSuccess
    | UserForgotPassword
    | UserForgotPasswordSuccess
    | UserForgotPasswordFailed
    | UserResetPassword
    | UserResetPasswordSuccess
    | UserResetPasswordFailed
    | UserUploadImage
    | UserUploadImageSuccess
    | UserUploadImageFailed
    | UserErrorOccurred;
