import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
    HttpParams
} from '@angular/common/http';
import { NEVER, throwError, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/modules/user/user.service';
import { Diliboard } from 'src/app/models/diliboard';

@Injectable({ providedIn: 'root' })
export class DiliboardService {
    headers = this.getHeaders();

    constructor(private http: HttpClient, private userService: UserService) {}

    getAllDiliboards(offset: number, limit: number, sort: string) {
        const params = new HttpParams()
            .set('offset', offset.toString())
            .set('limit', limit.toString())
            .set('sort', sort);

        const url: string = this.getUrl() + `diliboards.json`;

        return this.http
            .get(url, {
                params,
                headers: this.getHeaders(),
                observe: 'response'
            })
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    showDiliboardContent(
        boardId: number,
        offset: number,
        limit: number,
        sort: string
    ) {
        const params = new HttpParams()
            .set('offset', offset.toString())
            .set('limit', limit.toString())
            .set('sort', sort);

        const url: string =
            this.getUrl() + `diliboards/${boardId}/diliboard_contents.json`;

        return this.http
            .get(url, {
                params,
                headers: this.getHeaders(),
                observe: 'response'
            })
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    getSearchResult(offset: number, limit: number, term: string) {
        const params = new HttpParams()
            .set('offset', offset.toString())
            .set('limit', limit.toString())
            .set('term', term);

        const url: string = this.getUrl() + 'search.json';

        return this.http
            .get(url, {
                params,
                headers: this.getHeaders(),
                observe: 'response'
            })
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    getBoardUsers(boardId: number) {
        const url: string =
            this.getUrl() + `diliboards/${boardId}/diliboard_users.json`;

        return this.http
            .get(url, {
                headers: this.getHeaders(),
                observe: 'response'
            })
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    getUsersStatusInBoard(boardId: number) {
        const url: string = this.getUrl() + `diliboards/${boardId}/invites`;

        return this.http
            .get(url, {
                headers: this.getHeaders(),
                observe: 'response'
            })
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    getBoardComments(boardId: number) {
        const url: string =
            this.getUrl() + `diliboards/${boardId}/comments.json`;

        return this.http
            .get(url, {
                headers: this.getHeaders(),
                observe: 'response'
            })
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    postComment(text: string, boardId: number, user_id: number) {
        const body = {
            text,
            user_id
        };
        const url: string =
            this.getUrl() + `diliboards/${boardId}/comments.json`;

        return this.http
            .post(url, body, {
                headers: this.getHeaders(),
                observe: 'response'
            })
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    getBoardDetails(boardId: number) {
        const url: string = this.getUrl() + `diliboards/${boardId}.json`;

        return this.http
            .get(url, {
                headers: this.getHeaders(),
                observe: 'response'
            })
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    deleteBoard(boardId: number) {
        const url: string = this.getUrl() + `diliboards/${boardId}.json`;

        return this.http
            .delete(url, {
                headers: this.getHeaders(),
                observe: 'response'
            })
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    createDiliboard(body: Diliboard): Observable<any> {
        const url: string = this.getUrl() + `diliboards.json`;

        return this.http
            .post(url, body, {
                headers: this.getHeaders(),
                observe: 'response'
            })
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    deleteBoardContent(boardId: number, contentId: number) {
        const url: string =
            this.getUrl() +
            `diliboards/${boardId}/diliboard_contents/${contentId}.json`;

        return this.http
            .delete(url, {
                headers: this.getHeaders(),
                observe: 'response'
            })
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    // ADD NEW CONTENT WIZARD

    startWizard(diliboardId: number, urlParams: string): Observable<any> {
        const body = {
            url: urlParams
        };

        const url: string =
            this.getUrl() + `diliboards/${diliboardId}/wizard_sessions`;

        return this.http
            .post(url, body, {
                headers: this.getHeaders(),
                observe: 'response'
            })
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    startWizardWithAttachment(boardId, attachment) {
        const formData = new FormData();
        formData.append('file', attachment);

        const url: string = `${this.getUrl()}diliboards/${boardId}/wizard_sessions`;

        return this.http
            .post<any>(url, formData, {
                headers: new HttpHeaders({
                    Authorization: `Token token=${this.userService.getToken()}`
                }),
                observe: 'response'
            })
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    updateWizard(diliboardId: number, body, wizardId: number): Observable<any> {
        const url: string =
            this.getUrl() +
            `diliboards/${diliboardId}/wizard_sessions/${wizardId}`;

        return this.http
            .put(url, body, {
                headers: this.getHeaders(),
                observe: 'response'
            })
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    finishWizard(diliboardId: number, wizardId: number): Observable<any> {
        const url: string =
            this.getUrl() +
            `diliboards/${diliboardId}/wizard_sessions/${wizardId}/finish`;

        return this.http
            .post(url, '', {
                headers: this.getHeaders(),
                observe: 'response'
            })
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    editBoard(body: Diliboard, boardId: number): Observable<any> {
        const url: string = this.getUrl() + `diliboards/${boardId}`;

        return this.http
            .put(url, body, {
                headers: this.getHeaders(),
                observe: 'response'
            })
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    editDiliboardContent(
        boardId: number,
        contentId: number,
        body: any
    ): Observable<any> {
        const url: string =
            this.getUrl() +
            `diliboards/${boardId}/diliboard_contents/${contentId}.json`;

        return this.http
            .put(url, body, {
                headers: this.getHeaders(),
                observe: 'response'
            })
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    inviteUser(body: any): Observable<any> {
        const url: string = `${this.getUrl()}invites.json`;

        return this.http
            .post<any>(url, body, {
                headers: new HttpHeaders({
                    Authorization: `Token token=${this.userService.getToken()}`
                }),
                observe: 'response'
            })
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    removeUser(boardId: number, userId: number): Observable<any> {
        const url: string = `${this.getUrl()}diliboards/${boardId}/diliboard_users/${userId}.json`;

        return this.http
            .delete(url, {
                headers: new HttpHeaders({
                    Authorization: `Token token=${this.userService.getToken()}`
                }),
                observe: 'response'
            })
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    removeInvitedUser(inviteId: number) {
        const url: string = `${this.getUrl()}invites/${inviteId}`;

        return this.http
            .delete(url, {
                headers: new HttpHeaders({
                    Authorization: `Token token=${this.userService.getToken()}`
                }),
                observe: 'response'
            })
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    changeFriendRole(diliboardID: number, diliboard_user_id: number, body) {
        const url: string =
            this.getUrl() +
            `diliboards/${diliboardID}/diliboard_users/${diliboard_user_id}`;

        return this.http
            .put(url, body, {
                headers: this.getHeaders(),
                observe: 'response'
            })
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    changeInviteRole(inviteId: number, body) {
        const url: string = this.getUrl() + `invites/${inviteId}`;

        return this.http
            .put(url, body, {
                headers: this.getHeaders(),
                observe: 'response'
            })
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    acceptInvite(body: any): Observable<any> {
        const url: string = this.getUrl() + `invites/accept.json`;

        return this.http
            .put(url, body, {
                headers: this.getHeaders(),
                observe: 'response'
            })
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    getUrl(): string {
        return environment.apiBaseURL;
    }

    getHeaders(): any {
        if (this.userService.isLoggedIn()) {
            return new HttpHeaders({
                'Content-type': 'application/json',
                Authorization: `Token token=${this.userService.getToken()}`
            });
        } else {
            return new HttpHeaders({
                'Content-type': 'application/json'
            });
        }
    }
}
