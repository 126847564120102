<div class="cookie-banner-wrapper">
    <span class="cookie-banner-text">
        We use cookies on this site to enhance your experience. By using this
        website or closing this message you agree to our
        <span class="cookie-banner-link" (click)="navigateTo('terms')"
            >use of cookies.</span
        >
    </span>
    <span (click)="closeBanner()" class="cookie-banner-close">X</span>
</div>
