<div class="add-remove-wrapper" *ngIf="!showWhatIs">
    <div
        class="diligram-members"
        [ngClass]="{ 'only-user-list': !capabilities.invite_users }"
    >
        <img
            src="/assets/img/icons/cancel_icon.svg"
            alt="cancel_icon"
            class="close-icon"
            (click)="closeDialog()"
        />
        <h1 class="title">{{ 'add-remove-friend.title' | translate }}</h1>
        <p class="description" *ngIf="capabilities.invite_users">
            {{ 'add-remove-friend.description' | translate }}
        </p>
        <div class="invite-wrapper" *ngIf="capabilities.invite_users">
            <form [formGroup]="addForm">
                <input
                    formControlName="email"
                    type="text"
                    placeholder="Search or invite users by email"
                    (keyup.enter)="addFriend()"
                />
            </form>
            <button
                class="btn-primary center-btn"
                (click)="addFriend()"
                [disabled]="addForm.invalid"
            >
                {{ 'add-remove-friend.invite' | translate }}
            </button>
        </div>
        <p class="invite-description" *ngIf="capabilities.invite_users">
            {{ 'add-remove-friend.invite-description' | translate }}
        </p>
    </div>
    <div class="users-wrapper" *ngIf="(friendList$ | async).length > 0">
        <div
            class="user"
            *ngFor="let friend of friendList$ | async; let i = index"
        >
            <div class="first-part">
                <p class="user-data" *ngIf="friend.user">
                    {{ friend.user.first_name }} {{ friend.user.last_name }}
                </p>
                <p class="user-data" *ngIf="friend.email">
                    {{ friend.email }}
                </p>
                <span
                    class="role"
                    *ngFor="let role of roles"
                    [ngClass]="{ active: role === friend.role }"
                    (click)="
                        changeFriendRole(
                            friend.diliboard_id,
                            friend.id,
                            role,
                            friend.status,
                            friend.id
                        )
                    "
                    >{{ 'modules.add-friend.roles.' + role | translate }}</span
                >
            </div>
            <button
                *ngIf="capabilities.invite_users"
                class="btn btn-primary remove hide-under-768"
                (click)="
                    deleteFriend(
                        friend.diliboard_id,
                        friend.id,
                        friend.user ? friend.user.first_name : null,
                        friend.user ? friend.user.last_name : null,
                        friend.status ? friend.status : null,
                        friend.email ? friend.email : null
                    )
                "
            >
                <span class="remove-text">
                    {{ 'add-remove-friend.remove' | translate }}</span
                >
                <span class="joined-text" *ngIf="!friend.status">
                    {{ 'add-remove-friend.joined' | translate }}</span
                >
                <span class="joined-text" *ngIf="friend.status">
                    {{ 'add-remove-friend.invited' | translate }}</span
                >
            </button>
            <button
                *ngIf="capabilities.invite_users"
                class="btn btn-primary remove show-under-768"
                (click)="
                    deleteFriend(
                        friend.diliboard_id,
                        friend.id,
                        friend.user ? friend.user.first_name : null,
                        friend.user ? friend.user.last_name : null,
                        friend.status ? friend.status : null,
                        friend.email ? friend.email : null
                    )
                "
            >
                {{ 'add-remove-friend.remove' | translate }}
            </button>
        </div>
    </div>
    <div class="user-explanation" *ngIf="(friendList$ | async).length > 0">
        <p [innerHTML]="'add-remove-friend.role-description' | translate"></p>
        <img
            src="/assets/img/icons/question-mark.svg"
            alt="question-mark"
            class="question-mark"
            (click)="showHideWhatIs()"
        />
    </div>
    <div
        class="user-empty-wrapper"
        *ngIf="(friendList$ | async).length == 0 && capabilities.invite_users"
    >
        <img
            src="/assets/img/icons/friend-icon.svg"
            alt="friend-icon"
            class="friend-icon"
        />
        <p class="empty-list-description">
            {{ 'add-remove-friend.empty' | translate }}
        </p>
    </div>
</div>

<div class="add-remove-wrapper show-what-is" *ngIf="showWhatIs">
    <div class="diligram-members">
        <img
            src="/assets/img/icons/cancel_icon.svg"
            alt="cancel_icon"
            class="close-icon"
            (click)="closeDialog()"
        />
        <div class="back-btn" (click)="showHideWhatIs()">
            <img
                src="/assets/img/icons/back-btn.svg"
                alt="back-btn"
                class="back-icon"
            />
        </div>
        <h1>
            {{ 'add-remove-friend.role-title' | translate }}
        </h1>
    </div>
    <div class="user-wrapper intro">
        <h1>
            {{ 'add-remove-friend.viewer.title' | translate }}
        </h1>
        <p>
            {{ 'add-remove-friend.viewer.description' | translate }}
        </p>
        <h1>
            {{ 'add-remove-friend.collaborator.title' | translate }}
        </h1>
        <p>
            {{ 'add-remove-friend.collaborator.description' | translate }}
        </p>
        <h1>
            {{ 'add-remove-friend.contributor.title' | translate }}
        </h1>
        <p>
            {{ 'add-remove-friend.contributor.description' | translate }}
        </p>
    </div>
</div>
