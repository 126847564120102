import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
    Register,
    UserImage,
    Login,
    UpdateUser,
    UserData
} from 'src/app/models/user';
import { Store, select } from '@ngrx/store';
import { selectIsLoggedIn, selectUser } from './store/user.selector';
import { initialUserDataState } from './store/user.state';

@Injectable()
export class UserService implements OnDestroy {
    headers: HttpHeaders;
    options: any;
    private subscription: Subscription = new Subscription();
    loggedIn: boolean;
    user: UserData = initialUserDataState;

    constructor(private http: HttpClient, private store: Store<any>) {
        this.subscription.add(
            this.store.pipe(select(selectIsLoggedIn)).subscribe((loggedIn) => {
                this.loggedIn = loggedIn;
            })
        );
        this.subscription.add(
            this.store.pipe(select(selectUser)).subscribe((user) => {
                this.user = user;
                this.headers = this.getHeaders();
                this.options = this.getOptions();
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    // User
    login(loginData: Login): Observable<any> {
        const url: string = this.getUrl() + `sessions`;
        return this.http
            .post(url, loginData, {
                observe: 'response'
            })
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    register(registerData: Register): Observable<any> {
        const url: string = this.getUrl() + `users`;
        return this.http
            .post(
                url,
                {
                    user: registerData
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    forgotPassword(email: string): Observable<any> {
        const url: string = this.getUrl() + `users/forgot_password`;
        return this.http
            .post(
                url,
                { email: email },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    resetPassword(token: string, password: string): Observable<any> {
        const url: string = this.getUrl() + `users/reset_password`;
        return this.http
            .post(
                url,
                {
                    user: {
                        reset_password_token: token,
                        password: password,
                        password_confirmation: password
                    }
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    uploadUserImage(userImage: UserImage): Observable<any> {
        const url: string = this.getUrl() + `users/image_upload`;
        return this.http
            .post(
                url,
                {
                    user: userImage
                },
                {
                    headers: this.getHeaders(),
                    observe: 'response'
                }
            )
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }
    getUserPublicData(userId: number): Observable<any> {
        const url: string = this.getUrl() + `users/${userId}.json`;
        return this.http
            .get(url, {
                headers: this.getHeaders(),
                observe: 'response'
            })
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    getUserData(): Observable<any> {
        const url: string = this.getUrl() + `users/self.json`;

        return this.http.get(url, { headers: this.headers }).pipe(
            map((response) => {
                return response;
            })
        );
    }

    updateUser(userData: UpdateUser): Observable<any> {
        const url: string = this.getUrl() + `users/profile_edit`;

        return this.http
            .put(
                url,
                { user: userData },
                { headers: this.headers, observe: 'response' }
            )
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    getUrl(): string {
        return environment.apiBaseURL;
    }

    getOptions(): any {
        return {
            observe: 'response'
        };
    }

    getHeaders(): any {
        if (this.loggedIn) {
            return new HttpHeaders({
                'Content-type': 'application/json',
                Authorization: `Token token=${this.getToken()}`
            });
        } else {
            return new HttpHeaders({
                'Content-type': 'application/json'
            });
        }
    }

    isLoggedIn(): boolean {
        return this.loggedIn;
    }

    getToken(): string {
        return this.user.authentication_token
            ? this.user.authentication_token
            : '';
    }
}
