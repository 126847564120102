<h1 class="login-title">
    {{ 'user.login.sign-in' | translate }}
</h1>
<form [formGroup]="loginForm">
    <div class="form-group">
        <label class="login-label">{{ 'user.login.email' | translate }}</label>
        <input
            matInput
            formControlName="email"
            placeholder="john@gmail.com"
            type="email"
            (keyup.enter)="login()"
        />
    </div>
    <div class="form-group">
        <label class="login-label">{{
            'user.login.password' | translate
        }}</label>
        <input
            matInput
            formControlName="password"
            type="password"
            (keyup.enter)="login()"
        />
    </div>
    <div class="login-remember">
        <mat-checkbox (click)="rememberMe = !rememberMe"
            >{{ 'user.login.remember-me' | translate }}
        </mat-checkbox>
        <p
            (click)="navigateTo('forgot-password')"
            class="login-forgot-password"
        >
            {{ 'user.login.forgot-password' | translate }}
        </p>
    </div>
    <div *ngIf="errorMessage" class="error-msg">
        <img
            class="close-icons"
            src="/assets/img/icons/alert.svg"
            alt="alert_icon"
        />
        {{ errorMessage | translate }}
    </div>
</form>
<button
    class="btn-primary center-btn"
    type="submit"
    (click)="login()"
    [disabled]="loginForm.invalid && loginForm.touched"
>
    {{ 'user.login.sign-in' | translate }}
</button>
<hr class="half" />
<span class="or">{{ 'user.login.or' | translate }}</span>
<hr class="half" />
<button class="btn btn-transparent" (click)="socialLogin('facebook')">
    {{ 'user.login.sign-in-facebook' | translate }}
</button>
<button class="btn btn-transparent" (click)="socialLogin('google')">
    {{ 'user.login.sign-in-google' | translate }}
</button>
<div class="login-no-account">
    <p>{{ 'user.login.no-account-yet' | translate }}</p>
    <p class="login-register" (click)="navigateTo('register')">
        {{ 'user.login.register' | translate }}
    </p>
</div>
