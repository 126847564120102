<div class="not-found-wrapper">
    <img
            src="/assets/img/404.svg"
            alt="404_icon"
    />
    <h3 class="page-not-found-title">{{ 'diliboard.not-found.title' | translate }}</h3>
    <p class="page-not-found-subtitle">{{ 'diliboard.not-found.sub-title' | translate }}</p>
    <button class="btn btn-primary not-found-button"
            [routerLink]="['/']">{{ 'diliboard.not-found.go-home' | translate }}</button>
</div>
