import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Diliboard } from 'src/app/models/diliboard';
import { Store } from '@ngrx/store';
import { EditBoard } from '../../../store/diliboard.actions';

@Component({
    selector: 'app-edit-board',
    templateUrl: './edit-board.component.html'
})
export class EditBoardComponent implements OnInit {
    boardForm: FormGroup;
    board: Diliboard;

    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        private boardBuilder: FormBuilder,
        private dialog: MatDialog,
        private store: Store<any>
    ) {
        this.board = data.board;
    }

    ngOnInit() {
        this.setForm();
    }

    closeDialog(): void {
        this.dialog.closeAll();
    }

    saveDiligram(): void {
        this.store.dispatch(
            new EditBoard({
                board: this.boardForm.value,
                boardId: this.board.id
            })
        );
    }

    private setForm(): void {
        this.boardForm = this.boardBuilder.group({
            title: [
                this.board ? this.board.title : '',
                [Validators.required, Validators.maxLength(90)]
            ],
            color: ['#000000'],
            description: ['no_description'],
            public: [false]
        });
    }
}
