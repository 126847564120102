import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { CookiesService } from 'src/app/services/cookie.service';
import { Observable, BehaviorSubject, Subject } from 'rxjs';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
    isMobile: boolean = true;
    showCookieBanner$: Observable<boolean>;
    windowSize: number;
    show$: BehaviorSubject<string> = new BehaviorSubject<string>('');

    constructor(
        private router: Router,
        private cookiesService: CookiesService
    ) {
        router.events.forEach((event) => {
            if (event instanceof NavigationStart) {
                this.setShownParams(event.url);
            }
        });
    }

    ngOnInit(): void {
        this.showCookieBanner$ = this.cookiesService.showCookieBanner;
        if (typeof window !== 'undefined') {
            this.windowSize = window.innerWidth;
            this.setShownParams(this.router.url, 'init');
            if (window.innerWidth > 950) {
                this.isMobile = false;
            }
        }

        document.getElementsByTagName('body')[0].style.backgroundColor =
            '#FFFFFF';
    }

    ngOnDestroy() {
        document.getElementsByTagName('body')[0].style.backgroundColor =
            '#eff2f6';
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        if (this.windowSize !== window.innerWidth) {
            this.windowSize = window.innerWidth;
            if (window.innerWidth > 950) {
                this.isMobile = false;
                if (this.router.url.includes('login')) {
                    this.show$.next('');
                }
            } else {
                this.isMobile = true;
            }
        }
    }

    navigateTo(link: string): void {
        this.router.navigate([link]);
    }

    setShow(params: string): void {
        this.show$.next(params);
    }

    private setShownParams(url: string, params?: string): void {
        if (url.includes('login')) {
            this.show$.next('login');
        } else if (url.includes('register')) {
            this.show$.next('register');
        } else if (url.includes('forgot-password')) {
            this.show$.next('forgot-password');
        } else if (url.includes('register')) {
            this.show$.next('register');
        } else if (url.includes('reset-password')) {
            this.show$.next('reset-password');
        }

        if (
            window.innerWidth < 950 &&
            url.includes('login') &&
            params === 'init'
        ) {
            this.show$.next('');
        }
    }
}
