// https://stackoverflow.com/questions/47824191/use-ngx-translate-with-ng2-select-in-angular-5-app?rq=1
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Pipe({
    name: 'translateOptions'
})
export class TranslateOptionsPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {
    }

    transform(items: any) {
        const itemsCopy = JSON.parse(JSON.stringify(items));
        const observable = new Observable(observer => {
            const keys = itemsCopy.map(item => item.label);
            this.translateService.get(keys).subscribe(result => {
                // tslint:disable-next-line:forin
                for (const item in itemsCopy) {
                    const key = itemsCopy[item].label;
                    itemsCopy[item]['translation'] = result[key];
                }
                observer.next(itemsCopy);
            });
        });

        return observable;
    }
}
