<app-cookie-banner
    *ngIf="showCookieBanner$ | async"
    class="cookie-banner"
></app-cookie-banner>
<app-header
    *ngIf="isLoggedIn$ | async"
    class="header-component"
    [ngClass]="{ 'cookie-shown': showCookieBanner$ | async }"
></app-header>

<div
    class="home-component"
    [ngClass]="{
        white: !(isLoggedIn$ | async),
        'un-logged-imprint': checkIfPageIsImprint() && !(isLoggedIn$ | async),
        'cookie-shown': showCookieBanner$ | async
    }"
>
    <router-outlet></router-outlet>
</div>

<app-footer *ngIf="isLoggedIn$ | async"></app-footer>
