import { Action, ActionReducer, MetaReducer } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import * as Sentry from '@sentry/browser';
import { storeReset } from 'ngrx-store-reset';
import { UserActionType } from './modules/user/store/user.actions';

export function debugMetaReducer(
    reducer: ActionReducer<object>
): ActionReducer<object> {
    return function(oldState: object, action: Action): object {
        const newState = reducer(oldState, action);

        Sentry.addBreadcrumb({
            category: 'storeAction',
            message: action.type,
            level: Sentry.Severity.Info,
            type: 'default'
        });

        if (!environment.production) {
            console.groupCollapsed(
                `%c NgRx store update by '${action.type}'`,
                'color: #e2001a'
            );
            console.log('Old state: ', oldState);
            console.log('Action: ', action);
            console.log('New state: ', newState);
            console.groupEnd();
        }

        return newState;
    };
}

export function clearState(
    reducer: ActionReducer<object>
): ActionReducer<object> {
    return storeReset({ action: UserActionType.LOGOUT })(reducer);
}

export const metaReducers: MetaReducer<object>[] = [
    debugMetaReducer,
    clearState
];
