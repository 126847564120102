import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { Subscription, Observable, combineLatest } from 'rxjs';
import { Diliboard } from 'src/app/models/diliboard';
import { OverlayComponent } from './overlay/overlay.component';
import { Store, select } from '@ngrx/store';
import {
    GetAllBoardContent,
    LoadMoreDiliboard,
    SetSortingDiliboard,
    ResetDiliboardsAndContent,
    GetBoardDetails,
    AcceptInvite
} from '../../store/diliboard.actions';
import {
    selectMyClassroomDashboard,
    selectLoaderMyClassroomDashboard,
    selectMyClassroomCount,
    selectPageOptionsMyClassroomDashboard,
    selectBoardDetails
} from '../../store/diliboard.selector';
import { PageOptions } from '../../store/diliboard.state';
import { selectIsLoggedIn } from 'src/app/modules/user/store/user.selector';

const featureName: string = 'board';

@Component({
    selector: 'app-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    videos$: Observable<Diliboard[]>;
    board$: Observable<Diliboard>;
    boardId: number;
    boards: Diliboard[];
    private subscription: Subscription = new Subscription();
    private isLoggedIn: boolean;

    loader$: Observable<boolean>;
    videosCount$: Observable<number>;

    constructor(
        route: ActivatedRoute,
        private dialog: MatDialog,
        private router: Router,
        private store: Store<any>
    ) {
        this.subscription.add(
            this.store.pipe(select(selectIsLoggedIn)).subscribe((loggedIn) => {
                this.isLoggedIn = loggedIn;
            })
        );

        this.subscriptions.push(
            route.params.subscribe((params) => {
                this.boardId = +params.boardId;
                this.store.dispatch(new ResetDiliboardsAndContent());
                this.store.dispatch(
                    new GetBoardDetails({ boardId: this.boardId })
                );

                const pageOptions$: Observable<PageOptions> = this.store.pipe(
                    select(selectPageOptionsMyClassroomDashboard, {
                        featureName
                    })
                );

                const subscription = combineLatest(pageOptions$).subscribe(
                    ([pageOptions]: [PageOptions]) => {
                        this.store.dispatch(
                            new GetAllBoardContent({ boardId: this.boardId })
                        );
                    }
                );
                this.subscription.add(subscription);
            })
        );
    }

    ngOnInit() {
        let inviteToken = '';
        let boardId = null;
        const url = window.location.href.split('?invite_token=');
        inviteToken = url[1];
        boardId = url[0].split('/board/')[1];

        if (!!inviteToken) {
            if (this.isLoggedIn) {
                this.acceptInvite(inviteToken);
            }
        }
        this.videos$ = this.store.pipe(
            select(selectMyClassroomDashboard, {
                featureName
            })
        );
        this.loader$ = this.store.pipe(
            select(selectLoaderMyClassroomDashboard, {
                featureName
            })
        );
        this.videosCount$ = this.store.pipe(
            select(selectMyClassroomCount, {
                featureName
            })
        );

        this.board$ = this.store.pipe(select(selectBoardDetails));
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
        this.subscription.unsubscribe();
    }

    loadMore(): void {
        this.store.dispatch(new LoadMoreDiliboard({ featureName }));
    }

    setSorting(event: string): void {
        this.store.dispatch(
            new SetSortingDiliboard({ value: event, featureName })
        );
    }

    openOverlay(video: Diliboard): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'overlay-dialog ';
        dialogConfig.data = {
            video
        };
        this.dialog.open(OverlayComponent, dialogConfig);
    }

    private acceptInvite(inviteToken: string): void {
        const body = {
            invite_token: inviteToken
        };
        this.store.dispatch(new AcceptInvite({ body, boardId: this.boardId }));
    }
}
