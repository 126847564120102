import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
    AccountComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    LoginComponent,
    RegisterComponent
} from 'src/app/modules/user/components';
import { AuthGuard } from 'src/app/services';
import {
    MyClassroomsComponent,
    ContentComponent
} from 'src/app/modules/diliboard/components';
import { NotFoundComponent } from 'src/app/components/not-found/not-found.component';
import { ImprintComponent } from 'src/app/components/imprint/imprint.component';
import { HomeComponent } from 'src/app/components/home/home.component';
import { SearchResultComponent } from './modules/diliboard/components/search-result/search-result.component';
import { TermAndConditionsComponent } from './components/term-and-conditions/terms-and-conditions.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'my-diligrams',
        pathMatch: 'full'
    },
    {
        path: 'home',
        component: HomeComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'login'
            },
            {
                path: 'login',
                component: LoginComponent
            },
            {
                path: 'register',
                component: RegisterComponent
            },
            {
                path: 'forgot-password',
                component: ForgotPasswordComponent
            },
            {
                path: 'reset-password',
                component: ResetPasswordComponent
            }
        ]
    },
    {
        path: 'not-found',
        component: NotFoundComponent
    },
    {
        path: 'imprint',
        component: ImprintComponent
    },
    {
        path: 'terms',
        component: TermAndConditionsComponent
    },
    // this is temp, when logged user refresh page on ImprintComponent and TermAndConditionsComponent, header and footer are missing
    {
        path: 'imprint-diligram',
        component: ImprintComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'privacy-policy',
        component: TermAndConditionsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'search',
        component: SearchResultComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'account',
        component: AccountComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'my-diligrams',
        component: MyClassroomsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'board/:boardId',
        component: ContentComponent,
        canActivate: [AuthGuard]
    },
    {
        path: '**',
        redirectTo: 'not-found'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: false,
            scrollPositionRestoration: 'enabled',
            initialNavigation: 'enabled'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
