import { Component, OnDestroy, Input, OnInit } from '@angular/core';
import { Subscription, Observable, pipe } from 'rxjs';
import { Comments, Diliboard } from 'src/app/models/diliboard';
import { UserData } from 'src/app/models/user';
import { Store, select } from '@ngrx/store';
import { selectUser } from 'src/app/modules/user/store/user.selector';
import { GetBoardComments, PostComment } from '../../store/diliboard.actions';
import {
    selectBoardComments,
    selectLoaderBoardComments,
    selectCommentPostingLoader,
    selectSuccessfullyCommentLoader
} from '../../store/diliboard.selector';

@Component({
    selector: 'app-comments',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.css']
})
export class CommentsComponent implements OnInit, OnDestroy {
    @Input()
    set boardData(data: Diliboard) {
        if (data) {
            this.board = data;
            this.getData();
        }
    }
    comments$: Observable<Comments[]>;
    commentsLoader$: Observable<boolean>;
    commentPostingLoader$: Observable<boolean>;
    comment: string;
    board: Diliboard;
    user: UserData;
    private subscription: Subscription = new Subscription();

    constructor(private store: Store<any>) {}

    ngOnInit() {
        this.subscription.add(
            this.store.pipe(select(selectUser)).subscribe((user) => {
                this.user = user;
            })
        );

        this.subscription.add(
            this.store
                .select(selectSuccessfullyCommentLoader)
                .subscribe((res) => {
                    if (res) {
                        this.comment = null;
                    }
                })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    saveComment(): void {
        if (this.comment) {
            this.store.dispatch(
                new PostComment({
                    comment: this.comment,
                    boardId: this.board.id,
                    userId: this.user.id
                })
            );
        }
    }

    private getData(): void {
        this.store.dispatch(new GetBoardComments({ boardId: this.board.id }));
        this.comments$ = this.store.select(pipe(selectBoardComments));
        this.commentsLoader$ = this.store.select(
            pipe(selectLoaderBoardComments)
        );
        this.commentPostingLoader$ = this.store.select(
            pipe(selectCommentPostingLoader)
        );
    }
}
