import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, pipe, Observable } from 'rxjs';
import { ConfirmationPopUpComponent } from 'src/app/modules/shared-stuff/components/confirmation-pop-up/confirmation-pop-up.component';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../user/user.service';
import { Store, ActionsSubject, Action } from '@ngrx/store';
import {
    GetBoardUsers,
    ChangeFriendRole,
    ChangeInviteRole,
    InviteUser,
    DiliboardActionType,
    RemoveUser,
    RemoveInvitedUser
} from '../../diliboard/store/diliboard.actions';
import {
    selectBoardUsers,
    selectLoaderBoardUsers
} from '../../diliboard/store/diliboard.selector';
import { DiliboardCapabilities } from 'src/app/models/diliboard';

@Component({
    selector: 'app-add-remove-friend',
    templateUrl: './add-remove-friend.component.html',
    styleUrls: ['./add-remove-friend.component.css']
})
export class AddRemoveFriendComponent implements OnInit, OnDestroy {
    friendList$: Observable<Array<any>>;
    loader$: Observable<boolean>;
    addForm: FormGroup;
    showWhatIs: boolean = false;
    boardId: number;
    capabilities: DiliboardCapabilities;
    private subscription: Subscription = new Subscription();

    roles: string[] = ['viewer', 'collaborator', 'contributor'];

    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        private dialog: MatDialog,
        private formBuilder: FormBuilder,
        private translate: TranslateService,
        private store: Store<any>,
        private userService: UserService,
        private actions: ActionsSubject
    ) {
        this.boardId = data.boardId;
        this.capabilities = data.capabilities;
    }

    ngOnInit() {
        this.setForm();
        this.friendList$ = this.store.select(pipe(selectBoardUsers));
        this.loader$ = this.store.select(pipe(selectLoaderBoardUsers));

        this.subscription = this.actions.subscribe((action: Action) => {
            if (action.type === DiliboardActionType.SUCCESSFULLY_INVITE_USER) {
                this.setForm();
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    addFriend(): void {
        const email = this.addForm.value.email;
        if (email.includes(',')) {
            const emails = email.replace(/\s/g, '').split(',');

            this.addForm.value.emails = emails;
            this.addForm.value.email = null;
        }

        this.store.dispatch(
            new InviteUser({
                body: this.addForm.value,
                diliboardId: this.boardId
            })
        );
    }

    closeDialog(): void {
        this.dialog.closeAll();
    }

    deleteFriend(
        diliboardId: number,
        friendId: number,
        firstName: string,
        lastName: string,
        status?: string,
        email?: string
    ) {
        let message: string;

        let subscription = this.translate
            .get('add-remove-friend.confirmation-pop-up.remove', {
                name: !status ? `${firstName} ${lastName}` : email
            })
            .subscribe((res) => (message = res));

        this.subscription.add(subscription);

        subscription = this.dialog
            .open(ConfirmationPopUpComponent, {
                width: '350px',
                data: {
                    message
                }
            })
            .afterClosed()
            .subscribe((res: boolean) => {
                if (res) {
                    this.delete(diliboardId, friendId, status);
                }
            });

        this.subscription.add(subscription);
    }

    changeFriendRole(
        diliboardId: number,
        user_id: number,
        role: string,
        status?: string,
        inviteId?: number
    ): void {
        if (!status) {
            this.store.dispatch(
                new ChangeFriendRole({ diliboardId, user_id, role })
            );
        } else {
            this.store.dispatch(
                new ChangeInviteRole({ diliboardId, inviteId, role })
            );
        }
    }

    showHideWhatIs(): void {
        this.showWhatIs = !this.showWhatIs;
    }

    private setForm() {
        this.addForm = this.formBuilder.group({
            email: [
                null,
                [
                    Validators.required,
                    Validators.pattern(
                        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
                    )
                ]
            ],
            role: ['viewer', [Validators.required]],
            diliboard_id: [this.boardId]
        });
    }

    private delete(
        diliboardId: number,
        friendId: number,
        status: string
    ): void {
        if (!status) {
            this.store.dispatch(new RemoveUser({ diliboardId, friendId }));
        } else {
            this.store.dispatch(
                new RemoveInvitedUser({ diliboardId, inviteId: friendId })
            );
        }
    }
}
