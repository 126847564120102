import { UserData, Register } from 'src/app/models/user';

export const userStateName: string = 'user';

export const initialUserDataState: UserData = {
    authentication_token: null,
    confirmed_at: null,
    created_at: null,
    email: null,
    first_name: null,
    id: null,
    image_url_medium: null,
    image_url_original: null,
    image_url_thumb: null,
    last_name: null,
    role: null,
    sign_in_count: null,
    step: null,
    image: null,
    provider: null,
    followers_count: null,
    profile_text: null,
    diliboards_count: null,
    own_diliboards_count: null
};

export type UserState = {
    userData: UserData;
    loggedIn: boolean;
    socialLogin: boolean;
    rememberLogin: boolean;
    registerData: Register;
};

export const initialUserState: UserState = {
    userData: initialUserDataState,
    loggedIn: false,
    socialLogin: false,
    rememberLogin: false,
    registerData: null
};
