import { Diliboard, Comments } from 'src/app/models/diliboard';
import { SortOrder } from 'src/app/util/sort.enum';
import {
    FriendListInterface,
    UserInviteInterface
} from 'src/app/models/friend-list.model';
import { AddNewContentSessionModel } from '../models/wizard-session.model';

export const diliboardStateName: string = 'diliboard';

export type PageOptions = {
    offset: number;
    limit: number;
    sort: string;
};

export const initialSortOrder: PageOptions = {
    offset: 0,
    limit: 12,
    sort: SortOrder.title_ASC
};

export type DiliboardContentState = {
    data: Diliboard[];
    dataLoader: boolean;
    dataRequest: PageOptions;
    dataCount: number;
};

export const initialDiliboardContentState: DiliboardContentState = {
    data: [],
    dataLoader: false,
    dataRequest: initialSortOrder,
    dataCount: 0
};

export type BoardState = {
    users: FriendListInterface[];
    usersLoader: boolean;
    boardId: number;
    comments: Comments[];
    commentsLoader: boolean;
    commentPostingLoader: boolean;
    successfullyCommentLoader: boolean;
    boardDetails: Diliboard;
    boardDetailsLoader: boolean;
};

export const initialBoardState: BoardState = {
    users: [],
    usersLoader: false,
    boardId: null,
    comments: [],
    commentsLoader: false,
    commentPostingLoader: false,
    successfullyCommentLoader: false,
    boardDetails: null,
    boardDetailsLoader: false
};

export type DiliboardSearch = {
    data: Diliboard[];
    loader: boolean;
    term: string;
    dataRequest: PageOptions;
    dataCount: number;
};

export const initialDiliboardSearch: DiliboardSearch = {
    data: [],
    loader: false,
    term: null,
    dataRequest: initialSortOrder,
    dataCount: 0
};

export type DiliboardState = {
    myClassroom: DiliboardContentState;
    board: DiliboardContentState & BoardState;
    searchBoard: DiliboardSearch;
    searchBar: DiliboardSearch;
    newContentSession: {
        data: AddNewContentSessionModel;
        loader: boolean;
        error: string;
        errorStep: number;
    };
};

export const initialDiliboardState: DiliboardState = {
    myClassroom: initialDiliboardContentState,
    board: { ...initialDiliboardContentState, ...initialBoardState },
    searchBoard: initialDiliboardSearch,
    searchBar: initialDiliboardSearch,
    newContentSession: {
        data: null,
        loader: false,
        error: null,
        errorStep: null
    }
};
