<app-account-info
    [board]="board$ | async"
    *ngIf="board$ | async"
></app-account-info>
<div
    class="container"
    [ngClass]="{ 'transparent-wrapper': (videos$ | async).length === 0 }"
>
    <app-diliboard-videos
        [videos]="videos$ | async"
        [board]="board$ | async"
        (emitSorting$)="setSorting($event)"
    ></app-diliboard-videos>
    <button
        class="load-more"
        *ngIf="
            !(loader$ | async) &&
            (videos$ | async).length > 0 &&
            (videos$ | async).length < (videosCount$ | async)
        "
        (click)="loadMore()"
    >
        {{ 'actions.load-more' | translate }}
    </button>
</div>
<app-data-loader *ngIf="loader$ | async"></app-data-loader>
<app-comments [boardData]="board$ | async"></app-comments>
