<div class="upload-image">
    <input
        type="file"
        accept="image/*"
        (change)="changeListener($event)"
        *ngIf="!image"
        class="image-upload"
    />
    <img [src]="image" alt="user-image" *ngIf="image" />
</div>

<button class="btn btn-primary" (click)="saveImage()">
    {{ 'user.photo.save' | translate }}
</button>
<button class="btn btn-transparent" (click)="closeDialog()">
    {{ 'user.photo.cancel' | translate }}
</button>
