import {
    createFeatureSelector,
    createSelector,
    Selector,
    SelectorWithProps
} from '@ngrx/store';
import {
    diliboardStateName,
    DiliboardState,
    PageOptions
} from './diliboard.state';
import { Diliboard, Comments } from 'src/app/models/diliboard';
import { FriendListInterface } from 'src/app/models/friend-list.model';
import { WizardSessionModel } from '../models/wizard-session.model';

const selectDiliboardSelector = createFeatureSelector<DiliboardState>(
    diliboardStateName
);

export const selectMyClassroomDashboard: SelectorWithProps<
    object,
    { featureName: string },
    Diliboard[]
> = createSelector(
    selectDiliboardSelector,
    (state: DiliboardState, { featureName }: { featureName: string }) =>
        state[featureName].data
);

export const selectLoaderMyClassroomDashboard: SelectorWithProps<
    object,
    { featureName: string },
    boolean
> = createSelector(
    selectDiliboardSelector,
    (state: DiliboardState, { featureName }: { featureName: string }) =>
        state[featureName].dataLoader
);

export const selectPageOptionsMyClassroomDashboard: SelectorWithProps<
    object,
    { featureName: string },
    PageOptions
> = createSelector(
    selectDiliboardSelector,
    (state: DiliboardState, { featureName }: { featureName: string }) =>
        state[featureName].dataRequest
);

export const selectMyClassroomCount: SelectorWithProps<
    object,
    { featureName: string },
    number
> = createSelector(
    selectDiliboardSelector,
    (state: DiliboardState, { featureName }: { featureName: string }) =>
        state[featureName].dataCount
);

export const selectSearchResult: SelectorWithProps<
    object,
    { featureName: string },
    Diliboard[]
> = createSelector(
    selectDiliboardSelector,
    (state: DiliboardState, { featureName }: { featureName: string }) =>
        state[featureName].data
);

export const selectSearchTerm: SelectorWithProps<
    object,
    { featureName: string },
    string
> = createSelector(
    selectDiliboardSelector,
    (state: DiliboardState, { featureName }: { featureName: string }) =>
        state[featureName].term
);

export const selectSearchResultLoader: SelectorWithProps<
    object,
    { featureName: string },
    boolean
> = createSelector(
    selectDiliboardSelector,
    (state: DiliboardState, { featureName }: { featureName: string }) =>
        state[featureName].loader
);

export const selectSearchResultCounter: SelectorWithProps<
    object,
    { featureName: string },
    number
> = createSelector(
    selectDiliboardSelector,
    (state: DiliboardState, { featureName }: { featureName: string }) =>
        state[featureName].dataCount
);

export const selectSearchResultPageOptions: SelectorWithProps<
    object,
    { featureName: string },
    PageOptions
> = createSelector(
    selectDiliboardSelector,
    (state: DiliboardState, { featureName }: { featureName: string }) =>
        state[featureName].dataRequest
);

export const selectBoardUsers: Selector<
    object,
    FriendListInterface[]
> = createSelector(
    selectDiliboardSelector,
    (state: DiliboardState) => state.board.users
);

export const selectLoaderBoardUsers: Selector<object, boolean> = createSelector(
    selectDiliboardSelector,
    (state: DiliboardState) => state.board.usersLoader
);

export const selectTotalCollaboratorsInBoard: Selector<
    object,
    number
> = createSelector(
    selectDiliboardSelector,
    (state: DiliboardState) =>
        state.board.users.filter(value => value.role === 'collaborator').length
);

export const selectTotalViewersInBoard: Selector<
    object,
    number
> = createSelector(
    selectDiliboardSelector,
    (state: DiliboardState) =>
        state.board.users.filter(value => value.role === 'viewer').length
);

export const selectBoardComments: Selector<object, Comments[]> = createSelector(
    selectDiliboardSelector,
    (state: DiliboardState) => {
        const comments: Comments[] = JSON.parse(
            JSON.stringify(state.board.comments)
        ).map(value => {
            value.updated = new Date(value.updated_at);
            value.updated_string = convertDateToTimePeriod(value.updated_at);

            return value;
        });

        return comments.sort(
            (a, b) => +new Date(b.updated) - +new Date(a.updated)
        );
    }
);

export const selectLoaderBoardComments: Selector<
    object,
    boolean
> = createSelector(
    selectDiliboardSelector,
    (state: DiliboardState) => state.board.usersLoader
);

export const selectCommentPostingLoader: Selector<
    object,
    boolean
> = createSelector(
    selectDiliboardSelector,
    (state: DiliboardState) => state.board.commentPostingLoader
);

export const selectSuccessfullyCommentLoader: Selector<
    object,
    boolean
> = createSelector(
    selectDiliboardSelector,
    (state: DiliboardState) => state.board.successfullyCommentLoader
);

export const selectBoardDetails: Selector<object, Diliboard> = createSelector(
    selectDiliboardSelector,
    (state: DiliboardState) => state.board.boardDetails
);

export const selectBoardDetailsLoader: Selector<
    object,
    boolean
> = createSelector(
    selectDiliboardSelector,
    (state: DiliboardState) => state.board.boardDetailsLoader
);

export const selectWizardData: Selector<
    object,
    WizardSessionModel
> = createSelector(selectDiliboardSelector, (state: DiliboardState) => {
    return state.newContentSession?.data?.wizard_session;
});

export const selectWizardDataLoader: Selector<object, boolean> = createSelector(
    selectDiliboardSelector,
    (state: DiliboardState) => state.newContentSession.loader
);

export const selectWizardID: Selector<object, number> = createSelector(
    selectWizardData,
    (state: WizardSessionModel) => state?.id
);

export const selectWizardDataErrorMessage: Selector<
    object,
    {
        error: string;
        errorStep: number;
    }
> = createSelector(selectDiliboardSelector, (state: DiliboardState) => {
    return {
        error: state.newContentSession.error,
        errorStep: state.newContentSession.errorStep
    };
});

function convertDateToTimePeriod(date: string): string {
    const today: Date = new Date();
    const commentDate: Date = new Date(date);
    const years: number = today.getFullYear() - commentDate.getFullYear();
    const months: number = today.getMonth() - commentDate.getMonth();
    const days: number = today.getDate() - commentDate.getDate();
    const hours: number = today.getHours() - commentDate.getHours();
    const minutes: number = today.getMinutes() - commentDate.getMinutes();
    if (years !== 0 && months === 0) {
        return `${years} year ago`;
    } else if (months !== 0) {
        const month = months > 0 ? months : 12 - Math.abs(months);
        if (month > 1) {
            return `${month} months ago`;
        } else {
            return `${month} months ago`;
        }
    } else if (days !== 0) {
        if (days > 1) {
            return `${days} days ago`;
        } else {
            return `${days} days ago`;
        }
    } else if (hours !== 0) {
        return `${hours}h ago`;
    } else if (minutes !== 0) {
        return `${minutes}min ago`;
    } else {
        return `a few seconds ago`;
    }
}
