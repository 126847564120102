import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-confirmation-pop-up',
    template: `
        <div class="confirmation-wrapper">
            <img
                src="/assets/img/icons/cancel_icon.svg"
                alt="cancel_icon"
                class="close-icon"
                (click)="closeDialog()"
            />
            <h1>{{ message }}</h1>
            <div class="button-wrapper">
                <button
                    class="btn btn-primary center-btn"
                    (click)="execute('yes')"
                >
                    {{ 'actions.yes' | translate }}
                </button>
            </div>
        </div>
    `,
    styles: [
        `
            div.confirmation-wrapper {
                position: relative;
            }
            img.close-icon {
                position: absolute;
                right: 0;
                top: 0;
                cursor: pointer;
            }
            h1 {
                color: #5a6b88;
                font-size: 18px;
                line-height: 25px;
                text-align: center;
                padding-top: 18px;
            }
            .button-wrapper {
                display: flex;
                justify-content: flex-end;
                margin-top: 30px;
            }
            button.btn {
                width: 100px;
                height: 30px;
            }
        `
    ]
})
export class ConfirmationPopUpComponent {
    message: string;

    constructor(
        public dialogRef: MatDialogRef<
            ConfirmationPopUpComponent,
            { params: string }
        >,
        @Inject(MAT_DIALOG_DATA) data: { message: string }
    ) {
        this.message = data.message;
    }

    execute(params: string): void {
        this.dialogRef.close({ params });
    }

    closeDialog(): void {
        this.dialogRef.close();
    }
}
