import { Injectable } from '@angular/core';
import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    NavigationCancel
} from '@angular/router';
import { CookiesService } from './cookie.service';
import { Store } from '@ngrx/store';
import { UserGetToken } from '../modules/user/store/user.actions';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private cookieService: CookiesService,
        private store: Store<any>
    ) {
        router.events.forEach((event) => {
            if (event instanceof NavigationCancel) {
                if (event.url.includes('invite_token')) {
                    sessionStorage.setItem('invitation', event.url);
                }
            }
        });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.cookieService.getCookie('token')) {
            this.store.dispatch(new UserGetToken());
            return true;
        }

        this.router.navigate(['/home']);
        return false;
    }
}
