import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogConfig
} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { DataChangeService, DataService } from 'src/app/services';
import { Diliboard } from 'src/app/models/diliboard';
import { CreateBoardComponent } from 'src/app/modules/diliboard/components';
import { Store, select } from '@ngrx/store';
import { selectIsLoggedIn } from 'src/app/modules/user/store/user.selector';

@Component({
    selector: 'app-pin-dialog',
    templateUrl: './pin-dialog.component.html',
    styleUrls: ['./pin-dialog.component.css']
})
export class PinDialogComponent implements OnInit, OnDestroy {
    boards: Diliboard[];
    boardId: number;
    video: Diliboard;
    videoLink: string;
    showOption: boolean = false;
    selectedBoard: Diliboard;
    mobile: boolean = false;
    private subscription: Subscription = new Subscription();
    private isLoggedIn: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data,
        private dialog: MatDialog,
        private dataService: DataService,
        private router: Router,
        private dataChangeService: DataChangeService,
        private store: Store<any>
    ) {}

    ngOnInit() {
        this.subscription.add(
            this.store.pipe(select(selectIsLoggedIn)).subscribe((loggedIn) => {
                this.isLoggedIn = loggedIn;
            })
        );
        if (typeof window !== 'undefined') {
            this.mobile = window.innerWidth < 767;
        }

        this.video = this.data.video;
        this.videoLink = this.data.video.original_content_url;
        this.getData();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    getData(): void {
        const query = '?offset=0&limit=150';

        if (!!this.isLoggedIn) {
            const subscription = this.dataService
                .getAllDiliboards(query)
                .subscribe((response) => {
                    this.boards = response;
                });
            this.subscription.add(subscription);
        }
    }

    onChange(boardId: number): void {
        this.boardId = boardId;
    }

    saveData(): void {
        const body = {
            content_type: this.video.content_type,
            source: this.video.source,
            thumbnail: this.video.thumbnail,
            title: this.video.title,
            original_content_url: this.video.original_content_url
        };

        if (!!this.isLoggedIn) {
            const subscription = this.dataService
                .createDiliboardContent(body, this.selectedBoard.id)
                .subscribe((response) => {
                    this.dataChangeService.setChange('video');
                    this.router.navigate(['/board', this.selectedBoard.id]);
                    this.closeDialog();
                });
            this.subscription.add(subscription);
        }
    }

    createDili(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'standard-dialog';
        dialogConfig.data = { calledFrom: 'pin' };

        const subscription = this.dialog
            .open(CreateBoardComponent, dialogConfig)
            .afterClosed()
            .subscribe((response) => {
                if (!!response) {
                    const createdBoard = this.isLoggedIn
                        ? response.diliboard
                        : response;
                    this.boards.unshift(createdBoard);
                    this.setOption(createdBoard);
                }
            });

        this.subscription.add(subscription);
    }

    closeDialog(): void {
        this.dialog.closeAll();
    }

    // custom selector part

    toggleOptions(): void {
        this.showOption = !this.showOption;
    }

    setOption(option): void {
        this.selectedBoard = option;
        this.toggleOptions();
    }
}
