<div class="wrapper">
    <div class="container">
        <div class="row">
            <div class="col-12 header" *ngIf="!(isLoggedIn$ | async)">
                <img
                    class="home-logo"
                    src="/assets/img/diligram-logo.svg"
                    alt="diligram-logo"
                    (click)="navigateTo('home')"
                />
                <app-language></app-language>
            </div>
            <div class="col-12 col-sm-12 terms" *ngIf="language === 'en'">
                <h1>Privacy Policy</h1>

                <p>Last updated: April 01, 2020</p>

                <p>
                    This Privacy Policy describes Our policies and procedures on
                    the collection, use and disclosure of Your information when
                    You use the Service and tells You about Your privacy rights
                    and how the law protects You.
                </p>

                <p>
                    We use Your Personal data to provide and improve the
                    Service. By using the Service, You agree to the collection
                    and use of information in accordance with this Privacy
                    Policy.
                </p>

                <h1>Interpretation and Definitions</h1>
                <h2>Interpretation</h2>
                <p>
                    The words of which the initial letter is capitalized have
                    meanings defined under the following conditions.
                </p>
                <p>
                    The following definitions shall have the same meaning
                    regardless of whether they appear in singular or in plural.
                </p>

                <h2>Definitions</h2>
                <p>For the purposes of this Privacy Policy:</p>
                <ul>
                    <li>
                        <p>
                            <strong>You</strong> means the individual accessing
                            or using the Service, or the company, or other legal
                            entity on behalf of which such individual is
                            accessing or using the Service, as applicable.
                        </p>
                        <p>
                            Under GDPR (General Data Protection Regulation), You
                            can be referred to as the Data Subject or as the
                            User as you are the individual using the Service.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Company</strong> (referred to as either "the
                            Company", "We", "Us" or "Our" in this Agreement)
                            refers to Diligram AG, Talstrasse 23, 6403 Küssnacht
                            SZ.
                        </p>
                        <p>
                            For the purpose of the GDPR, the Company is the Data
                            Controller.
                        </p>
                    </li>
                    <li>
                        <strong>Affiliate</strong> means an entity that
                        controls, is controlled by or is under common control
                        with a party, where "control" means ownership of 50% or
                        more of the shares, equity interest or other securities
                        entitled to vote for election of directors or other
                        managing authority.
                    </li>
                    <li>
                        <strong>Account</strong> means a unique account created
                        for You to access our Service or parts of our Service.
                    </li>
                    <li>
                        <strong>Website</strong> refers to Diligram Edu,
                        accessible from https://edu.diligram.com
                    </li>
                    <li><strong>Service</strong> refers to the Website.</li>
                    <li><strong>Country</strong> refers to: Switzerland</li>
                    <li>
                        <p>
                            <strong>Service Provider</strong> means any natural
                            or legal person who processes the data on behalf of
                            the Company. It refers to third-party companies or
                            individuals employed by the Company to facilitate
                            the Service, to provide the Service on behalf of the
                            Company, to perform services related to the Service
                            or to assist the Company in analyzing how the
                            Service is used.
                        </p>
                        <p>
                            For the purpose of the GDPR, Service Providers are
                            considered Data Processors.
                        </p>
                    </li>
                    <li>
                        <strong>Third-party Social Media Service</strong> refers
                        to any website or any social network website through
                        which a User can log in or create an account to use the
                        Service.
                    </li>
                    <li>
                        <strong>Facebook Fan Page</strong> is a public profile
                        named Diligram specifically created by the Company on
                        the Facebook social network, accessible from
                        https://www.facebook.com/Diligram-111419553828584/
                    </li>
                    <li>
                        <p>
                            <strong>Personal Data</strong> is any information
                            that relates to an identified or identifiable
                            individual.
                        </p>
                        <p>
                            For the purposes for GDPR, Personal Data means any
                            information relating to You such as a name, an
                            identification number, location data, online
                            identifier or to one or more factors specific to the
                            physical, physiological, genetic, mental, economic,
                            cultural or social identity.
                        </p>
                    </li>
                    <li>
                        <strong>Cookies</strong> are small files that are placed
                        on Your computer, mobile device or any other device by a
                        website, containing the details of Your browsing history
                        on that website among its many uses.
                    </li>
                    <li>
                        <strong>Usage Data</strong> refers to data collected
                        automatically, either generated by the use of the
                        Service or from the Service infrastructure itself (for
                        example, the duration of a page visit).
                    </li>
                    <li>
                        <strong>Data Controller</strong>, for the purposes of
                        the GDPR (General Data Protection Regulation), refers to
                        the Company as the legal person which alone or jointly
                        with others determines the purposes and means of the
                        processing of Personal Data.
                    </li>
                </ul>

                <h1>Collecting and Using Your Personal Data</h1>
                <h2>Types of Data Collected</h2>

                <h3>Personal Data</h3>
                <p>
                    While using Our Service, We may ask You to provide Us with
                    certain personally identifiable information that can be used
                    to contact or identify You. Personally identifiable
                    information may include, but is not limited to:
                </p>
                <ul>
                    <li>Email address</li>
                    <li>First name and last name</li>
                    <li>Phone number</li>
                    <li>Address, State, Province, ZIP/Postal code, City</li>
                    <li>Usage Data</li>
                </ul>

                <h3>Usage Data</h3>
                <p>
                    Usage Data is collected automatically when using the
                    Service.
                </p>
                <p>
                    Usage Data may include information such as Your Device's
                    Internet Protocol address (e.g. IP address), browser type,
                    browser version, the pages of our Service that You visit,
                    the time and date of Your visit, the time spent on those
                    pages, unique device identifiers and other diagnostic data.
                </p>
                <p>
                    When You access the Service by or through a mobile device,
                    We may collect certain information automatically, including,
                    but not limited to, the type of mobile device You use, Your
                    mobile device unique ID, the IP address of Your mobile
                    device, Your mobile operating system, the type of mobile
                    Internet browser You use, unique device identifiers and
                    other diagnostic data.
                </p>
                <p>
                    We may also collect information that Your browser sends
                    whenever You visit our Service or when You access the
                    Service by or through a mobile device.
                </p>

                <h3>Information from Third-Party Social Media Services</h3>
                <p>
                    The Company allows You to create an account and log in to
                    use the Service through the following Third-party Social
                    Media Services:
                </p>
                <ul>
                    <li>Google</li>
                    <li>Facebook</li>
                    <li>Twitter</li>
                </ul>
                <p>
                    If You decide to register through or otherwise grant us
                    access to a Third-Party Social Media Service, We may collect
                    Personal data that is already associated with Your
                    Third-Party Social Media Service's account, such as Your
                    name, Your email address, Your activities or Your contact
                    list associated with that account.
                </p>
                <p>
                    You may also have the option of sharing additional
                    information with the Company through Your Third-Party Social
                    Media Service's account. If You choose to provide such
                    information and Personal Data, during registration or
                    otherwise, You are giving the Company permission to use,
                    share, and store it in a manner consistent with this Privacy
                    Policy.
                </p>

                <h3>Tracking Technologies and Cookies</h3>
                <p>
                    We use Cookies and similar tracking technologies to track
                    the activity on Our Service and store certain information.
                    Tracking technologies used are beacons, tags, and scripts to
                    collect and track information and to improve and analyze Our
                    Service.
                </p>
                <p>
                    You can instruct Your browser to refuse all Cookies or to
                    indicate when a Cookie is being sent. However, if You do not
                    accept Cookies, You may not be able to use some parts of our
                    Service.
                </p>
                <p>
                    Cookies can be "Persistent" or "Session" Cookies. Persistent
                    Cookies remain on your personal computer or mobile device
                    when You go offline, while Session Cookies are deleted as
                    soon as You close your web browser. Learn more about cookies
                    here:
                    <a href="https://www.freeprivacypolicy.com/blog/cookies/"
                        >Cookies: What Do They Do?</a
                    >
                </p>
                <p>
                    We use both session and persistent Cookies for the purposes
                    set out below:
                </p>
                <ul>
                    <li>
                        <p><strong>Necessary / Essential Cookies</strong></p>
                        <p>Type: Session Cookies</p>
                        <p>Administered by: Us</p>
                        <p>
                            Purpose: These Cookies are essential to provide You
                            with services available through the Website and to
                            enable You to use some of its features. They help to
                            authenticate users and prevent fraudulent use of
                            user accounts. Without these Cookies, the services
                            that You have asked for cannot be provided, and We
                            only use these Cookies to provide You with those
                            services.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong
                                >Cookies Policy / Notice Acceptance
                                Cookies</strong
                            >
                        </p>
                        <p>Type: Persistent Cookies</p>
                        <p>Administered by: Us</p>
                        <p>
                            Purpose: These Cookies identify if users have
                            accepted the use of cookies on the Website.
                        </p>
                    </li>
                    <li>
                        <p><strong>Functionality Cookies</strong></p>
                        <p>Type: Persistent Cookies</p>
                        <p>Administered by: Us</p>
                        <p>
                            Purpose: These Cookies allow us to remember choices
                            You make when You use the Website, such as
                            remembering your login details or language
                            preference. The purpose of these Cookies is to
                            provide You with a more personal experience and to
                            avoid You having to re-enter your preferences every
                            time You use the Website.
                        </p>
                    </li>
                    <li>
                        <p><strong>Tracking and Performance Cookies</strong></p>
                        <p>Type: Persistent Cookies</p>
                        <p>Administered by: Third-Parties</p>
                        <p>
                            Purpose: These Cookies are used to track information
                            about traffic to the Website and how users use the
                            Website. The information gathered via these Cookies
                            may directly or indirectly identify you as an
                            individual visitor. This is because the information
                            collected is typically linked to a pseudonymous
                            identifier associated with the device you use to
                            access the Website. We may also use these Cookies to
                            test new advertisements, pages, features or new
                            functionality of the Website to see how our users
                            react to them.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Targeting and Advertising Cookies</strong>
                        </p>
                        <p>Type: Persistent Cookies</p>
                        <p>Administered by: Third-Parties</p>
                        <p>
                            Purpose: These Cookies track your browsing habits to
                            enable Us to show advertising which is more likely
                            to be of interest to You. These Cookies use
                            information about your browsing history to group You
                            with other users who have similar interests. Based
                            on that information, and with Our permission, third
                            party advertisers can place Cookies to enable them
                            to show adverts which We think will be relevant to
                            your interests while You are on third party
                            websites.
                        </p>
                    </li>
                </ul>
                <p>
                    For more information about the cookies we use and your
                    choices regarding cookies, please visit our Cookies Policy.
                </p>

                <h2>Use of Your Personal Data</h2>
                <p>
                    The Company may use Personal Data for the following
                    purposes:
                </p>
                <ul>
                    <li>
                        <strong>To provide and maintain our Service</strong>,
                        including to monitor the usage of our Service.
                    </li>
                    <li>
                        <strong>To manage Your Account:</strong> to manage Your
                        registration as a user of the Service. The Personal Data
                        You provide can give You access to different
                        functionalities of the Service that are available to You
                        as a registered user.
                    </li>
                    <li>
                        <strong>For the performance of a contract:</strong> the
                        development, compliance and undertaking of the purchase
                        contract for the products, items or services You have
                        purchased or of any other contract with Us through the
                        Service.
                    </li>
                    <li>
                        <strong>To contact You:</strong> To contact You by
                        email, telephone calls, SMS, or other equivalent forms
                        of electronic communication, such as a mobile
                        application's push notifications regarding updates or
                        informative communications related to the
                        functionalities, products or contracted services,
                        including the security updates, when necessary or
                        reasonable for their implementation.
                    </li>
                    <li>
                        <strong>To provide You</strong> with news, special
                        offers and general information about other goods,
                        services and events which we offer that are similar to
                        those that you have already purchased or enquired about
                        unless You have opted not to receive such information.
                    </li>
                    <li>
                        <strong>To manage Your requests:</strong> To attend and
                        manage Your requests to Us.
                    </li>
                </ul>

                <p>
                    We may share your personal information in the following
                    situations:
                </p>

                <ul>
                    <li>
                        <strong>With Service Providers:</strong> We may share
                        Your personal information with Service Providers to
                        monitor and analyze the use of our Service, to show
                        advertisements to You to help support and maintain Our
                        Service, to contact You, to advertise on third party
                        websites to You after You visited our Service or for
                        payment processing.
                    </li>
                    <li>
                        <strong>For Business transfers:</strong> We may share or
                        transfer Your personal information in connection with,
                        or during negotiations of, any merger, sale of Company
                        assets, financing, or acquisition of all or a portion of
                        our business to another company.
                    </li>
                    <li>
                        <strong>With Affiliates:</strong> We may share Your
                        information with Our affiliates, in which case we will
                        require those affiliates to honor this Privacy Policy.
                        Affiliates include Our parent company and any other
                        subsidiaries, joint venture partners or other companies
                        that We control or that are under common control with
                        Us.
                    </li>
                    <li>
                        <strong>With Business partners:</strong> We may share
                        Your information with Our business partners to offer You
                        certain products, services or promotions.
                    </li>
                    <li>
                        <strong>With other users:</strong> when You share
                        personal information or otherwise interact in the public
                        areas with other users, such information may be viewed
                        by all users and may be publicly distributed outside. If
                        You interact with other users or register through a
                        Third-Party Social Media Service, Your contacts on the
                        Third-Party Social Media Service may see Your name,
                        profile, pictures and description of Your activity.
                        Similarly, other users will be able to view descriptions
                        of Your activity, communicate with You and view Your
                        profile.
                    </li>
                </ul>

                <h2>Retention of Your Personal Data</h2>
                <p>
                    The Company will retain Your Personal Data only for as long
                    as is necessary for the purposes set out in this Privacy
                    Policy. We will retain and use Your Personal Data to the
                    extent necessary to comply with our legal obligations (for
                    example, if we are required to retain your data to comply
                    with applicable laws), resolve disputes, and enforce our
                    legal agreements and policies.
                </p>
                <p>
                    The Company will also retain Usage Data for internal
                    analysis purposes. Usage Data is generally retained for a
                    shorter period of time, except when this data is used to
                    strengthen the security or to improve the functionality of
                    Our Service, or We are legally obligated to retain this data
                    for longer time periods.
                </p>

                <h2>Transfer of Your Personal Data</h2>
                <p>
                    Your information, including Personal Data, is processed at
                    the Company's operating offices and in any other places
                    where the parties involved in the processing are located. It
                    means that this information may be transferred to — and
                    maintained on — computers located outside of Your state,
                    province, country or other governmental jurisdiction where
                    the data protection laws may differ than those from Your
                    jurisdiction.
                </p>
                <p>
                    Your consent to this Privacy Policy followed by Your
                    submission of such information represents Your agreement to
                    that transfer.
                </p>
                <p>
                    The Company will take all steps reasonably necessary to
                    ensure that Your data is treated securely and in accordance
                    with this Privacy Policy and no transfer of Your Personal
                    Data will take place to an organization or a country unless
                    there are adequate controls in place including the security
                    of Your data and other personal information.
                </p>

                <h2>Disclosure of Your Personal Data</h2>
                <h3>Business Transactions</h3>
                <p>
                    If the Company is involved in a merger, acquisition or asset
                    sale, Your Personal Data may be transferred. We will provide
                    notice before Your Personal Data is transferred and becomes
                    subject to a different Privacy Policy.
                </p>
                <h3>Law enforcement</h3>
                <p>
                    Under certain circumstances, the Company may be required to
                    disclose Your Personal Data if required to do so by law or
                    in response to valid requests by public authorities (e.g. a
                    court or a government agency).
                </p>
                <h3>Other legal requirements</h3>
                <p>
                    The Company may disclose Your Personal Data in the good
                    faith belief that such action is necessary to:
                </p>
                <ul>
                    <li>Comply with a legal obligation</li>
                    <li>
                        Protect and defend the rights or property of the Company
                    </li>
                    <li>
                        Prevent or investigate possible wrongdoing in connection
                        with the Service
                    </li>
                    <li>
                        Protect the personal safety of Users of the Service or
                        the public
                    </li>
                    <li>Protect against legal liability</li>
                </ul>

                <h2>Security of Your Personal Data</h2>
                <p>
                    The security of Your Personal Data is important to Us, but
                    remember that no method of transmission over the Internet,
                    or method of electronic storage is 100% secure. While We
                    strive to use commercially acceptable means to protect Your
                    Personal Data, We cannot guarantee its absolute security.
                </p>

                <h1>
                    Detailed Information on the Processing of Your Personal Data
                </h1>
                <p>
                    Service Providers have access to Your Personal Data only to
                    perform their tasks on Our behalf and are obligated not to
                    disclose or use it for any other purpose.
                </p>

                <h2>Analytics</h2>
                <p>
                    We may use third-party Service providers to monitor and
                    analyze the use of our Service.
                </p>
                <ul>
                    <li>
                        <p><strong>Google Analytics</strong></p>
                        <p>
                            Google Analytics is a web analytics service offered
                            by Google that tracks and reports website traffic.
                            Google uses the data collected to track and monitor
                            the use of our Service. This data is shared with
                            other Google services. Google may use the collected
                            data to contextualise and personalise the ads of its
                            own advertising network.
                        </p>
                        <p>
                            You can opt-out of having made your activity on the
                            Service available to Google Analytics by installing
                            the Google Analytics opt-out browser add-on. The
                            add-on prevents the Google Analytics JavaScript
                            (ga.js, analytics.js and dc.js) from sharing
                            information with Google Analytics about visits
                            activity.
                        </p>
                        <p>
                            For more information on the privacy practices of
                            Google, please visit the Google Privacy & Terms web
                            page:
                            <a href="https://policies.google.com/privacy?hl=en"
                                >https://policies.google.com/privacy?hl=en</a
                            >
                        </p>
                    </li>
                </ul>

                <h2>Advertising</h2>
                <p>
                    We may use Service providers to show advertisements to You
                    to help support and maintain Our Service.
                </p>
                <ul>
                    <li>
                        <p>
                            <strong>Google AdSense & DoubleClick Cookie</strong>
                        </p>
                        <p>
                            Google, as a third party vendor, uses cookies to
                            serve ads on our Service. Google's use of the
                            DoubleClick cookie enables it and its partners to
                            serve ads to our users based on their visit to our
                            Service or other websites on the Internet.
                        </p>
                        <p>
                            You may opt out of the use of the DoubleClick Cookie
                            for interest-based advertising by visiting the
                            Google Ads Settings web page:
                            <a href="http://www.google.com/ads/preferences/"
                                >http://www.google.com/ads/preferences/</a
                            >
                        </p>
                    </li>
                </ul>

                <h2>Email Marketing</h2>
                <p>
                    We may use Your Personal Data to contact You with
                    newsletters, marketing or promotional materials and other
                    information that may be of interest to You. You may opt-out
                    of receiving any, or all, of these communications from Us by
                    following the unsubscribe link or instructions provided in
                    any email We send or by contacting Us.
                </p>
                <p>
                    We may use Email Marketing Service Providers to manage and
                    send emails to You.
                </p>

                <h2>Behavioral Remarketing</h2>
                <p>
                    The Company uses remarketing services to advertise on third
                    party websites to You after You visited our Service. We and
                    Our third-party vendors use cookies to inform, optimize and
                    serve ads based on Your past visits to our Service.
                </p>
                <ul>
                    <li>
                        <p><strong>Google Ads (AdWords)</strong></p>
                        <p>
                            Google Ads (AdWords) remarketing service is provided
                            by Google Inc.
                        </p>
                        <p>
                            You can opt-out of Google Analytics for Display
                            Advertising and customise the Google Display Network
                            ads by visiting the Google Ads Settings page:
                            <a href="http://www.google.com/settings/ads"
                                >http://www.google.com/settings/ads</a
                            >
                        </p>
                        <p>
                            Google also recommends installing the Google
                            Analytics Opt-out Browser Add-on -
                            <a href="https://tools.google.com/dlpage/gaoptout"
                                >https://tools.google.com/dlpage/gaoptout</a
                            >
                            - for your web browser. Google Analytics Opt-out
                            Browser Add-on provides visitors with the ability to
                            prevent their data from being collected and used by
                            Google Analytics.
                        </p>
                        <p>
                            For more information on the privacy practices of
                            Google, please visit the Google Privacy & Terms web
                            page:
                            <a href="https://policies.google.com/privacy?hl=en"
                                >https://policies.google.com/privacy?hl=en</a
                            >
                        </p>
                    </li>
                    <li>
                        <p><strong>Twitter</strong></p>
                        <p>
                            Twitter remarketing service is provided by Twitter
                            Inc.
                        </p>
                        <p>
                            You can opt-out from Twitter's interest-based ads by
                            following their instructions:
                            <a
                                href="https://support.twitter.com/articles/20170405"
                                >https://support.twitter.com/articles/20170405</a
                            >
                        </p>
                        <p>
                            You can learn more about the privacy practices and
                            policies of Twitter by visiting their Privacy Policy
                            page:
                            <a href="https://twitter.com/privacy"
                                >https://twitter.com/privacy</a
                            >
                        </p>
                    </li>
                    <li>
                        <p><strong>Facebook</strong></p>
                        <p>
                            Facebook remarketing service is provided by Facebook
                            Inc.
                        </p>
                        <p>
                            You can learn more about interest-based advertising
                            from Facebook by visiting this page:
                            <a
                                href="https://www.facebook.com/help/164968693837950"
                                >https://www.facebook.com/help/164968693837950</a
                            >
                        </p>
                        <p>
                            To opt-out from Facebook's interest-based ads,
                            follow these instructions from Facebook:
                            <a
                                href="https://www.facebook.com/help/568137493302217"
                                >https://www.facebook.com/help/568137493302217</a
                            >
                        </p>
                        <p></p>
                        <p>
                            Facebook adheres to the Self-Regulatory Principles
                            for Online Behavioural Advertising established by
                            the Digital Advertising Alliance. You can also
                            opt-out from Facebook and other participating
                            companies through the Digital Advertising Alliance
                            in the USA
                            <a href="http://www.aboutads.info/choices/"
                                >http://www.aboutads.info/choices/</a
                            >, the Digital Advertising Alliance of Canada in
                            Canada
                            <a href="http://youradchoices.ca/"
                                >http://youradchoices.ca/</a
                            >
                            or the European Interactive Digital Advertising
                            Alliance in Europe
                            <a href="http://www.youronlinechoices.eu/"
                                >http://www.youronlinechoices.eu/</a
                            >, or opt-out using your mobile device settings.
                        </p>
                        <p>
                            For more information on the privacy practices of
                            Facebook, please visit Facebook's Data Policy:
                            <a
                                href="https://www.facebook.com/privacy/explanation"
                                >https://www.facebook.com/privacy/explanation</a
                            >
                        </p>
                    </li>
                </ul>

                <h2>Payments</h2>
                <p>
                    We may provide paid products and/or services within the
                    Service. In that case, we may use third-party services for
                    payment processing (e.g. payment processors).
                </p>
                <p>
                    We will not store or collect Your payment card details. That
                    information is provided directly to Our third-party payment
                    processors whose use of Your personal information is
                    governed by their Privacy Policy. These payment processors
                    adhere to the standards set by PCI-DSS as managed by the PCI
                    Security Standards Council, which is a joint effort of
                    brands like Visa, Mastercard, American Express and Discover.
                    PCI-DSS requirements help ensure the secure handling of
                    payment information.
                </p>
                <ul>
                    <li>
                        <p><strong>PayPal</strong></p>
                        <p>
                            Their Privacy Policy can be viewed at
                            <a
                                href="https://www.paypal.com/webapps/mpp/ua/privacy-full"
                                >https://www.paypal.com/webapps/mpp/ua/privacy-full</a
                            >
                        </p>
                    </li>
                    <li>
                        <p><strong>PayPal</strong></p>
                        <p>
                            Their Privacy Policy can be viewed at
                            <a
                                href="https://www.braintreepayments.com/legal/braintree-privacy-policy"
                                >https://www.braintreepayments.com/legal/braintree-privacy-policy</a
                            >
                        </p>
                    </li>
                    <li>
                        <p><strong>Sage Pay</strong></p>
                        <p>
                            Their Privacy Policy can be viewed at
                            <a href="https://www.sagepay.co.uk/policies"
                                >https://www.sagepay.co.uk/policies</a
                            >
                        </p>
                    </li>
                </ul>

                <h1>GDPR Privacy</h1>
                <h2>Legal Basis for Processing Personal Data under GDPR</h2>
                <p>
                    We may process Personal Data under the following conditions:
                </p>

                <ul>
                    <li>
                        <strong>Consent:</strong> You have given Your consent
                        for processing Personal Data for one or more specific
                        purposes.
                    </li>
                    <li>
                        <strong>Performance of a contract:</strong> Provision of
                        Personal Data is necessary for the performance of an
                        agreement with You and/or for any pre-contractual
                        obligations thereof.
                    </li>
                    <li>
                        <strong>Legal obligations:</strong> Processing Personal
                        Data is necessary for compliance with a legal obligation
                        to which the Company is subject.
                    </li>
                    <li>
                        <strong>Vital interests:</strong> Processing Personal
                        Data is necessary in order to protect Your vital
                        interests or of another natural person.
                    </li>
                    <li>
                        <strong>Public interests:</strong> Processing Personal
                        Data is related to a task that is carried out in the
                        public interest or in the exercise of official authority
                        vested in the Company.
                    </li>
                    <li>
                        <strong>Legitimate interests:</strong> Processing
                        Personal Data is necessary for the purposes of the
                        legitimate interests pursued by the Company.
                    </li>
                </ul>
                <p>
                    In any case, the Company will gladly help to clarify the
                    specific legal basis that applies to the processing, and in
                    particular whether the provision of Personal Data is a
                    statutory or contractual requirement, or a requirement
                    necessary to enter into a contract.
                </p>

                <h2>Your Rights under the GDPR</h2>
                <p>
                    The Company undertakes to respect the confidentiality of
                    Your Personal Data and to guarantee You can exercise Your
                    rights.
                </p>
                <p>
                    You have the right under this Privacy Policy, and by law if
                    You are within the EU, to:
                </p>

                <ul>
                    <li>
                        <strong>Request access to Your Personal Data.</strong>
                        The right to access, update or delete the information We
                        have on You. Whenever made possible, you can access,
                        update or request deletion of Your Personal Data
                        directly within Your account settings section. If you
                        are unable to perform these actions yourself, please
                        contact Us to assist You. This also enables You to
                        receive a copy of the Personal Data We hold about You.
                    </li>
                    <li>
                        <strong
                            >Request correction of the Personal Data that We
                            hold about You.</strong
                        >
                        You have the right to to have any incomplete or
                        inaccurate information We hold about You corrected.
                    </li>
                    <li>
                        <strong
                            >Object to processing of Your Personal Data.</strong
                        >
                        This right exists where We are relying on a legitimate
                        interest as the legal basis for Our processing and there
                        is something about Your particular situation, which
                        makes You want to object to our processing of Your
                        Personal Data on this ground. You also have the right to
                        object where We are processing Your Personal Data for
                        direct marketing purposes.
                    </li>
                    <li>
                        <strong>Request erasure of Your Personal Data.</strong>
                        You have the right to ask Us to delete or remove
                        Personal Data when there is no good reason for Us to
                        continue processing it.
                    </li>
                    <li>
                        <strong
                            >Request the transfer of Your Personal Data.</strong
                        >
                        We will provide to You, or to a third-party You have
                        chosen, Your Personal Data in a structured, commonly
                        used, machine-readable format. Please note that this
                        right only applies to automated information which You
                        initially provided consent for Us to use or where We
                        used the information to perform a contract with You.
                    </li>
                    <li>
                        <strong>Withdraw Your consent.</strong> You have the
                        right to withdraw Your consent on using your Personal
                        Data. If You withdraw Your consent, We may not be able
                        to provide You with access to certain specific
                        functionalities of the Service.
                    </li>
                </ul>

                <h2>Exercising of Your GDPR Data Protection Rights</h2>
                <p>
                    You may exercise Your rights of access, rectification,
                    cancellation and opposition by contacting Us. Please note
                    that we may ask You to verify Your identity before
                    responding to such requests. If You make a request, We will
                    try our best to respond to You as soon as possible.
                </p>
                <p>
                    You have the right to complain to a Data Protection
                    Authority about Our collection and use of Your Personal
                    Data. For more information, if You are in the European
                    Economic Area (EEA), please contact Your local data
                    protection authority in the EEA.
                </p>

                <h1>Facebook Fan Page</h1>
                <h2>Data Controller for the Facebook Fan Page</h2>
                <p>
                    The Company is the Data Controller of Your Personal Data
                    collected while using the Service. As operator of the
                    Facebook Fan Page
                    (https://www.facebook.com/Diligram-111419553828584/), the
                    Company and the operator of the social network Facebook are
                    Joint Controllers.
                </p>
                <p>
                    The Company has entered into agreements with Facebook that
                    define the terms for use of the Facebook Fan Page, among
                    other things. These terms are mostly based on the Facebook
                    Terms of Service:
                    <a href="https://www.facebook.com/terms.php"
                        >https://www.facebook.com/terms.php</a
                    >
                </p>
                <p>
                    Visit the Facebook Privacy Policy
                    <a href="https://www.facebook.com/policy.php"
                        >https://www.facebook.com/policy.php</a
                    >
                    for more information about how Facebook manages Personal
                    data or contact Facebook online, or by mail: Facebook, Inc.
                    ATTN, Privacy Operations, 1601 Willow Road, Menlo Park, CA
                    94025, United States.
                </p>

                <h2>Facebook Insights</h2>
                <p>
                    We use the Facebook Insights function in connection with the
                    operation of the Facebook Fan Page and on the basis of the
                    GDPR, in order to obtain anonymized statistical data about
                    Our users.
                </p>
                <p>
                    For this purpose, Facebook places a Cookie on the device of
                    the user visiting Our Facebook Fan Page. Each Cookie
                    contains a unique identifier code and remains active for a
                    period of two years, except when it is deleted before the
                    end of this period.
                </p>
                <p>
                    Facebook receives, records and processes the information
                    stored in the Cookie, especially when the user visits the
                    Facebook services, services that are provided by other
                    members of the Facebook Fan Page and services by other
                    companies that use Facebook services.
                </p>
                <p>
                    For more information on the privacy practices of Facebook,
                    please visit Facebook Privacy Policy here:
                    <a href="https://www.facebook.com/full_data_use_policy"
                        >https://www.facebook.com/full_data_use_policy</a
                    >
                </p>

                <h1>Children's Privacy</h1>
                <p>
                    The Service may contain content appropriate for children
                    under the age of 13. As a parent, you should know that
                    through the Service children under the age of 13 may
                    participate in activities that involve the collection or use
                    of personal information. We use reasonable efforts to ensure
                    that before we collect any personal information from a
                    child, the child's parent receives notice of and consents to
                    our personal information practices.
                </p>
                <p>
                    We also may limit how We collect, use, and store some of the
                    information of Users between 13 and 18 years old. In some
                    cases, this means We will be unable to provide certain
                    functionality of the Service to these Users. If We need to
                    rely on consent as a legal basis for processing Your
                    information and Your country requires consent from a parent,
                    We may require Your parent's consent before We collect and
                    use that information.
                </p>
                <p>
                    We may ask a User to verify its date of birth before
                    collecting any personal information from them. If the User
                    is under the age of 13, the Service will be either blocked
                    or redirected to a parental consent process.
                </p>

                <h2>Information Collected from Children Under the Age of 13</h2>
                <p>
                    The Company may collect and store persistent identifiers
                    such as cookies or IP addresses from Children without
                    parental consent for the purpose of supporting the internal
                    operation of the Service.
                </p>
                <p>
                    We may collect and store other personal information about
                    children if this information is submitted by a child with
                    prior parent consent or by the parent or guardian of the
                    child.
                </p>
                <p>
                    The Company may collect and store the following types of
                    personal information about a child when submitted by a child
                    with prior parental consent or by the parent or guardian of
                    the child:
                </p>
                <ul>
                    <li>First and/or last name</li>
                    <li>Date of birth</li>
                    <li>Gender</li>
                    <li>Grade level</li>
                    <li>Email address</li>
                    <li>Telephone number</li>
                    <li>Parent's or guardian's name</li>
                    <li>Parent's or guardian's email address</li>
                </ul>
                <p>
                    For further details on the information We might collect, You
                    can refer to the "Types of Data Collected" section of this
                    Privacy Policy. We follow our standard Privacy Policy for
                    the disclosure of personal information collected from and
                    about children.
                </p>

                <h2>Parental Access</h2>
                <p>
                    A parent who has already given the Company permission to
                    collect and use his child personal information can, at any
                    time:
                </p>
                <ul>
                    <li>
                        Review, correct or delete the child's personal
                        information
                    </li>
                    <li>
                        Discontinue further collection or use of the child's
                        personal information
                    </li>
                </ul>
                <p>
                    To make such a request, You can write to Us using the
                    contact information provided in this Privacy Policy.
                </p>

                <h1>
                    Your California Privacy Rights (California Business and
                    Professions Code Section 22581)
                </h1>
                <p>
                    California Business and Professions Code section 22581 allow
                    California residents under the age of 18 who are registered
                    users of online sites, services or applications to request
                    and obtain removal of content or information they have
                    publicly posted.
                </p>
                <p>
                    To request removal of such data, and if you are a California
                    resident, You can contact Us using the contact information
                    provided below, and include the email address associated
                    with Your account.
                </p>
                <p>
                    Be aware that Your request does not guarantee complete or
                    comprehensive removal of content or information posted
                    online and that the law may not permit or require removal in
                    certain circumstances.
                </p>

                <h1>Links to Other Websites</h1>
                <p>
                    Our Service may contain links to other websites that are not
                    operated by Us. If You click on a third party link, You will
                    be directed to that third party's site. We strongly advise
                    You to review the Privacy Policy of every site You visit.
                </p>
                <p>
                    We have no control over and assume no responsibility for the
                    content, privacy policies or practices of any third party
                    sites or services.
                </p>

                <h1>Changes to this Privacy Policy</h1>
                <p>
                    We may update our Privacy Policy from time to time. We will
                    notify You of any changes by posting the new Privacy Policy
                    on this page.
                </p>
                <p>
                    We will let You know via email and/or a prominent notice on
                    Our Service, prior to the change becoming effective and
                    update the "Last updated" date at the top of this Privacy
                    Policy.
                </p>
                <p>
                    You are advised to review this Privacy Policy periodically
                    for any changes. Changes to this Privacy Policy are
                    effective when they are posted on this page.
                </p>

                <h1>Contact Us</h1>
                <p>
                    If you have any questions about this Privacy Policy, You can
                    contact us:
                </p>
                <ul>
                    <li>By email: info@diligram.com</li>
                    <li>
                        By visiting this page on our website:
                        <a
                            target="_blank"
                            href="https://www.diligram.com/#contactUs"
                            >https://www.diligram.com/#contactUs</a
                        >
                    </li>
                </ul>
            </div>
            <div class="col-12 col-sm-12 terms" *ngIf="language === 'de'">
                <h2>Datenschutzerkl&auml;rung</h2>
                <p>
                    Verantwortliche Stelle im Sinne der Datenschutzgesetze,
                    insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:
                </p>
                <br />
                <p>
                    Diligram AG<br />Rolf Uwe Placzek<br />Talstrasse 23<br />6403
                    Küssnacht SZ / Schweiz
                </p>
                <p>
                    Telefon: +41 41 567 0132<br />E-Mail: info@diligram.com<br />WebSite:
                    https://diligram.com/
                </p>
                <p>&nbsp;</p>
                <h2>Allgemeiner Hinweis</h2>
                <p>
                    Gest&uuml;tzt auf Artikel 13 der schweizerischen
                    Bundesverfassung und die datenschutzrechtlichen Bestimmungen
                    des Bundes (Datenschutzgesetz, DSG<!--DSG-->) hat jede
                    Person Anspruch auf Schutz ihrer Privatsph&auml;re sowie auf
                    Schutz vor Missbrauch ihrer pers&ouml;nlichen Daten. Die
                    Betreiber dieser Seiten nehmen den Schutz Ihrer
                    pers&ouml;nlichen Daten sehr ernst. Wir behandeln Ihre
                    personenbezogenen Daten vertraulich und entsprechend der
                    gesetzlichen Datenschutzvorschriften sowie dieser
                    Datenschutzerkl&auml;rung.
                </p>
                <p>
                    In Zusammenarbeit mit unseren Hosting-Providern bem&uuml;hen
                    wir uns, die Datenbanken so gut wie m&ouml;glich vor fremden
                    Zugriffen, Verlusten, Missbrauch oder vor F&auml;lschung zu
                    sch&uuml;tzen.
                </p>
                <p>
                    Wir weisen darauf hin, dass die Daten&uuml;bertragung im
                    Internet (z.B. bei der Kommunikation per E-Mail)
                    Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser
                    Schutz der Daten vor dem Zugriff durch Dritte ist nicht
                    m&ouml;glich.
                </p>
                <p>
                    Durch die Nutzung dieser Website erkl&auml;ren Sie sich mit
                    der Erhebung, Verarbeitung und Nutzung von Daten gem&auml;ss
                    der nachfolgenden Beschreibung einverstanden. Diese Website
                    kann grunds&auml;tzlich ohne Registrierung besucht werden.
                    Dabei werden Daten wie beispielsweise aufgerufene Seiten
                    bzw. Namen der abgerufenen Datei, Datum und Uhrzeit zu
                    statistischen Zwecken auf dem Server gespeichert, ohne dass
                    diese Daten unmittelbar auf Ihre Person bezogen werden.
                    Personenbezogene Daten, insbesondere Name, Adresse oder
                    E-Mail-Adresse werden soweit m&ouml;glich auf freiwilliger
                    Basis erhoben. Ohne Ihre Einwilligung erfolgt keine
                    Weitergabe der Daten an Dritte.
                </p>
                <p>&nbsp;</p>
                <h2>Bearbeitung von Personendaten</h2>
                <p>
                    Personendaten sind alle Angaben, die sich auf eine bestimmte
                    oder bestimmbare Person beziehen. Eine betroffene Person ist
                    eine Person, &uuml;ber die Personendaten bearbeitet werden.
                    Bearbeiten umfasst jeden Umgang mit Personendaten,
                    unabh&auml;ngig von den angewandten Mitteln und Verfahren,
                    insbesondere das Aufbewahren, Bekanntgeben, Beschaffen,
                    L&ouml;schen, Speichern, Ver&auml;ndern, Vernichten und
                    Verwenden von Personendaten.
                </p>
                <p>
                    Wir bearbeiten Personendaten im Einklang mit dem
                    schweizerischen Datenschutzrecht. Im &Uuml;brigen bearbeiten
                    wir – soweit und sofern die EU-DSGVO anwendbar ist –
                    Personendaten gem&auml;ss folgenden Rechtsgrundlagen im
                    Zusammenhang mit Art. 6 Abs. 1 DSGVO<!--DSGVO-->:
                </p>
                <ul>
                    <li>
                        lit. a) Bearbeitung von Personendaten mit Einwilligung
                        der betroffenen Person.
                    </li>
                    <li>
                        lit. b) Bearbeitung von Personendaten zur Erf&uuml;llung
                        eines Vertrages mit der betroffenen Person sowie zur
                        Durchf&uuml;hrung entsprechender vorvertraglicher
                        Massnahmen.
                    </li>
                    <li>
                        lit. c) Bearbeitung von Personendaten zur Erf&uuml;llung
                        einer rechtlichen Verpflichtung, der wir gem&auml;ss
                        allenfalls anwendbarem Recht der EU oder gem&auml;ss
                        allenfalls anwendbarem Recht eines Landes, in dem die
                        DSGVO<!--DSGVO-->
                        ganz oder teilweise anwendbar ist, unterliegen.
                    </li>
                    <li>
                        lit. d) Bearbeitung von Personendaten um lebenswichtige
                        Interessen der betroffenen Person oder einer anderen
                        nat&uuml;rlichen Person zu sch&uuml;tzen.
                    </li>
                    <li>
                        lit. f) Bearbeitung von Personendaten um die
                        berechtigten Interessen von uns oder von Dritten zu
                        wahren, sofern nicht die Grundfreiheiten und Grundrechte
                        sowie Interessen der betroffenen Person &uuml;berwiegen.
                        Berechtigte Interessen sind insbesondere unser
                        betriebswirtschaftliches Interesse, unsere Website
                        bereitstellen zu k&ouml;nnen, die
                        Informationssicherheit, die Durchsetzung von eigenen
                        rechtlichen Anspr&uuml;chen und die Einhaltung von
                        schweizerischem Recht.
                    </li>
                </ul>
                <p>
                    Wir bearbeiten Personendaten f&uuml;r jene Dauer, die
                    f&uuml;r den jeweiligen Zweck oder die jeweiligen Zwecke
                    erforderlich ist. Bei l&auml;nger dauernden
                    Aufbewahrungspflichten aufgrund von gesetzlichen und
                    sonstigen Pflichten, denen wir unterliegen, schr&auml;nken
                    wir die Bearbeitung entsprechend ein.
                </p>
                <p>&nbsp;</p>
                <h2>Datenschutzerkl&auml;rung f&uuml;r Cookies</h2>
                <p>
                    Diese Website verwendet Cookies. Das sind kleine
                    Textdateien, die es m&ouml;glich machen, auf dem
                    Endger&auml;t des Nutzers spezifische, auf den Nutzer
                    bezogene Informationen zu speichern, w&auml;hrend er die
                    Website nutzt. Cookies erm&ouml;glichen es, insbesondere
                    Nutzungsh&auml;ufigkeit und Nutzeranzahl der Seiten zu
                    ermitteln, Verhaltensweisen der Seitennutzung zu
                    analysieren, aber auch unser Angebot kundenfreundlicher zu
                    gestalten.
                    <!--Allg.Cookies-->Cookies bleiben &uuml;ber das Ende einer
                    Browser-Sitzung gespeichert und k&ouml;nnen bei einem
                    erneuten Seitenbesuch wieder aufgerufen werden. Wenn Sie das
                    nicht w&uuml;nschen, sollten Sie Ihren Internetbrowser so
                    einstellen, dass er die Annahme von Cookies verweigert.
                </p>
                <p>
                    Ein genereller Widerspruch gegen den Einsatz der zu Zwecken
                    des Onlinemarketing eingesetzten Cookies kann bei einer
                    Vielzahl der Dienste, vor allem im Fall des Trackings, über
                    die US-amerikanische Seite
                    <a
                        href="http://www.aboutads.info/choices/"
                        target="_blank"
                        rel="noopener"
                        >http://www.aboutads.info/choices/</a
                    >
                    oder die EU-Seite
                    <a
                        href="http://www.youronlinechoices.com/"
                        target="_blank"
                        rel="noopener"
                        >http://www.youronlinechoices.com/</a
                    >
                    erklärt werden. Des Weiteren kann die Speicherung von
                    Cookies mittels deren Abschaltung in den Einstellungen des
                    Browsers erreicht werden. Bitte beachten Sie, dass dann
                    gegebenenfalls nicht alle Funktionen dieses Onlineangebotes
                    genutzt werden können.
                </p>
                <p>&nbsp;</p>
                <h2>
                    Datenschutzerkl&auml;rung f&uuml;r
                    SSL-/TLS-Verschl&uuml;sselung
                </h2>
                <p>
                    Diese Website nutzt aus Gr&uuml;nden der Sicherheit und zum
                    Schutz der &Uuml;bertragung vertraulicher Inhalte, wie zum
                    Beispiel der Anfragen, die Sie an uns als Seitenbetreiber
                    senden, eine SSL-/TLS-Verschl&uuml;sselung. Eine
                    verschl&uuml;sselte Verbindung erkennen Sie daran, dass die
                    Adresszeile des Browsers von &quot;http://&quot; auf
                    &quot;https://&quot; wechselt und an dem Schloss-Symbol in
                    Ihrer Browserzeile.
                </p>
                <p>
                    Wenn die SSL bzw. TLS Verschl&uuml;sselung aktiviert ist,
                    k&ouml;nnen die Daten, die Sie an uns &uuml;bermitteln,
                    nicht von Dritten mitgelesen werden.
                </p>
                <p>&nbsp;</p>
                <h2>Datenschutzerkl&auml;rung f&uuml;r Server-Log-Files</h2>
                <p>
                    Der Provider dieser Website erhebt und speichert automatisch
                    Informationen in so genannten Server-Log Files, die Ihr
                    Browser automatisch an uns &uuml;bermittelt. Dies sind:
                </p>
                <ul>
                    <li>Browsertyp und Browserversion</li>
                    <li>verwendetes Betriebssystem</li>
                    <li>Referrer URL</li>
                    <li>Hostname des zugreifenden Rechners</li>
                    <li>Uhrzeit der Serveranfrage</li>
                </ul>
                <p>
                    Diese Daten sind nicht bestimmten Personen zuordenbar. Eine
                    Zusammenf&uuml;hrung dieser Daten mit anderen Datenquellen
                    wird nicht vorgenommen. Wir behalten uns vor, diese Daten
                    nachtr&auml;glich zupr&uuml;fen, wenn uns konkrete
                    Anhaltspunkte f&uuml;r eine rechtswidrige Nutzung bekannt
                    werden.
                </p>
                <p>&nbsp;</p>
                <h2>Dienste von Dritten</h2>
                <p>
                    Diese Website verwenden allenfalls Google Maps f&uuml;r das
                    Einbetten von Karten, Google Invisible reCAPTCHA f&uuml;r
                    den Schutz gegen Bots und Spam sowie YouTube f&uuml;r das
                    Einbetten von Videos.
                </p>
                <p>
                    Diese Dienste der amerikanischen Google LLC verwenden unter
                    anderem Cookies und infolgedessen werden Daten an Google in
                    den USA &uuml;bertragen, wobei wir davon ausgehen, dass in
                    diesem Rahmen kein personenbezogenes Tracking allein durch
                    die Nutzung unserer Website stattfindet.
                </p>
                <p>
                    Google hat sich verpflichtet, einen angemessenen Datenschutz
                    gem&auml;ss dem amerikanisch-europ&auml;ischen und dem
                    amerikanisch-schweizerischen Privacy Shield zu
                    gew&auml;hrleisten.
                </p>
                <p>
                    Weitere Informationen finden sich in der
                    <a
                        href="https://policies.google.com/privacy?hl=de"
                        target="_blank"
                        rel="noopener"
                        style="color: inherit;"
                        >Datenschutzerkl&auml;rung von Google</a
                    >.
                </p>
                <p>&nbsp;</p>
                <h2>Datenschutzerkl&auml;rung f&uuml;r Kontaktformular</h2>
                <p>
                    Wenn Sie uns per Kontaktformular Anfragen zukommen lassen,
                    werden Ihre Angaben aus dem Anfrageformular inklusive der
                    von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung
                    der Anfrage und f&uuml;r den Fall von Anschlussfragen bei
                    uns gespeichert. Diese Daten geben wir nicht ohne Ihre
                    Einwilligung weiter.
                </p>
                <p>&nbsp;</p>
                <h2>Datenschutzerkl&auml;rung f&uuml;r Newsletterdaten</h2>
                <p>
                    Wenn Sie den auf dieser Website angebotenen Newsletter
                    beziehen m&ouml;chten, ben&ouml;tigen wir von Ihnen eine
                    E-Mail-Adresse sowie Informationen, welche uns die
                    &Uuml;berpr&uuml;fung gestatten, dass Sie der Inhaber der
                    angegebenen E-Mail-Adresse sind und mit dem Empfang des
                    Newsletters einverstanden sind. Weitere Daten werden nicht
                    erhoben. Diese Daten verwenden wir ausschliesslich f&uuml;r
                    den Versand der angeforderten Informationen und geben sie
                    nicht an Dritte weiter.
                </p>
                <p>
                    Die erteilte Einwilligung zur Speicherung der Daten, der
                    E-Mail-Adresse sowie deren Nutzung zum Versand des
                    Newsletters k&ouml;nnen Sie jederzeit widerrufen, etwa
                    &uuml;ber den &laquo;Austragen-Link&raquo; im Newsletter.
                </p>
                <p>&nbsp;</p>
                <h2>
                    Datenschutzerkl&auml;rung f&uuml;r Kommentarfunktion auf
                    dieser Website
                </h2>
                <p>
                    F&uuml;r die Kommentarfunktion auf dieser Website werden
                    neben Ihrem Kommentar auch Angaben zum Zeitpunkt der
                    Erstellung des Kommentars, Ihre E-Mail-Adresse und, wenn Sie
                    nicht anonym posten, der von Ihnen gew&auml;hlte Nutzername
                    gespeichert.
                </p>
                <p><i>Speicherung der IP Adresse</i></p>
                <p>
                    Unsere Kommentarfunktion speichert die IP-Adressen der
                    Nutzer, die Kommentare verfassen. Da wir Kommentare auf
                    unserer Seite nicht vor der Freischaltung pr&uuml;fen,
                    ben&ouml;tigen wir diese Daten, um im Falle von
                    Rechtsverletzungen wie Beleidigungen oder Propaganda gegen
                    den Verfasser vorgehen zu k&ouml;nnen.
                </p>
                <p><i>Abonnieren von Kommentaren</i></p>
                <p>
                    Als Nutzer der Seite k&ouml;nnen Sie nach einer Anmeldung
                    Kommentare abonnieren. Sie erhalten eine
                    Best&auml;tigungsemail, um zu pr&uuml;fen, ob Sie der
                    Inhaber der angegebenen E-Mail-Adresse sind. Sie k&ouml;nnen
                    diese Funktion jederzeit &uuml;ber einen Link in den
                    Info-Mails abbestellen.
                </p>
                <p>&nbsp;</p>
                <h2>Rechte betroffener Personen</h2>
                <p><strong>Recht auf Best&auml;tigung</strong></p>
                <p>
                    Jede betroffene Person hat das Recht, vom Betreiber der
                    Website eine Best&auml;tigung dar&uuml;ber zu verlangen, ob
                    betroffene Personen betreffende, personenbezogene Daten
                    verarbeitet werden. M&ouml;chten Sie dieses
                    Best&auml;tigungsrecht in Anspruch nehmen, k&ouml;nnen Sie
                    sich hierzu jederzeit an den Datenschutzbeauftragten wenden.
                </p>
                <br />
                <p><strong>Recht auf Auskunft</strong></p>
                <p>
                    Jede von der Verarbeitung betroffene Person mit
                    personenbezogenen Daten hat das Recht, jederzeit vom
                    Betreiber dieser Website unentgeltliche Auskunft &uuml;ber
                    die zu seiner Person gespeicherten personenbezogenen Daten
                    und eine Kopie dieser Auskunft zu erhalten. Ferner kann
                    gegebenenfalls &uuml;ber folgende Informationen Auskunft
                    gegeben werden:
                </p>
                <ul>
                    <li>die Verarbeitungszwecke</li>
                    <li>
                        die Kategorien personenbezogener Daten, die verarbeitet
                        werden
                    </li>
                    <li>
                        die Empf&auml;nger, gegen&uuml;ber denen die
                        personenbezogenen Daten offengelegt worden sind oder
                        noch offengelegt werden
                    </li>
                    <li>
                        falls m&ouml;glich, die geplante Dauer, f&uuml;r die die
                        personenbezogenen Daten gespeichert werden, oder, falls
                        dies nicht m&ouml;glich ist, die Kriterien f&uuml;r die
                        Festlegung dieser Dauer
                    </li>
                    <li>
                        das Bestehen eines Rechts auf Berichtigung oder
                        L&ouml;schung der sie betreffenden personenbezogenen
                        Daten oder auf Einschr&auml;nkung der Verarbeitung durch
                        den Verantwortlichen oder eines Widerspruchsrechts gegen
                        diese Verarbeitung
                    </li>
                    <li>
                        das Bestehen eines Beschwerderechts bei einer
                        Aufsichtsbeh&ouml;rde
                    </li>
                    <li>
                        wenn die personenbezogenen Daten nicht bei der
                        betroffenen Person erhoben werden: Alle verf&uuml;gbaren
                        Informationen &uuml;ber die Herkunft der Daten
                    </li>
                </ul>
                <p>
                    Ferner steht der betroffenen Person ein Auskunftsrecht
                    dar&uuml;ber zu, ob personenbezogene Daten an ein Drittland
                    oder an eine internationale Organisation &uuml;bermittelt
                    wurden. Sofern dies der Fall ist, so steht der betroffenen
                    Person im &uuml;brigen das Recht zu, Auskunft &uuml;ber die
                    geeigneten Garantien im Zusammenhang mit der
                    &uuml;bermittlung zu erhalten.
                </p>
                <p>
                    M&ouml;chten Sie dieses Auskunftsrecht in Anspruch nehmen,
                    k&ouml;nnen Sie sich hierzu jederzeit an unseren
                    Datenschutzbeauftragten wenden.
                </p>
                <br />
                <p><strong>Recht auf Berichtigung</strong></p>
                <p>
                    Jede von der Verarbeitung personenbezogener Daten betroffene
                    Person hat das Recht, die unverz&uuml;gliche Berichtigung
                    sie betreffender unrichtiger personenbezogener Daten zu
                    verlangen. Ferner steht der betroffenen Person das Recht zu,
                    unter Ber&uuml;cksichtigung der Zwecke der Verarbeitung, die
                    Vervollst&auml;ndigung unvollst&auml;ndiger
                    personenbezogener Daten - auch mittels einer
                    erg&auml;nzenden Erkl&auml;rung - zu verlangen.
                </p>
                <p>
                    M&ouml;chten Sie dieses Berichtigungsrecht in Anspruch
                    nehmen, k&ouml;nnen Sie sich hierzu jederzeit an unseren
                    Datenschutzbeauftragten wenden.
                </p>
                <br />
                <p>
                    <strong
                        >Recht auf L&ouml;schung (Recht auf Vergessen
                        werden)</strong
                    >
                </p>
                <p>
                    Jede von der Verarbeitung personenbezogener Daten betroffene
                    Person hat das Recht, von dem Verantwortlichen dieser
                    Website zu verlangen, dass die sie betreffenden
                    personenbezogenen Daten unverz&uuml;glich gel&ouml;scht
                    werden, sofern einer der folgenden Gr&uuml;nde zutrifft und
                    soweit die Verarbeitung nicht erforderlich ist:
                </p>
                <ul>
                    <li>
                        Die personenbezogenen Daten wurden f&uuml;r solche
                        Zwecke erhoben oder auf sonstige Weise verarbeitet,
                        f&uuml;r welche sie nicht mehr notwendig sind
                    </li>
                    <li>
                        Die betroffene Person widerruft ihre Einwilligung, auf
                        die sich die Verarbeitung st&uuml;tzte, und es fehlt an
                        einer anderweitigen Rechtsgrundlage f&uuml;r die
                        Verarbeitung
                    </li>
                    <li>
                        Die betroffene Person legt aus Gr&uuml;nden, die sich
                        aus ihrer besonderen Situation ergeben, Widerspruch
                        gegen die Verarbeitung ein und es liegen keine
                        vorrangigen berechtigten Gr&uuml;nde f&uuml;r die
                        Verarbeitung vor, oder die betroffene Person legt im
                        Falle von Direktwerbung und damit verbundenem Profiling
                        Widerspruch gegen die Verarbeitung ein
                    </li>
                    <li>
                        Die personenbezogenen Daten wurden unrechtm&auml;ssig
                        verarbeitet
                    </li>
                    <li>
                        Die L&ouml;schung der personenbezogenen Daten ist zur
                        Erf&uuml;llung einer rechtlichen Verpflichtung nach dem
                        Unionsrecht oder dem Recht der Mitgliedstaaten
                        erforderlich, dem der Verantwortliche unterliegt
                    </li>
                    <li>
                        Die personenbezogenen Daten wurden in Bezug auf
                        angebotene Dienste der Informationsgesellschaft, die
                        einem Kind direkt gemacht wurden, erhoben
                    </li>
                </ul>
                <p>
                    Sofern einer der oben genannten Gr&uuml;nde zutrifft und Sie
                    die L&ouml;schung von personenbezogenen Daten, die
                    beimBetreiber dieser Website gespeichert sind, veranlassen
                    m&ouml;chten, k&ouml;nnen Sie sich hierzu jederzeit an
                    unseren Datenschutzbeauftragten wenden. Der
                    Datenschutzbeauftragte dieser Website wird veranlassen, dass
                    dem L&ouml;schverlangen unverz&uuml;glich nachgekommen wird.
                </p>
                <br />
                <p>
                    <strong
                        >Recht auf Einschr&auml;nkung der Verarbeitung</strong
                    >
                </p>
                <p>
                    Jede von der Verarbeitung personenbezogener Daten betroffene
                    Person hat das Recht, von dem Verantwortlichen dieser
                    Website die Einschr&auml;nkung der Verarbeitung zu
                    verlangen, wenn eine der folgenden Voraussetzungen gegeben
                    ist:
                </p>
                <ul>
                    <li>
                        Die Richtigkeit der personenbezogenen Daten wird von der
                        betroffenen Person bestritten, und zwar f&uuml;r eine
                        Dauer, die es dem Verantwortlichen erm&ouml;glicht, die
                        Richtigkeit der personenbezogenen Daten zu
                        &uuml;berpr&uuml;fen
                    </li>
                    <li>
                        Die Verarbeitung ist unrechtm&auml;ssig, die betroffene
                        Person lehnt die L&ouml;schung der personenbezogenen
                        Daten ab und verlangt stattdessen die Einschr&auml;nkung
                        der Nutzung der personenbezogenen Daten
                    </li>
                    <li>
                        Der Verantwortliche ben&ouml;tigt die personenbezogenen
                        Daten f&uuml;r die Zwecke der Verarbeitung nicht
                        l&auml;nger, die betroffene Person ben&ouml;tigt sie
                        jedoch zur Geltendmachung, Aus&uuml;bung oder
                        Verteidigung von Rechtsanspr&uuml;chen
                    </li>
                    <li>
                        Die betroffene Person hat aus Gr&uuml;nden, die sich aus
                        ihrer besonderen Situation ergeben, Widerspruch gegen
                        die Verarbeitung eingelegt und es steht noch nicht fest,
                        ob die berechtigten Gr&uuml;nde des Verantwortlichen
                        gegen&uuml;ber denen der betroffenen Person
                        &uuml;berwiegen
                    </li>
                </ul>
                <p>
                    Sofern eine der oben genannten Voraussetzungen gegeben ist
                    Sie die Einschr&auml;nkung von personenbezogenen Daten, die
                    beim Betreiber dieser Website gespeichert sind, verlangen
                    m&ouml;chten, k&ouml;nnen Sie sich hierzu jederzeit an
                    unseren Datenschutzbeauftragten wenden. Der
                    Datenschutzbeauftragte dieser Website wird die
                    Einschr&auml;nkung der Verarbeitung veranlassen.
                </p>
                <br />
                <p><strong>Recht auf Daten&uuml;bertragbarkeit</strong></p>
                <p>
                    Jede von der Verarbeitung personenbezogener Daten betroffene
                    Person hat das Recht, die sie betreffenden personenbezogenen
                    Daten in einem strukturierten, g&auml;ngigen und
                    maschinenlesbaren Format zu erhalten. Sie hat ausserdem das
                    Recht, dass diese Daten bei Vorliegen der gesetzlichen
                    Voraussetzungen einem anderen Verantwortlichen
                    &uuml;bermittelt werden.
                </p>
                <p>
                    Ferner hat die betroffene Person das Recht, zu erwirken,
                    dass die personenbezogenen Daten direkt von einem
                    Verantwortlichen an einen anderen Verantwortlichen
                    &uuml;bermittelt werden, soweit dies technisch machbar ist
                    und sofern hiervon nicht die Rechte und Freiheiten anderer
                    Personen beeintr&auml;chtigt werden.
                </p>
                <p>
                    Zur Geltendmachung des Rechts auf Daten&uuml;bertragbarkeit
                    k&ouml;nnen Sie sich jederzeit an den vom Betreiber dieser
                    Website bestellten Datenschutzbeauftragten wenden.
                </p>
                <br />
                <p><strong>Recht auf Widerspruch</strong></p>
                <p>
                    Jede von der Verarbeitung personenbezogener Daten betroffene
                    Person hat das Recht, aus Gr&uuml;nden, die sich aus ihrer
                    besonderen Situation ergeben, jederzeit gegen die
                    Verarbeitung sie betreffender personenbezogener Daten,
                    Widerspruch einzulegen.
                </p>
                <p>
                    Der Betreiber dieser Website verarbeitet die
                    personenbezogenen Daten im Falle des Widerspruchs nicht
                    mehr, es sei denn, wir k&ouml;nnen zwingende
                    schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung
                    nachweisen, die den Interessen, Rechten und Freiheiten der
                    betroffenen Person &uuml;berwiegen, oder wenn die
                    Verarbeitung der Geltendmachung, Aus&uuml;bung oder
                    Verteidigung von Rechtsanspr&uuml;chen dient.
                </p>
                <p>
                    Zur Aus&uuml;bung des Rechts auf Widerspruch k&ouml;nnen Sie
                    sich direkt an den Datenschutzbeauftragten dieser Website
                    wenden.
                </p>
                <br />
                <p>
                    <strong
                        >Recht auf Widerruf einer datenschutzrechtlichen
                        Einwilligung</strong
                    >
                </p>
                <p>
                    Jede von der Verarbeitung personenbezogener Daten betroffene
                    Person hat das Recht, eine abgegebene Einwilligung zur
                    Verarbeitung personenbezogener Daten jederzeit zu
                    widerrufen.
                </p>
                <p>
                    M&ouml;chten Sie Ihr Recht auf Widerruf einer Einwilligung
                    geltend machen, k&ouml;nnen Sie sich hierzu jederzeit an
                    unseren Datenschutzbeauftragten wenden.
                </p>
                <p>&nbsp;</p>
                <h2>
                    Datenschutzerkl&auml;rung f&uuml;r Widerspruch Werbe-Mails
                </h2>
                <p>
                    Der Nutzung von im Rahmen der Impressumspflicht
                    ver&ouml;ffentlichten Kontaktdaten zur &Uuml;bersendung von
                    nicht ausdr&uuml;cklich angeforderter Werbung und
                    Informationsmaterialien wird hiermit widersprochen. Die
                    Betreiber der Seiten behalten sich ausdr&uuml;cklich
                    rechtliche Schritte im Falle der unverlangten Zusendung von
                    Werbeinformationen, etwa durch Spam-E-Mails, vor.
                </p>
                <p>&nbsp;</p>
                <h2>Kostenpflichtige Leistungen</h2>
                <p>
                    Zur Erbringung kostenpflichtiger Leistungen werden von uns
                    zus&auml;tzliche Daten erfragt, wie z.B. Zahlungsangaben, um
                    Ihre Bestellung resp. Ihren Auftrag ausf&uuml;hren zu
                    k&ouml;nnen. Wir speichern diese Daten in unseren Systemen,
                    bis die gesetzlichen Aufbewahrungsfristen abgelaufen sind.
                </p>
                <p>&nbsp;</p>
                <h2>Verwendung von Google Maps</h2>
                <p>
                    Diese Website nutzt das Angebot von Google Maps. Dadurch
                    können wir Ihnen interaktive Karten direkt in der Website
                    anzeigen und ermöglichen Ihnen die komfortable Nutzung der
                    Karten-Funktion. Durch den Besuch auf der Website erhält
                    Google die Information, dass Sie die entsprechende
                    Unterseite unserer Website aufgerufen haben. Dies erfolgt
                    unabhängig davon, ob Google ein Nutzerkonto bereitstellt,
                    über das Sie eingeloggt sind, oder ob kein Nutzerkonto
                    besteht. Wenn Sie bei Google eingeloggt sind, werden Ihre
                    Daten direkt Ihrem Konto zugeordnet. Wenn Sie die Zuordnung
                    mit Ihrem Profil bei Google nicht wünschen, müssen Sie sich
                    vor Aktivierung des Buttons ausloggen. Google speichert Ihre
                    Daten als Nutzungsprofile und nutzt sie für Zwecke der
                    Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung
                    seiner Website. Eine solche Auswertung erfolgt insbesondere
                    (selbst für nicht eingeloggte Nutzer) zur Erbringung von
                    bedarfsgerechter Werbung und um andere Nutzer des sozialen
                    Netzwerks über Ihre Aktivitäten auf unserer Website zu
                    informieren. Ihnen steht ein Widerspruchsrecht zu gegen die
                    Bildung dieser Nutzerprofile, wobei Sie sich zur Ausübung
                    dessen an Google richten müssen. Weitere Informationen zu
                    Zweck und Umfang der Datenerhebung und ihrer Verarbeitung
                    durch Google erhalten Sie neben weiteren Informationen zu
                    Ihren diesbezüglichen Rechten und Einstellungsmöglichkeiten
                    zum Schutze Ihrer Privatsphäre unter:
                    <a
                        href="http://www.google.de/intl/de/policies/privacy"
                        target="_blank"
                        rel="noopener"
                        >www.google.de/intl/de/policies/privacy</a
                    >.
                </p>
                <p>&nbsp;</p>
                <h2>Datenschutzerkl&auml;rung f&uuml;r Google Analytics</h2>
                <p>
                    Diese Website benutzt Google Analytics, einen
                    Webanalysedienst der Google Ireland Limited. Wenn der
                    Verantwortliche für die Datenverarbeitung auf dieser Website
                    ausserhalb des Europäischen Wirtschaftsraumes oder der
                    Schweiz sitzt, dann erfolgt die Google Analytics
                    Datenverarbeitung durch Google LLC. Google LLC und Google
                    Ireland Limited werden nachfolgend &laquo;Google&raquo;
                    genannt.
                </p>
                <p>
                    Über die gewonnenen Statistiken können wir unser Angebot
                    verbessern und für Sie als Nutzer interessanter
                    ausgestalten. Diese Website verwendet Google Analytics zudem
                    für eine geräteübergreifende Analyse von Besucherströmen,
                    die über eine User-ID durchgeführt wird. Sofern Sie über ein
                    Google-Benutzerkonto verfügen, können Sie in den dortigen
                    Einstellungen unter &laquo;Meine Daten&raquo;,
                    &laquo;persönliche Daten&raquo; die geräteübergreifende
                    Analyse Ihrer Nutzung deaktivieren.
                </p>
                <p>
                    Rechtsgrundlage für die Nutzung von Google Analytics ist
                    Art. 6 Abs. 1 S. 1 lit. f DS-GVO. Die im Rahmen von Google
                    Analytics von Ihrem Browser übermittelte IP-Adresse wird
                    nicht mit anderen Daten von Google zusammengeführt. Wir
                    weisen Sie darauf hin, dass auf dieser Website Google
                    Analytics um den Code &laquo;_anonymizeIp();&raquo;
                    erweitert wurde, um eine anonymisierte Erfassung von
                    IP-Adressen zu gewährleisten. Dadurch werden IP-Adressen
                    gekürzt weiterverarbeitet, eine Personenbeziehbarkeit kann
                    damit ausgeschlossen werden. Soweit den über Sie erhobenen
                    Daten ein Personenbezug zukommt, wird dieser also sofort
                    ausgeschlossen und die personenbezogenen Daten damit
                    umgehend gelöscht.
                </p>
                <p>
                    Nur in Ausnahmefällen wird die volle IP-Adresse an einen
                    Server von Google in den USA übertragen und dort gekürzt. Im
                    Auftrag des Betreibers dieser Website wird Google diese
                    Informationen benutzen, um Ihre Nutzung der Website
                    auszuwerten, um Reports über die Websitenaktivitäten
                    zusammenzustellen und um weitere mit der Websitennutzung und
                    der Internetnutzung verbundene Dienstleistungen gegenüber
                    dem Websitenbetreiber zu erbringen. Für die Ausnahmefälle,
                    in denen personenbezogene Daten in die USA übertragen
                    werden, hat sich Google dem EU-US Privacy Shield
                    unterworfen,
                    <a
                        href="https://www.privacyshield.gov/EU-US-Framework"
                        target="_blank"
                        rel="noopener"
                        >https://www.privacyshield.gov/EU-US-Framework</a
                    >.
                </p>
                <p>
                    Google Analytics verwendet Cookies. Die durch den Cookie
                    erzeugten Informationen über Ihre Benutzung dieser Website
                    werden in der Regel an einen Server von Google in den USA
                    übertragen und dort gespeichert. Sie können die Speicherung
                    der Cookies durch eine entsprechende Einstellung Ihrer
                    Browser-Software verhindern; wir weisen Sie jedoch darauf
                    hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
                    Funktionen dieser Website vollumfänglich werden nutzen
                    können. Sie können darüber hinaus die Erfassung der durch
                    das Cookie erzeugten und auf Ihre Nutzung der Website
                    bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die
                    Verarbeitung dieser Daten durch Google verhindern, indem sie
                    das unter dem folgenden Link verfügbare Browser-Plugin
                    herunterladen und installieren:
                    <a
                        href="https://www.swissanwalt.ch/de/gaoptout.aspx"
                        target="_blank"
                        rel="noopener"
                        >Google Analytics deaktivieren</a
                    >.
                </p>
                <p>
                    Ausserdem können Sie die Nutzung von Google Analytics auch
                    verhindern, indem sie auf diesen Link klicken:
                    <a
                        href="https://tools.google.com/dlpage/gaoptout?hl=de"
                        target="_blank"
                        rel="noopener"
                        >Google Analytics deaktivieren</a
                    >. Hierdurch wird ein sog. opt-out Cookie auf ihrem
                    Datenträger gespeichert, der die Verarbeitung
                    personenbezogener Daten durch Google Analytics verhindert.
                    Bitte beachten Sie, dass bei einem Löschen sämtlicher
                    Cookies auf Ihrem Endgerät auch diese Opt-out-Cookies
                    gelöscht werden, d.h., dass Sie erneut die Opt-out-Cookies
                    setzen müssen, wenn Sie weiterhin diese Form der
                    Datenerhebung verhindern wollen. Die Opt-out-Cookies sind
                    pro Browser und Rechner/Endgerät gesetzt und müssen daher
                    für jeden Browser, Rechner oder anderes Endgerät gesondert
                    aktiviert werden.
                </p>
                <p>&nbsp;</p>
                <h2>Datenschutzerkl&auml;rung f&uuml;r Facebook</h2>
                <p>
                    Diese Website verwendet Funktionen von Facebook Inc., 1601
                    S. California Ave, Palo Alto, CA 94304, USA . Bei Aufruf
                    unserer Seiten mit Facebook-Plug-Ins wird eine Verbindung
                    zwischen Ihrem Browser und den Servern von Facebook
                    aufgebaut. Dabei werden bereits Daten an Facebook
                    &uuml;bertragen. Besitzen Sie einen Facebook-Account,
                    k&ouml;nnen diese Daten damit verkn&uuml;pft werden. Wenn
                    Sie keine Zuordnung dieser Daten zu Ihrem Facebook-Account
                    w&uuml;nschen, loggen Sie sich bitte vor dem Besuch unserer
                    Seite bei Facebook aus. Interaktionen, insbesondere das
                    Nutzen einer Kommentarfunktion oder das Anklicken eines
                    „Like“- oder „Teilen“-Buttons werden ebenfalls an Facebook
                    weitergegeben. Mehr erfahren Sie unter
                    <a
                        href="https://de-de.facebook.com/about/privacy"
                        target="_blank"
                        style="color: inherit;"
                        >https://de-de.facebook.com/about/privacy</a
                    >.
                </p>
                <p>&nbsp;</p>
                <h2>Datenschutzerkl&auml;rung f&uuml;r Twitter</h2>
                <p>
                    Diese Website verwendet Funktionen von Twitter, Inc., 1355
                    Market St, Suite 900, San Francisco, CA 94103, USA. Bei
                    Aufruf unserer Seiten mit Twitter-Plug-Ins wird eine
                    Verbindung zwischen Ihrem Browser und den Servern von
                    Twitter aufgebaut. Dabei werden bereits Daten an Twitter
                    &uuml;bertragen. Besitzen Sie einen Twitter-Account,
                    k&ouml;nnen diese Daten damit verkn&uuml;pft werden. Wenn
                    Sie keine Zuordnung dieser Daten zu Ihrem Twitter-Account
                    wünschen, loggen Sie sich bitte vor dem Besuch unserer Seite
                    bei Twitter aus. Interaktionen, insbesondere das Anklicken
                    eines „Re-Tweet“-Buttons werden ebenfalls an Twitter
                    weitergegeben. Mehr erfahren Sie unter
                    <a
                        href="https://twitter.com/privacy"
                        target="_blank"
                        style="color: inherit;"
                        >https://twitter.com/privacy</a
                    >.
                </p>
                <p>&nbsp;</p>
                <h2>Datenschutzerkl&auml;rung f&uuml;r Instagram</h2>
                <p>
                    Auf unserer Website sind Funktionen des Dienstes Instagram
                    eingebunden. Diese Funktionen werden angeboten durch die
                    Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA
                    integriert. Wenn Sie in Ihrem Instagram-Account eingeloggt
                    sind k&ouml;nnen Sie durch Anklicken des Instagram-Buttons
                    die Inhalte unserer Seiten mit Ihrem Instagram-Profil
                    verlinken. Dadurch kann Instagram den Besuch unserer Seiten
                    Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass
                    wir als Anbieter der Seiten keine Kenntnis vom Inhalt der
                    &uuml;bermittelten Daten sowie deren Nutzung durch Instagram
                    erhalten.
                </p>
                <p>
                    Weitere Informationen hierzu finden Sie in der
                    Datenschutzerkl&auml;rung von Instagram:
                    <a
                        href="https://instagram.com/about/legal/privacy/"
                        target="_blank"
                        style="color: inherit;"
                        >http://instagram.com/about/legal/privacy/</a
                    >
                </p>
                <p>&nbsp;</p>
                <h2>Datenschutzerkl&auml;rung f&uuml;r LinkedIn</h2>
                <p>
                    Diese Website nutzt Funktionen des Netzwerks LinkedIn.
                    Anbieter ist die LinkedIn Corporation, 2029 Stierlin Court,
                    Mountain View, CA 94043, USA. Bei jedem Abruf einer unserer
                    Seiten, die Funktionen von LinkedIn enth&auml;lt, wird eine
                    Verbindung zu Servern von LinkedIn aufbaut. LinkedIn wird
                    dar&uuml;ber informiert, dass Sie unsere Internetseiten mit
                    Ihrer IP-Adresse besucht haben. Wenn Sie den
                    "Recommend-Button" von LinkedIn anklicken und in Ihrem
                    Account bei LinkedIn eingeloggt sind, ist es LinkedIn
                    m&ouml;glich, Ihren Besuch auf unserer Internetseite Ihnen
                    und Ihrem Benutzerkonto zuzuordnen. Wir weisen darauf hin,
                    dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt
                    der &uuml;bermittelten Daten sowie deren Nutzung durch
                    LinkedIn haben.
                </p>
                <p>
                    Weitere Informationen hierzu finden Sie in der
                    Datenschutzerkl&auml;rung von LinkedIn unter:
                    <a
                        href="https://www.linkedin.com/legal/privacy-policy"
                        target="_blank"
                        style="color: inherit;"
                        >https://www.linkedin.com/legal/privacy-policy</a
                    >
                </p>
                <p>&nbsp;</p>
                <h2>Datenschutzerkl&auml;rung f&uuml;r Pinterest</h2>
                <p>
                    Auf dieser Website verwenden wir Social Plugins des sozialen
                    Netzwerkes Pinterest, das von der Pinterest Inc., 808
                    Brannan Street San Francisco, CA 94103-490, USA
                    ("Pinterest") betrieben wird. Wenn Sie eine Seite aufrufen,
                    die ein solches Plugin enth&auml;lt, stellt Ihr Browser eine
                    direkte Verbindung zu den Servern von Pinterest her. Das
                    Plugin &uuml;bermittelt dabei Protokolldaten an den Server
                    von Pinterest in die USA. Diese Protokolldaten enthalten
                    m&ouml;glicherweise Ihre IP-Adresse, die Adresse der
                    besuchten Websites, die ebenfalls Pinterest- Funktionen
                    enthalten, Art und Einstellungen des Browsers, Datum und
                    Zeitpunkt der Anfrage, Ihre Verwendungsweise von Pinterest
                    sowie Cookies.
                </p>
                <p>
                    Weitere Informationen zu Zweck, Umfang und weiterer
                    Verarbeitung und Nutzung der Daten durch Pinterest sowie
                    Ihre diesbez&uuml;glichen Rechte und M&ouml;glichkeiten zum
                    Schutz Ihrer Privatsph&auml;re finden Sie in den den
                    Datenschutzhinweisen von Pinterest:
                    <a
                        href="https://about.pinterest.com/de/privacy-policy"
                        target="_blank"
                        style="color: inherit;"
                        >https://about.pinterest.com/de/privacy-policy</a
                    >
                </p>
                <p>&nbsp;</p>
                <h2>Datenschutzerkl&auml;rung f&uuml;r SoundCloud</h2>
                <p>
                    Auf dieser Website k&ouml;nnen Plugins des sozialen
                    Netzwerks SoundCloud (SoundCloud Limited, Berners House,
                    47-48 Berners Street, London W1T 3NF, Gro&szlig;britannien.)
                    integriert sein. Die SoundCloud-Plugins erkennen Sie an dem
                    SoundCloud-Logo auf den betroffenen Seiten.
                </p>
                <p>
                    Wenn Sie unsere Seiten besuchen, wird nach Aktivierung des
                    Plugin eine direkte Verbindung zwischen Ihrem Browser und
                    dem SoundCloud-Server hergestellt. SoundCloud erh&auml;lt
                    dadurch die Information, dass Sie mit Ihrer IP-Adresse
                    unsere Seite besucht haben. Wenn Sie den
                    &quot;Like-Button&quot; oder &quot;Share- Button&quot;
                    anklicken w&auml;hrend Sie in Ihrem SoundCloud-
                    Benutzerkonto eingeloggt sind, k&ouml;nnen Sie die Inhalte
                    unserer Seiten mit Ihrem SoundCloud-Profil verlinken
                    und/oder teilen. Dadurch kann SoundCloud Ihrem Benutzerkonto
                    den Besuch unserer Seiten zuordnen. Wir weisen darauf hin,
                    dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt
                    der &uuml;bermittelten Daten sowie deren Nutzung durch
                    SoundCloud erhalten. Weitere Informationen hierzu finden Sie
                    in der Datenschutzerkl&auml;rung von SoundCloud unter:
                    <a
                        href="https://soundcloud.com/pages/privacy"
                        target="_blank"
                        style="color: inherit;"
                        >https://soundcloud.com/pages/privacy</a
                    >
                </p>
                <p>
                    Wenn Sie nicht w&uuml;nschen, dass Soundcloud den Besuch
                    unserer Seiten Ihrem SoundCloud- Benutzerkonto zuordnet,
                    loggen Sie sich bitte aus Ihrem SoundCloud-Benutzerkonto aus
                    bevor Sie Inhalte des SoundCloud-Plugins aktivieren.
                </p>
                <p>&nbsp;</p>
                <h2>Datenschutzerkl&auml;rung f&uuml;r Tumblr</h2>
                <p>
                    Diese Website nutzen Schaltfl&auml;chen des Dienstes Tumblr.
                    Anbieter ist die Tumblr, Inc., 35 East 21st St, 10th Floor,
                    New York, NY 10010, USA. Diese Schaltfl&auml;chen
                    erm&ouml;glichen es Ihnen, einen Beitrag oder eine Seite bei
                    Tumblr zu teilen oder dem Anbieter bei Tumblr zu folgen.
                    Wenn Sie eine unserer Websiten mit Tumblr-Button aufrufen,
                    baut der Browser eine direkte Verbindung mit den Servern von
                    Tumblr auf. Wir haben keinen Einfluss auf den Umfang der
                    Daten, die Tumblr mit Hilfe dieses Plugins erhebt und
                    &uuml;bermittelt. Nach aktuellem Stand werden die IP-Adresse
                    des Nutzers sowie die URL der jeweiligen Website
                    &uuml;bermittelt.
                </p>
                <p>
                    Weitere Informationen hierzu finden sich in der
                    Datenschutzerkl&auml;rung von Tumblr unter:
                    <a
                        href="http://www.tumblr.com/policy/de/privacy"
                        target="_blank"
                        style="color: inherit;"
                        >https://www.tumblr.com/policy/de/privacy</a
                    >.
                </p>
                <p>&nbsp;</p>
                <h2>Externe Zahlungsdienstleister</h2>
                <p>
                    Diese Website setzt externe Zahlungsdienstleister ein,
                    &uuml;ber deren Plattformen die Nutzer und wir
                    Zahlungstransaktionen vornehmen k&ouml;nnen. Zum Beispiel
                    &uuml;ber
                </p>
                <ul>
                    <li>
                        PostFinance
                        (https://www.postfinance.ch/de/detail/rechtliches-barrierefreiheit.html)
                    </li>
                    <li>
                        Visa
                        (https://www.visa.de/nutzungsbedingungen/visa-privacy-center.html)
                    </li>
                    <li>
                        Mastercard
                        (https://www.mastercard.ch/de-ch/datenschutz.html)
                    </li>
                    <li>
                        American Express
                        (https://www.americanexpress.com/de/content/privacy-policy-statement.html)
                    </li>
                    <li>
                        Paypal
                        (https://www.paypal.com/de/webapps/mpp/ua/privacy-full)
                    </li>
                    <li>Bexio AG (https://www.bexio.com/de-CH/datenschutz)</li>
                    <li>
                        Payrexx AG
                        (https://www.payrexx.ch/site/assets/files/2592/datenschutzerklaerung.pdf)
                    </li>
                    <li>
                        Apple Pay (https://support.apple.com/de-ch/ht203027)
                    </li>
                    <li>Stripe (https://stripe.com/ch/privacy)</li>
                    <li>Klarna (https://www.klarna.com/de/datenschutz/)</li>
                    <li>
                        Skrill
                        (https://www.skrill.com/de/fusszeile/datenschutzrichtlinie/)
                    </li>
                    <li>
                        Giropay
                        (https://www.giropay.de/rechtliches/datenschutz-agb/)
                        etc.
                    </li>
                </ul>
                <p>
                    Im Rahmen der Erf&uuml;llung von Vertr&auml;gen setzen wir
                    die Zahlungsdienstleister auf Grundlage der schweizerischen
                    Datenschutzverordnung sowie und soweit n&ouml;tig, des Art.
                    6 Abs. 1 lit. b. EU-DSGVO ein. Im &Uuml;brigen setzen wir
                    externe Zahlungsdienstleister auf Grundlage unserer
                    berechtigten Interessen gem. schweizerischer
                    Datenschutzverordnung sowie und soweit n&ouml;tig, gem. Art.
                    6 Abs. 1 lit. f. EU-DSGVO ein, um unseren Nutzern effektive
                    und sichere Zahlungsm&ouml;glichkeit zu bieten.
                </p>
                <p>
                    Zu den durch die Zahlungsdienstleister verarbeiteten Daten
                    geh&ouml;ren Bestandsdaten, wie z.B. der Name und die
                    Adresse, Bankdaten, wie u.a. Kontonummern oder
                    Kreditkartennummern, Passw&ouml;rter, TANs und
                    Pr&uuml;fsummen sowie die Vertrags-, Summen und
                    empf&auml;ngerbezogenen Angaben. Die Angaben sind
                    erforderlich, um die Transaktionen durchzuf&uuml;hren. Die
                    eingegebenen Daten werden jedoch nur durch die
                    Zahlungsdienstleister verarbeitet und bei diesen
                    gespeichert. Wir als Betreiber erhalten keinerlei
                    Informationen zu (Bank-) Konto oder Kreditkarte, sondern
                    lediglich Informationen zur Best&auml;tigung (Annahme) oder
                    Ablehnung der Zahlung. Unter Umst&auml;nden werden die Daten
                    seitens der Zahlungsdienstleister an Wirtschaftsauskunfteien
                    &uuml;bermittelt. Diese &Uuml;bermittlung bezweckt die
                    Identit&auml;ts- und Bonit&auml;tspr&uuml;fung. Hierzu
                    verweisen wir auf die AGB und Datenschutzhinweise der
                    Zahlungsdienstleister.
                </p>
                <p>
                    F&uuml;r die Zahlungsgesch&auml;fte gelten die
                    Gesch&auml;ftsbedingungen und die Datenschutzhinweise der
                    jeweiligen Zahlungsdienstleister, welche innerhalb der
                    jeweiligen Website, bzw. Transaktionsapplikationen abrufbar
                    sind. Wir verweisen auf diese ebenfalls zwecks weiterer
                    Informationen und Geltendmachung von Widerrufs-, Auskunfts-
                    und anderen Betroffenenrechten.
                </p>
                <p>&nbsp;</p>
                <h2>Datenschutzerkl&auml;rung f&uuml;r YouTube</h2>
                <p>
                    Auf dieser Website sind Funktionen des Dienstes
                    &laquo;YouTube&raquo; eingebunden. &laquo;YouTube&raquo;
                    geh&ouml;rt der Google Ireland Limited, einer nach irischem
                    Recht eingetragenen und betriebenen Gesellschaft mit Sitz in
                    Gordon House, Barrow Street, Dublin 4, Irland, welche die
                    Dienste im Europ&auml;ischen Wirtschaftsraum und der Schweiz
                    betreibt.
                </p>
                <p>
                    Ihre rechtliche Vereinbarung mit &laquo;YouTube&raquo;
                    besteht aus den unter folgendem Link zu entnehmenden
                    Bestimmungen und Bedingungen:
                    <a
                        href="https://www.youtube.com/static?gl=DE&template=terms&hl=de"
                        target="_blank"
                        rel="noopener"
                        >https://www.youtube.com/static?gl=de&template=terms&hl=de</a
                    >. Diese Bestimmungen bilden eine rechtlich bindende
                    Vereinbarung zwischen Ihnen und &laquo;YouTube&raquo;
                    bez&uuml;glich der Nutzung der Dienste. In der
                    Datenschutzerkl&auml;rung von Google wird erl&auml;utert,
                    wie &laquo;YouTube&raquo; mit Ihren personenbezogenen Daten
                    verf&auml;hrt und Ihre Daten sch&uuml;tzt, wenn Sie den
                    Dienst nutzen.
                </p>
                <p>&nbsp;</p>
                <h2>Datenschutzerkl&auml;rung f&uuml;r Vimeo</h2>
                <p>
                    Auf dieser Website sind Plugins des Videoportals Vimeo der
                    Vimeo, LLC, 555 West 18th Street, New York, New York 10011,
                    USA eingebunden. Bei jedem Aufruf einer Seite, die ein oder
                    mehrere Vimeo-Videoclips anbietet, wird eine direkte
                    Verbindung zwischen Ihrem Browser und einem Server von Vimeo
                    in den USA hergestellt. Dabei werden Informationen &uuml;ber
                    Ihren Besuch und Ihre IP-Adresse dort gespeichert. Durch
                    Interaktionen mit den Vimeo Plugins (z.B. Klicken des
                    Start-Buttons) werden diese Informationen ebenfalls an Vimeo
                    &uuml;bermittelt und dort gespeichert. Die
                    Datenschutzerkl&auml;rung f&uuml;r Vimeo mit n&auml;heren
                    Informationen zur Erhebung und Nutzung Ihrer Daten durch
                    Vimeo finden Sie in der
                    <a href="http://vimeo.com/privacy"
                        >Datenschutzerkl&auml;rung von Vimeo</a
                    >.
                </p>
                <p>
                    Wenn Sie ein Vimeo-Benutzerkonto haben und nicht
                    m&ouml;chten, dass Vimeo &uuml;ber diese Website Daten
                    &uuml;ber Sie sammelt und mit Ihren bei Vimeo gespeicherten
                    Mitgliedsdaten verkn&uuml;pft, m&uuml;ssen Sie sich vor
                    Ihrem Besuch dieser Website bei Vimeo ausloggen.
                </p>
                <p>
                    Ausserdem ruft Vimeo &uuml;ber einen iFrame, in dem das
                    Video aufgerufen wird, den Tracker Google Analytics auf.
                    Dabei handelt es sich um ein eigenes Tracking von Vimeo, auf
                    das wir keinen Zugriff haben. Sie k&ouml;nnen das Tracking
                    durch Google Analytics unterbinden, indem Sie die
                    Deaktivierungs-Tools einsetzen, die Google f&uuml;r einige
                    Internet-Browser anbietet. Sie k&ouml;nnen dar&uuml;ber
                    hinaus die Erfassung der durch Google Analytics erzeugten
                    und auf ihre Nutzung der Website bezogenen Daten (inkl.
                    Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser
                    Daten durch Google verhindern, indem Sie das unter dem
                    folgenden Link verf&uuml;gbare Browser-Plugin herunterladen
                    und installieren:
                </p>
                <p>
                    <a
                        href="https://tools.google.com/dlpage/gaoptout?hl=de"
                        target="_blank"
                        rel="noopener"
                        >https://tools.google.com/dlpage/gaoptout?hl=de</a
                    >
                </p>
                <p>&nbsp;</p>
                <h2>Bestellabwicklung im Online-Shop mit Kundenkonto</h2>
                <p>
                    Wir verarbeiten die Daten unserer Kunden gem. den
                    datenschutzrechtlichen Bestimmungen des Bundes
                    (Datenschutzgesetz, DSG) und der EU-DSGVO, im Rahmen der
                    Bestellvorg&auml;nge in unserem Onlineshop, um ihnen die
                    Auswahl und die Bestellung der gew&auml;hlten Produkte und
                    Leistungen, sowie deren Bezahlung und Zustellung, bzw.
                    Ausf&uuml;hrung zu erm&ouml;glichen.
                </p>
                <p>
                    Zu den verarbeiteten Daten geh&ouml;ren Stammdaten
                    (Bestandsdaten), Kommunikationsdaten, Vertragsdaten,
                    Zahlungsdaten und zu den von der Verarbeitung betroffenen
                    Personen geh&ouml;ren unsere Kunden, Interessenten und
                    sonstige Gesch&auml;ftspartner. Die Verarbeitung erfolgt zum
                    Zweck der Erbringung von Vertragsleistungen im Rahmen des
                    Betriebs eines Onlineshops, Abrechnung, Auslieferung und der
                    Kundenservices. Hierbei setzen wir Session Cookies, z.B.
                    f&uuml;r die Speicherung des Warenkorb-Inhalts und
                    permanente Cookies, z.B. f&uuml;r die Speicherung des
                    Login-Status ein.
                </p>
                <p>
                    Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1
                    lit. b (Durchf&uuml;hrung Bestellvorg&auml;nge) und c
                    (Gesetzlich erforderliche Archivierung) DSGVO. Dabei sind
                    die als erforderlich gekennzeichneten Angaben zur
                    Begr&uuml;ndung und Erf&uuml;llung des Vertrages
                    erforderlich. Die Daten offenbaren wir gegen&uuml;ber
                    Dritten nur im Rahmen der Auslieferung, Zahlung oder im
                    Rahmen der gesetzlichen Erlaubnisse und Pflichten. Die Daten
                    werden in Drittl&auml;ndern nur dann verarbeitet, wenn dies
                    zur Vertragserf&uuml;llung erforderlich ist (z.B. auf
                    Kundenwunsch bei Auslieferung oder Zahlung).
                </p>
                <p>
                    Nutzer k&ouml;nnen optional ein Nutzerkonto anlegen, indem
                    sie insbesondere ihre Bestellungen einsehen k&ouml;nnen. Im
                    Rahmen der Registrierung, werden die erforderlichen
                    Pflichtangaben den Nutzern mitgeteilt. Die Nutzerkonten sind
                    nicht &ouml;ffentlich und k&ouml;nnen von Suchmaschinen,
                    z.B. Google, nicht indexiert werden. Wenn Nutzer ihr
                    Nutzerkonto gek&uuml;ndigt haben, werden deren Daten im
                    Hinblick auf das Nutzerkonto gel&ouml;scht, vorbehaltlich
                    deren Aufbewahrung ist aus handels- oder steuerrechtlichen
                    Gr&uuml;nden entspr. Art. 6 Abs. 1 lit. c DSGVO notwendig.
                    Angaben im Kundenkonto verbleiben bis zu deren L&ouml;schung
                    mit anschliessender Archivierung im Fall einer rechtlichen
                    Verpflichtung. Es obliegt den Nutzern, ihre Daten bei
                    erfolgter K&uuml;ndigung vor dem Vertragsende zu sichern.
                </p>
                <p>
                    Im Rahmen der Registrierung und erneuter Anmeldungen sowie
                    Inanspruchnahme unserer Onlinedienste, speichern wir die
                    IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung.
                    Die Speicherung erfolgt auf Grundlage unserer berechtigten
                    Interessen, als auch der Nutzer an Schutz vor Missbrauch und
                    sonstiger unbefugter Nutzung. Eine Weitergabe dieser Daten
                    an Dritte erfolgt grunds&auml;tzlich nicht, ausser sie ist
                    zur Verfolgung unserer Anspr&uuml;che erforderlich oder es
                    besteht hierzu eine gesetzliche Verpflichtung gem. Art. 6
                    Abs. 1 lit. c DSGVO.
                </p>
                <p>
                    Die L&ouml;schung erfolgt nach Ablauf gesetzlicher
                    Gew&auml;hrleistungs- und vergleichbarer Pflichten, die
                    Erforderlichkeit der Aufbewahrung der Daten wird in
                    unregelm&auml;ssigen Abst&auml;nden &uuml;berpr&uuml;ft. Im
                    Fall der gesetzlichen Archivierungspflichten erfolgt die
                    L&ouml;schung nach deren Ablauf.
                </p>
                <p>&nbsp;</p>
                <h2>
                    Hinweis betreffend Daten&uuml;bermittlungen in die USA
                    (Vereinigte Staaten von Amerika)
                </h2>
                <p>
                    Aus Gr&uuml;nden der Vollst&auml;ndigkeit weisen wir Sie
                    darauf hin, dass f&uuml;r Nutzer mit Sitz in der Schweiz
                    &Uuml;berwachungsmassnahmen von US-Beh&ouml;rden bestehen,
                    welche generell die Speicherung aller personenbezogenen
                    Daten aus der Schweiz - welche in die USA &uuml;bermittelt
                    wurden - erm&ouml;glicht.
                </p>
                <p>
                    Dies geschieht ohne Differenzierung, Einschr&auml;nkung oder
                    Ausnahme anhand der verfolgten Ziele und ohne ein objektives
                    Kriterium, das es erm&ouml;glicht, den Zugang der
                    US-Beh&ouml;rden<!--USA-->
                    zu den Daten und deren sp&auml;tere Nutzung auf ganz
                    bestimmte, strikt begrenzte Zwecke zu beschr&auml;nken, die
                    mit dem Zugang zu diesen Daten als auch mit deren Nutzung
                    verbundenen Eingriffe zu rechtfertigen verm&ouml;gen.
                    Ausserdem weisen wir Sie darauf hin, dass in den USA
                    f&uuml;r die betroffenen Personen aus der Schweiz, keine
                    Rechtsbehelfe vorliegen, die es erlauben w&uuml;rden, Zugang
                    zu den Sie betreffenden Daten zu erhalten und deren
                    Berichtigung oder L&ouml;schung zu erwirken, resp. kein
                    wirksamer gerichtlicher Rechtsschutz gegen generelle
                    Zugriffsrechte von US-Beh&ouml;rden vorliegt. Wir weisen den
                    Betroffenen explizit auf diese Rechts- und Sachlage hin, um
                    eine entsprechend informierte Entscheidung zur Einwilligung
                    in die Verwendung seiner Daten zu treffen.
                </p>
                <p>
                    Nutzer mit Wohnsitz in einem Mitgliedstaat der EU<!--EU-->
                    weisen wir darauf hin, dass die USA aus Sicht der
                    Europ&auml;ischen Union nicht &uuml;ber ein ausreichendes
                    Datenschutzniveau verf&uuml;gt.
                </p>
                <p>&nbsp;</p>
                <h2>Urheberrechte</h2>
                <p>
                    Die Urheber- und alle anderen Rechte an Inhalten, Bildern,
                    Fotos oder anderen Dateien auf der Website, geh&ouml;ren
                    ausschliesslich dem Betreiber dieser Website oder den
                    speziell genannten Rechteinhabern. Für die Reproduktion von
                    sämtlichen Dateien, ist die schriftliche Zustimmung des
                    Urheberrechtstr&auml;gers im Voraus einzuholen.
                </p>
                <p>
                    Wer ohne Einwilligung des jeweiligen Rechteinhabers eine
                    Urheberrechtsverletzung begeht, kann sich strafbar und
                    allenfalls schadenersatzpflichtig machen.
                </p>
                <p>&nbsp;</p>
                <h2>Allgemeiner Haftungsausschluss</h2>
                <p>
                    Alle Angaben unseres Internetangebotes wurden
                    sorgf&auml;ltig gepr&uuml;ft. Wir bem&uuml;hen uns, unser
                    Informationsangebot aktuell, inhaltlich richtig und
                    vollst&auml;ndig anzubieten. Trotzdem kann das Auftreten von
                    Fehlern nicht v&ouml;llig ausgeschlossen werden, womit wir
                    keine Garantie f&uuml;r Vollst&auml;ndigkeit, Richtigkeit
                    und Aktualit&auml;t von Informationen auch
                    journalistisch-redaktioneller Art &uuml;bernehmen
                    k&ouml;nnen. Haftungsanspr&uuml;che aus Sch&auml;den
                    materieller oder ideeller Art, die durch die Nutzung der
                    angebotenen Informationen verursacht wurden, sind
                    ausgeschlossen, sofern kein nachweislich vors&auml;tzliches
                    oder grob fahrl&auml;ssiges Verschulden vorliegt.
                </p>
                <p>
                    Der Herausgeber kann nach eigenem Ermessen und ohne
                    Ank&uuml;ndigung Texte ver&auml;ndern oder l&ouml;schen und
                    ist nicht verpflichtet, Inhalte dieser Website zu
                    aktualisieren. Die Benutzung bzw. der Zugang zu dieser
                    Website geschieht auf eigene Gefahr des Besuchers. Der
                    Herausgeber, seine Auftraggeber oder Partner sind nicht
                    verantwortlich f&uuml;r Sch&auml;den, wie direkte,
                    indirekte, zuf&auml;llige, vorab konkret zu bestimmende oder
                    Folgesch&auml;den, die angeblich durch den Besuch dieser
                    Website entstanden sind und &uuml;bernehmen hierf&uuml;r
                    folglich keine Haftung.
                </p>
                <p>
                    Der Herausgeber &uuml;bernimmt ebenfalls keine Verantwortung
                    und Haftung f&uuml;r die Inhalte und die Verf&uuml;gbarkeit
                    von Website Dritter, die &uuml;ber externe Links dieser
                    Website erreichbar sind. F&uuml;r den Inhalt der verlinkten
                    Seiten sind ausschliesslich deren Betreiber verantwortlich.
                    Der Herausgeber distanziert sich damit ausdr&uuml;cklich von
                    allen Inhalten Dritter, die m&ouml;glicherweise straf- oder
                    haftungsrechtlich relevant sind oder gegen die guten Sitten
                    verstossen.
                </p>
                <p>&nbsp;</p>
                <h2>&Auml;nderungen</h2>
                <p>
                    Wir k&ouml;nnen diese Datenschutzerkl&auml;rung jederzeit
                    ohne Vorank&uuml;ndigung anpassen. Es gilt die jeweils
                    aktuelle, auf unserer Website publizierte Fassung. Soweit
                    die Datenschutzerkl&auml;rung Teil einer Vereinbarung mit
                    Ihnen ist, werden wir Sie im Falle einer Aktualisierung über
                    die &Auml;nderung per E-Mail oder auf andere geeignete Weise
                    informieren.
                </p>
                <p>&nbsp;</p>
                <h2>Fragen an den Datenschutzbeauftragten</h2>
                <p>
                    Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns
                    bitte eine E-Mail oder wenden Sie sich direkt an die
                    f&uuml;r den Datenschutz zu Beginn der
                    Datenschutzerkl&auml;rung aufgef&uuml;hrten,
                    verantwortlichen Person in unserer Organisation.
                </p>
                <p>&nbsp;</p>
                <p>
                    Küssnacht SZ / Schweiz, 14.04.2020<!--ACHTUNG: Wenn Sie die Quelle ohne Erlaubnis von SwissAnwalt entfernen, dann begehen Sie eine Urheberrechtsverletzung welche in jedem Fall unter Kostenfolge geahndet wird.--><br />Quelle:
                    <a
                        href="https://www.swissanwalt.ch"
                        target="_blank"
                        rel="noopener"
                        >SwissAnwalt</a
                    >
                </p>
                <!--Bitte beachten Sie die AGB von SwissAnwalt betreffend allfällig anfallenden Kosten bei Weglassen der Quelle!-->
            </div>
        </div>
    </div>
</div>
