import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Diliboard } from 'src/app/models/diliboard';
import { UserData } from 'src/app/models/user';
import { PinDialogComponent } from 'src/app/modules/dialogs';
import {
    selectIsLoggedIn,
    selectUser
} from '../../modules/user/store/user.selector';
import { ConfirmationPopUpComponent } from 'src/app/modules/shared-stuff/components/confirmation-pop-up/confirmation-pop-up.component';
import {
    DeleteBoard,
    DeleteBoardContent
} from 'src/app/modules/diliboard/store/diliboard.actions';
import {
    EditBoardComponent,
    EditContentComponent
} from 'src/app/modules/diliboard/components';
import { Router } from '@angular/router';

@Component({
    selector: 'app-board-menu',
    templateUrl: './board-menu.component.html',
    styleUrls: ['./board-menu.component.css']
})
export class BoardMenuComponent implements OnInit, OnDestroy {
    @Input('diliData')
    set diliData(data: Diliboard) {
        this.diliboard = JSON.parse(JSON.stringify(data));
    }
    @Input('showPin') showPin: boolean;
    diliboard: Diliboard;
    user: UserData;
    checkOwnBoard: boolean;
    socialParams: string;
    private isLoggedIn: boolean;
    private subscription: Subscription = new Subscription();

    constructor(
        private dialog: MatDialog,
        private store: Store<object>,
        private translate: TranslateService,
        private router: Router
    ) {}

    ngOnInit() {
        this.subscription.add(
            this.store.pipe(select(selectIsLoggedIn)).subscribe((loggedIn) => {
                this.isLoggedIn = loggedIn;
            })
        );
        this.subscription.add(
            this.store.pipe(select(selectUser)).subscribe((user) => {
                this.user = user;
            })
        );
        if (this.diliboard.content_type) {
            this.showPin = true;
            this.socialParams = `board/${this.diliboard.diliboard_id}?videoid=${this.diliboard.id}`;
        } else {
            this.socialParams = `board/${this.diliboard.id}`;
        }
        if (this.isLoggedIn) {
            if (this.diliboard.user_id === this.user.id) {
                this.checkOwnBoard = false;
            } else {
                this.checkOwnBoard = true;
            }
        } else {
            this.checkOwnBoard = true;
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    showShare(): void {
        this.diliboard.showBoardOptions = false;
    }

    showMenu(): void {
        this.diliboard.showBoardOptions = true;
    }

    hideMenuOrSocial(): void {
        this.diliboard.showBoardOptions = false;
    }

    pinVideo(): void {
        this.dialog.open(PinDialogComponent, {
            panelClass: 'standard-dialog',
            data: {
                video: this.diliboard
            }
        });
    }

    checkIfRouteIsMyClassroom(): boolean {
        return this.router.url.includes('my-diligrams');
    }

    delete(): void {
        if (this.diliboard.type) {
            this.deleteContent();
        } else {
            this.deleteDiligram();
        }
    }

    edit(): void {
        if (this.router.url.includes('my-diligrams')) {
            this.dialog.open(EditBoardComponent, {
                panelClass: 'edit-dialog',
                data: {
                    board: this.diliboard
                }
            });
        } else if (this.router.url.includes('board')) {
            this.dialog.open(EditContentComponent, {
                panelClass: 'edit-dialog',
                data: this.diliboard
            });
        }
    }

    deleteDiligram(): void {
        let message: string;

        let subscription = this.translate
            .get('diliboard.remove-diliboard', {
                name: this.diliboard.title
            })
            .subscribe((res) => (message = res));

        this.subscription.add(subscription);

        subscription = this.dialog
            .open(ConfirmationPopUpComponent, {
                width: '350px',
                data: {
                    message
                }
            })
            .afterClosed()
            .subscribe((res: boolean) => {
                if (res) {
                    this.store.dispatch(
                        new DeleteBoard({ boardId: this.diliboard.id })
                    );
                }
            });

        this.subscription.add(subscription);
    }

    deleteContent(): void {
        let message: string;

        let subscription = this.translate
            .get('diliboard.remove-diliboard', {
                name: this.diliboard.title
            })
            .subscribe((res) => (message = res));

        this.subscription.add(subscription);

        subscription = this.dialog
            .open(ConfirmationPopUpComponent, {
                width: '350px',
                data: {
                    message
                }
            })
            .afterClosed()
            .subscribe((res: boolean) => {
                if (res) {
                    this.store.dispatch(
                        new DeleteBoardContent({
                            boardId: this.diliboard.diliboard_id,
                            contentId: this.diliboard.id
                        })
                    );
                }
            });

        this.subscription.add(subscription);
    }
}
