export const textFiles: string[] = [
    '.doc',
    '.docx',
    '.log',
    '.msg',
    '.odt',
    '.pages',
    '.rtf',
    '.tex',
    '.txt',
    '.wpd',
    '.wps'
];

export const dataFiles: string[] = [
    '.csv',
    '.dat',
    '.ged',
    '.ley',
    '.leychain',
    '.pps',
    '.ppt',
    '.pptx',
    '.sdf',
    '.tar',
    '.tax2016',
    '.tax2018',
    '.vcf',
    '.xml'
];

export const rasterImagesFiles: string[] = [
    '.bmp',
    '.dds',
    '.gif',
    '.heic',
    '.jpg',
    '.png',
    '.psd',
    '.pspimage',
    '.tga',
    '.thm',
    '.tif',
    '.tiff',
    '.yuv'
];

export const vectorImagesFiles: string[] = ['.ai', '.eps', '.svg'];

export const pageLayoutFiles: string[] = ['.indd', '.pct', '.pdf'];

export const spreadsheetFiles: string[] = ['.xlr', '.xls', '.xlsx'];
