import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
    UserLogin,
    UserActionType,
    UserErrorOccurred,
    UserLoginSuccess,
    UserLoginFailed,
    UserGetData,
    UserGetDataSuccess,
    UserUpdateData,
    UserUpdateDataSuccess,
    UserLogout,
    UserRegisterFailed,
    UserRegister,
    UserRegisterSuccess,
    UserForgotPassword,
    UserForgotPasswordSuccess,
    UserForgotPasswordFailed,
    UserResetPassword,
    UserResetPasswordSuccess,
    UserResetPasswordFailed,
    UserUploadImage,
    UserUploadImageSuccess,
    UserUploadImageFailed,
    UserUpdateToken,
    UserGetToken
} from './user.actions';
import { UserService } from '../user.service';
import { Router } from '@angular/router';
import { AuthService } from 'angularx-social-login';
import { NotificationsService } from 'src/app/services';
import { CookiesService } from 'src/app/services/cookie.service';

@Injectable()
export class UserEffects {
    @Effect()
    login$: Observable<Action> = this.actions$.pipe(
        ofType<UserLogin>(UserActionType.LOGIN),
        mergeMap((action) => {
            return this.userService.login(action.payload.loginData).pipe(
                map((user) => {
                    this.cookieService.setCookie(
                        'socialLogin',
                        String(action.payload.socialLogin),
                        365
                    );
                    this.cookieService.setCookie(
                        'rememberLogin',
                        String(action.payload.rememberLogin),
                        365
                    );
                    this.cookieService.setCookie(
                        'token',
                        String(user['body'].authentication_token),
                        365
                    );
                    return new UserLoginSuccess({ userData: user['body'] });
                }),
                catchError((error) => {
                    return of(
                        new UserLoginFailed({
                            errorMessage: error
                        })
                    );
                })
            );
        })
    );
    @Effect()
    getUserData$: Observable<Action> = this.actions$.pipe(
        ofType<UserGetData>(UserActionType.GET_USER_DATA),
        mergeMap(() => {
            return this.userService.getUserData().pipe(
                map((data) => {
                    this.notificationsService.fetchNotifications();
                    return new UserGetDataSuccess({
                        userData: data.user
                    });
                }),
                catchError((error) => {
                    return of(
                        new UserErrorOccurred({
                            method: 'GET_USER_DATA',
                            error: error
                        })
                    );
                })
            );
        })
    );
    @Effect()
    updateUserData$: Observable<Action> = this.actions$.pipe(
        ofType<UserUpdateData>(UserActionType.UPDATE_USER_DATA),
        mergeMap((action) => {
            return this.userService.updateUser(action.payload.userData).pipe(
                map(() => {
                    return new UserUpdateDataSuccess({
                        updateData: action.payload.userData
                    });
                }),
                catchError((error) => {
                    return of(
                        new UserErrorOccurred({
                            method: 'UPDATE_USER_DATA',
                            error: error
                        })
                    );
                })
            );
        })
    );
    @Effect({ dispatch: false })
    logout$: Observable<void> = this.actions$.pipe(
        ofType<UserLogout>(UserActionType.LOGOUT),
        map((action) => {
            this.cookieService.deleteCookie('token');
            this.cookieService.deleteCookie('socialLogin');
            this.cookieService.deleteCookie('rememberLogin');
            if (action.payload.socialLogin) {
                this.socialAuthService.signOut();
            }
            this.router.navigate(['home']);
        })
    );
    @Effect()
    updateToken$: Observable<UserUpdateToken> = this.actions$.pipe(
        ofType<UserGetToken>(UserActionType.GET_USER_TOKEN),
        map(() => {
            return new UserUpdateToken({
                token: this.cookieService.getCookie('token'),
                socialLogin: this.cookieService.getCookie('socialLogin'),
                rememberLogin: this.cookieService.getCookie('rememberLogin')
            });
        })
    );

    @Effect()
    register$: Observable<Action> = this.actions$.pipe(
        ofType<UserRegister>(UserActionType.REGISTER),
        mergeMap((action) => {
            return this.userService.register(action.payload.registerData).pipe(
                map(() => {
                    return new UserRegisterSuccess();
                }),
                catchError((error) => {
                    return of(
                        new UserRegisterFailed({
                            errorMessage: error
                        })
                    );
                })
            );
        })
    );
    @Effect()
    forgotPassword$: Observable<Action> = this.actions$.pipe(
        ofType<UserForgotPassword>(UserActionType.FORGOT_PASSWORD),
        mergeMap((action) => {
            return this.userService.forgotPassword(action.payload.email).pipe(
                map(() => {
                    return new UserForgotPasswordSuccess();
                }),
                catchError((error) => {
                    return of(
                        new UserForgotPasswordFailed({
                            errorMessage: error
                        })
                    );
                })
            );
        })
    );
    @Effect()
    resetPassword$: Observable<Action> = this.actions$.pipe(
        ofType<UserResetPassword>(UserActionType.RESET_PASSWORD),
        mergeMap((action) => {
            return this.userService
                .resetPassword(
                    action.payload.resetToken,
                    action.payload.newPassword
                )
                .pipe(
                    map(() => {
                        return new UserResetPasswordSuccess();
                    }),
                    catchError((error) => {
                        return of(
                            new UserResetPasswordFailed({
                                errorMessage: error
                            })
                        );
                    })
                );
        })
    );
    @Effect()
    uploadImage$: Observable<Action> = this.actions$.pipe(
        ofType<UserUploadImage>(UserActionType.UPLOAD_IMAGE),
        mergeMap((action) => {
            return this.userService
                .uploadUserImage(action.payload.userImage)
                .pipe(
                    map((response) => {
                        return new UserUploadImageSuccess({
                            userData: response.user
                        });
                    }),
                    catchError((error) => {
                        return of(
                            new UserUploadImageFailed({ errorMessage: error })
                        );
                    })
                );
        })
    );

    constructor(
        private readonly actions$: Actions,
        private userService: UserService,
        private socialAuthService: AuthService,
        private router: Router,
        private notificationsService: NotificationsService,
        private cookieService: CookiesService
    ) {}
}
