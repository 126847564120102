import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { Notification } from 'src/app//models/notification';
import { UserService } from '../modules/user/user.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    private notificationsSubject = new BehaviorSubject<Notification[]>([]);
    private notifications: Notification[] = [];
    isLoggedIn: boolean;

    constructor(
        private dataService: DataService,
        private userService: UserService
    ) {}

    getNotificationsSubject(): Subject<Notification[]> {
        return this.notificationsSubject;
    }

    getNotifications(): Notification[] {
        return this.notifications;
    }

    fetchNotifications() {
        const headers = this.userService.getHeaders();
        this.dataService
            .getData(`notifications.json`, { headers })
            .subscribe((notifications: Notification[]) => {
                this.notifications = notifications;
                this.notificationsSubject.next(this.notifications);
            });
    }

    fetchNewNotifications() {
        const headers = this.userService.getHeaders();
        this.dataService
            .getData(`notifications.json?unread=true&&limit=100`, { headers })
            .subscribe((notifications: Notification[]) => {
                this.notifications = this.notifications.reverse();

                notifications.forEach((notification: Notification) => {
                    const notificationExists: boolean =
                        this.notifications.filter(
                            (n: Notification) =>
                                n.notifiable_id === notification.notifiable_id
                        ).length > 0;

                    if (!notificationExists) {
                        this.notifications.push(notification);
                    }
                });

                this.notifications = this.notifications.reverse();
                this.notificationsSubject.next(this.notifications);

                setTimeout(() => this.fetchNewNotifications.bind(this), 5000);
            });
    }

    readNotification(notification) {
        const headers = this.userService.getHeaders();
        return this.dataService.putData(
            `notifications/${notification.id}.json`,
            null,
            {
                headers
            }
        );
    }
}
