<ul type="none" class="notifications-list">
    <li
        *ngFor="let notification of notifications; let i = index"
        class="item"
        [ngClass]="{
            new: !notification.read_at,
            invite: notification.notifiable_type === 'Invite',
            readed: notification.read_at
        }"
        (click)="navigateTo(notification.notifiable_type, notification.id)"
        [ngStyle]="{
            cursor:
                notification.notifiable_type !== 'invited'
                    ? 'pointer'
                    : 'not-allowed',
            display: notification.actor ? 'block' : 'none'
        }"
    >
        <div class="notification-content account-links">
            <img
                class="inline image"
                *ngIf="notification.actor && notification.actor.image_url_thumb"
                src="{{ notification.actor.image_url_thumb }}"
            />
            <img
                class="inline image"
                *ngIf="
                    !notification.actor || !notification.actor.image_url_thumb
                "
                src="/assets/img/icons/account_icon.svg"
            />
            <p
                class="inline name"
                *ngIf="notification.actor"
                [ngClass]="{
                    'justify-content-space-between':
                        notification.notifiable_type === 'Invite' &&
                        !notification.read_at
                }"
            >
                {{ notification.actor.first_name }}
                {{ notification.actor.last_name }}
                <span
                    *ngIf="
                        notification.notifiable_type === 'Invite' &&
                        !notification.read_at
                    "
                    class="action-icons"
                >
                    <i
                        class="fas fa-check-circle"
                        (click)="acceptInvite(notification, i)"
                    ></i>
                    <i
                        class="fas fa-times-circle"
                        (click)="rejectInvite(notification, i)"
                    ></i>
                </span>
                <img
                    class="isReaded"
                    src="/assets/img/icons/check_icon_light_gray.svg"
                    *ngIf="notification.read_at"
                />
            </p>
        </div>
        <p class="notification-message">{{ notification.message }}</p>
        <span class="timestamp">
            {{
                getTimeSinceRead(notification.created_at).value +
                    (getTimeSinceRead(notification.created_at).title
                        | translate)
            }}
        </span>
    </li>
    <li *ngIf="notifications && notifications.length === 0" class="item empty">
        {{ 'diliboard.notifications.no-notifications' | translate }}
    </li>
</ul>
