<div class="user-data-wrapper" [ngClass]="{ board: type === 'board' }">
    <div class="info-data collection" *ngIf="type === 'board'">
        <img class="count" src="/assets/img/icons/white-collection.svg" />
        <span class="count-description">{{ diliboardContentsCount }}</span>
    </div>
    <div class="info-data user">
        <img src="/assets/img/icons/white-user.svg" />
        <span> {{ user.first_name + ' ' + user.last_name }}</span>
    </div>
</div>
