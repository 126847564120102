<div class="overlay-action">
    <div class="overlay-options">
        <span
            class="action-wrapper"
            (click)="openDialog('edit')"
            *ngIf="checkUserRules((user$ | async).id)"
            ><img
                src="/assets/img/icons/edit.svg"
                alt="edit_icon"
                class="action-icon"
            />
            {{ 'modules.overlay.edit' | translate }}</span
        >
        <span
            class="action-wrapper"
            (click)="openDialog('delete')"
            *ngIf="checkUserRules((user$ | async).id)"
            ><img
                src="/assets/img/icons/delete.svg"
                alt="delete_icon"
                class="action-icon"
            />
            {{ 'modules.overlay.delete' | translate }}</span
        >
        <span
            class="action-wrapper"
            (click)="openDialog('download')"
            *ngIf="showDownload"
            ><img
                src="/assets/img/icons/download.svg"
                alt="download_icon"
                class="action-icon"
            />
            {{ 'modules.overlay.download' | translate }}</span
        >
    </div>
    <img
        src="/assets/img/icons/cancel_icon.svg"
        alt="cancel_icon"
        class="close-icon"
        (click)="closeDialog()"
    />
</div>
<div class="video-container" *ngIf="getView() === 'embedded'">
    <iframe
        *ngIf="video.content_type === 'video'"
        [src]="video.original_content_url | safe"
        allowfullscreen
        frameborder="0"
    ></iframe>
    <iframe
        *ngIf="video.content_type === 'iframe' && video.source === 'www'"
        [src]="video.original_content_url | safe"
        allowfullscreen
        frameborder="0"
    ></iframe>
</div>
<div class="imgur-wrapper" *ngIf="video.content_type === 'image'">
    <img
        [src]="video.original_content_url"
        [alt]="video.title"
        class="imgur-image"
    />
</div>
<div class="description-banner" *ngIf="getView() === 'descriptionWithLink'">
    <img
        src="/assets/img/diligram-banner.svg"
        alt="diligram-banner"
        class="diligram-banner"
    />
</div>
<div class="overlay-wrapper">
    <div
        class="video-title-wrapper"
        [ngClass]="{
            'description-with-link': getView() === 'descriptionWithLink'
        }"
    >
        <h1 class="video-title">
            {{ video.title }}
        </h1>
        <div
            class="source"
            *ngIf="video.source === 'youtube' && getView() === 'embedded'"
        >
            <div class="text">
                {{ 'modules.overlay.source' | translate }} <br />
                <span class="external-source">www.youtube.com</span>
            </div>
            <img src="/assets/img/icons/youtube-source.svg" alt="you" />
        </div>
    </div>
    <div
        class="video-description-container"
        [ngClass]="{
            'description-with-link': getView() === 'descriptionWithLink'
        }"
    >
        {{ video.description }}
    </div>
    <div
        class="link-section"
        *ngIf="
            getView() === 'descriptionWithLink' && video.content_type === 'link'
        "
    >
        <span class="url" (click)="openLink()">{{
            video.original_content_url
        }}</span>
        <img
            src="/assets/img/icons/copy.svg"
            alt="copy"
            class="copy-icon"
            (click)="copyUrl()"
        />
    </div>

    <div
        class="file-section"
        (click)="openDialog('download')"
        *ngIf="showDownload"
    >
        {{ video.filename }}
    </div>
</div>
