import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotificationCountService {
    constructor() {}

    public newNotificationCount: number;

    public onChangeNotificationCount = new Subject<number>();

    setNotificationCount(inputCount: number): void {
        this.newNotificationCount = inputCount;
        this.onChangeNotificationCount.next(inputCount);
    }

    getNotificationCount(): number {
        return this.newNotificationCount;
    }

    decrementNotificationCount(): void {
        if (this.newNotificationCount !== 0) {
            this.newNotificationCount--;
        }
    }
}
