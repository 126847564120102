import {
    Component,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
    OnInit
} from '@angular/core';
import { IDropDown } from 'src/app/models/dropdown.model';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-dropdown',
    template: `
        <ng-select
            class="{{ !!className ? className : '' }}"
            [items]="values"
            bindLabel="value"
            placeholder="{{ placeholder | translate }}"
            [searchable]="false"
            [clearable]="false"
            [multiple]="false"
            [closeOnSelect]="true"
            [(ngModel)]="selectedValue"
            (ngModelChange)="setValue($event)"
            [trackByFn]="getValueID"
        >
            <ng-template ng-label-tmp let-data="item">
                <span class="ng-value-label ng-star-inserted">
                    {{ data.translation | translate }}
                </span>
            </ng-template>

            <ng-template ng-option-tmp let-data="item">
                <span class="ng-option-label ng-star-inserted">
                    {{ data.translation | translate }}
                </span>
            </ng-template>
        </ng-select>
    `,
    styleUrls: ['./dropdown.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownComponent implements OnInit {
    @Input() values: IDropDown[];
    @Input() selectedValue: IDropDown;
    @Input() placeholder: string;
    @Input() className: string;
    @Output() emitValue$: EventEmitter<IDropDown> = new EventEmitter<
        IDropDown
    >();
    @Input() shownComponent: Observable<string>;

    ngOnInit(): void {
        this.shownComponent.subscribe((value) => {
            if (value === '') {
                this.className = 'home-language';
            } else if (value === 'login' || value === 'register') {
                this.className = 'home-language-white';
            }
        });
    }

    setValue(event: IDropDown): void {
        this.selectedValue = event;
        this.emitValue$.emit(event);
    }

    getValueID(event: IDropDown): string {
        return event.value;
    }
}
