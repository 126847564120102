<div>
    <img
        src="/assets/img/icons/cancel_icon.svg"
        alt="cancel_icon"
        class="close-icon"
        (click)="closeDialog()"
    />
    <h1 class="dialog-title">{{ 'modules.pin-dialog.title' | translate }}</h1>
    <mat-dialog-content>
        <iframe
            *ngIf="videoLink"
            [src]="videoLink | safe"
            allowfullscreen
            frameborder="0"
        ></iframe>
        <div class="form-selector no-shadow">
            <p
                class="choose-option"
                [ngClass]="{ 'no-bottom-radius': showOption }"
                (click)="toggleOptions()"
            >
                {{
                    !!selectedBoard
                        ? selectedBoard.title
                        : ('modules.pin-dialog.choose-diligram' | translate)
                }}
                <img
                    src="/assets/img/icons/chevron.svg"
                    class="chevron-normal"
                    [ngClass]="{ 'chevron-rotate': showOption }"
                />
            </p>
            <div
                class="choose-option-wrapper"
                *ngIf="showOption"
                [hidden]="mobile"
            >
                <div (click)="createDili()">
                    <p class="first-option">
                        {{ 'modules.pin-dialog.create-diligram' | translate }}
                    </p>
                </div>
                <div
                    *ngFor="let board of boards; let last = last"
                    (click)="setOption(board)"
                >
                    <p [ngClass]="{ 'last-option': last }">{{ board.title }}</p>
                </div>
            </div>

            <div
                class="choose-option-wrapper"
                *ngIf="showOption"
                [hidden]="!mobile"
            >
                <div
                    *ngFor="let board of boards; let last = last"
                    (click)="setOption(board)"
                >
                    <p [ngClass]="{ 'last-option': last }">{{ board.title }}</p>
                </div>
            </div>

            <button class="btn btn-primary" (click)="createDili()">
                {{ 'modules.pin-dialog.create-diligram' | translate }}
            </button>
        </div>
    </mat-dialog-content>
</div>
<mat-dialog-actions>
    <button class="btn btn-transparent" (click)="saveData()">
        {{ 'modules.pin-dialog.save' | translate }}
    </button>
    <!-- <button class="btn btn-transparent" (click)="createDili()">Create a new Diligram</button> -->
</mat-dialog-actions>
