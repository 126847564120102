<div class="container">
    <!-- <div class="text-center" *ngIf="!checkUser">
        <p>{{ 'modules.comments.invite-notification' | translate }}</p>
    </div> -->
    <div
        class="comment-wrapper"
        *ngIf="(comments$ | async) && !(commentsLoader$ | async)"
    >
        <div class="comment-title">
            <h1>
                <span class="bolded-title">{{
                    (comments$ | async).length
                }}</span>
                {{
                    (comments$ | async).length === 1
                        ? ('modules.comments.comment' | translate)
                        : ('modules.comments.comments' | translate)
                }}
            </h1>
        </div>
        <div class="comments">
            <div
                class="comment-per-user"
                *ngFor="let comment of comments$ | async"
            >
                <div class="comment-image">
                    <img
                        [src]="
                            comment.author.image_url_medium
                                ? comment.author.image_url_medium
                                : '/assets/img/icons/guest_icon-gray.svg'
                        "
                        class="profile-icon"
                    />
                </div>
                <div class="comment">
                    <p class="author">
                        {{
                            comment.author.first_name +
                                ' ' +
                                comment.author.last_name
                        }}
                    </p>
                    <p class="comment-text">{{ comment.text }}</p>
                    <span class="comment-updated">{{
                        comment.updated_string
                    }}</span>
                </div>
            </div>
        </div>
        <div class="text-area-wrapper">
            <p>{{ 'modules.comments.new-comment' | translate }}</p>
            <textarea
                [(ngModel)]="comment"
                placeholder="{{
                    'modules.comments.comment-placeholder' | translate
                }}"
                [disabled]="commentPostingLoader$ | async"
            ></textarea>
        </div>
        <button
            class="btn btn-primary center-btn"
            (click)="saveComment()"
            [disabled]="commentPostingLoader$ | async"
        >
            {{ 'modules.comments.send' | translate }}
        </button>
    </div>
    <app-data-loader *ngIf="commentsLoader$ | async"></app-data-loader>
</div>
