import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { Diliboard } from 'src/app/models/diliboard';
import { EditContentComponent } from 'src/app/modules/diliboard/components/content/edit-content/edit-content.component';
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { selectUser } from 'src/app/modules/user/store/user.selector';
import { UserData } from 'src/app/models/user';
import { ConfirmationPopUpComponent } from 'src/app/modules/shared-stuff/components/confirmation-pop-up/confirmation-pop-up.component';
import { TranslateService } from '@ngx-translate/core';
import { DeleteBoardContent } from '../../../store/diliboard.actions';

@Component({
    selector: 'app-overlay',
    templateUrl: './overlay.component.html',
    styleUrls: ['./overlay.component.css']
})
export class OverlayComponent implements OnInit, OnDestroy {
    video: Diliboard;
    user$: Observable<UserData>;
    showDownload: boolean = false;

    private subscription: Subscription = new Subscription();

    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        private dialog: MatDialog,
        ga: Angulartics2GoogleAnalytics,
        private store: Store<any>,
        private translate: TranslateService
    ) {
        ga.eventTrack('/', {
            category: 'board',
            label: `board/${data.video.id}`
        });
        ga.pageTrack(`board/${data.video.id}`);
        this.video = data.video;
        this.showDownload = this.video.type.includes('GenericFileContent');
    }

    ngOnInit() {
        this.user$ = this.store.pipe(select(selectUser));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    closeDialog(): void {
        this.dialog.closeAll();
    }

    checkUserRules(userId: number): boolean {
        if (!!userId) {
            return userId === this.video.user_id;
        } else {
            return false;
        }
    }

    openDialog(params: string): void {
        if (params === 'edit') {
            this.dialog.open(EditContentComponent, {
                panelClass: 'edit-dialog',
                data: this.video
            });
        } else if (params === 'delete') {
            let message: string;

            let subscription = this.translate
                .get('diliboard.remove-diliboard', {
                    name: this.video.title
                })
                .subscribe((res) => (message = res));

            this.subscription.add(subscription);

            subscription = this.dialog
                .open(ConfirmationPopUpComponent, {
                    width: '350px',
                    data: {
                        message
                    }
                })
                .afterClosed()
                .subscribe((res: boolean) => {
                    if (res) {
                        this.store.dispatch(
                            new DeleteBoardContent({
                                boardId: this.video.diliboard_id,
                                contentId: this.video.id
                            })
                        );
                    }
                });

            this.subscription.add(subscription);
        } else if (params === 'download') {
            window.open(this.video.file, '_blank');
        }
    }

    getView(): string {
        if (this.video.type.includes('DescriptionWithLink')) {
            return 'descriptionWithLink';
        } else if (this.video.type.includes('Embedded')) {
            return 'embedded';
        }
    }

    openLink(): void {
        window.open(this.video.original_content_url, '_blank');
    }

    copyUrl(): void {
        const item: string = this.video.original_content_url;
        document.addEventListener('copy', (e: ClipboardEvent) => {
            e.clipboardData.setData('text/plain', item);
            e.preventDefault();
            document.removeEventListener('copy', null);
        });
        document.execCommand('copy');
    }
}
