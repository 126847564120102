import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class CookiesService {
    showCookieBanner: BehaviorSubject<boolean>;
    constructor() {
        this.showCookieBanner = new BehaviorSubject<boolean>(
            !(this.getCookie('cookieConsent') === 'accepted')
        );
    }

    public cookieAccepted(): void {
        this.showCookieBanner.next(false);
        this.setCookie('cookieConsent', 'accepted', 365);
    }

    public deleteCookie(name) {
        document.cookie = name + '=;expires=Mon, 01 Mar 1980 00:00:01 GMT;';
    }

    public getCookie(name: string) {
        const ca: Array<string> = document.cookie.split(';');
        const caLen: number = ca.length;
        const cookieName = `${name}=`;
        let c: string;

        for (let i = 0; i < caLen; i += 1) {
            c = ca[i].replace(/^\s+/g, '');
            if (c.indexOf(cookieName) === 0) {
                return c.substring(cookieName.length, c.length);
            }
        }
        return '';
    }

    public setCookie(
        name: string,
        value: string,
        expireDays: number,
        path: string = ''
    ) {
        const d: Date = new Date();
        d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
        const expires = `expires=${d.toUTCString()}`;
        const cpath = path ? `; path=${path}` : '';
        document.cookie = `${name}=${value}; ${expires}${cpath}`;
    }

    deleteAllCookies() {
        const cookies = document.cookie.split(';');

        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf('=');
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
        }
    }
}
