<app-account-info
    [type]="'search'"
    [searchTerm]="searchTerm"
></app-account-info>
<div
    class="container"
    [ngClass]="{ 'transparent-wrapper': (boards$ | async).length === 0 }"
>
    <app-board [boards]="boards$ | async" [type]="'search'"></app-board>
    <button
        class="load-more"
        *ngIf="
            !(loader$ | async) &&
            (boards$ | async).length > 0 &&
            (boards$ | async).length < (boardsCount$ | async)
        "
        (click)="loadMore()"
    >
        {{ 'actions.load-more' | translate }}
    </button>
</div>
<app-data-loader *ngIf="loader$ | async"></app-data-loader>
