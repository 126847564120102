import {
    Component,
    OnDestroy,
    OnInit,
    Output,
    EventEmitter
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
    AuthService,
    FacebookLoginProvider,
    GoogleLoginProvider
} from 'angularx-social-login';
import { Login } from 'src/app/models/user';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import {
    UserLoginSuccess,
    UserActionType,
    UserLogin,
    UserLoginFailed
} from '../../store/user.actions';
import { selectIsLoggedIn } from '../../store/user.selector';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
    loginForm: FormGroup;
    displayErrors: boolean = false;
    errorMessage: string = '';
    isLoggedIn: boolean = false;
    rememberMe = false;
    private subscription: Subscription = new Subscription();

    constructor(
        private loginBuilder: FormBuilder,
        private router: Router,
        private socialAuthService: AuthService,
        private _actions$: Actions,
        private store: Store<object>
    ) {}

    ngOnInit() {
        this.subscription.add(
            this.store.pipe(select(selectIsLoggedIn)).subscribe((loggedIn) => {
                this.isLoggedIn = loggedIn;
            })
        );
        this.setForm();
        if (this.isLoggedIn) {
            this.router.navigateByUrl('');
        }
        this.subscription.add(
            this.socialAuthService.authState.subscribe(() => {})
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    setForm(): void {
        this.loginForm = this.loginBuilder.group({
            email: ['', [Validators.email, Validators.required]],
            password: ['', [Validators.minLength(6), Validators.required]]
        });
    }

    login(): void {
        this.errorMessage = null;
        this.displayErrors = true;
        if (this.loginForm.value.email && this.loginForm.value.password) {
            const body: Login = {
                email: this.loginForm.value.email,
                password: this.loginForm.value.password
            };
            this.store.dispatch(
                new UserLogin({
                    loginData: body,
                    rememberLogin: this.rememberMe,
                    socialLogin: false
                })
            );

            this.subscription.add(
                this._actions$
                    .pipe(
                        ofType<UserLoginSuccess>(UserActionType.LOGIN_SUCCESS)
                    )
                    .subscribe(() => {
                        const invitationUrl: string = sessionStorage.getItem(
                            'invitation'
                        );
                        if (invitationUrl) {
                            sessionStorage.removeItem('invitation');
                            this.router.navigateByUrl(invitationUrl);
                        } else {
                            this.router.navigateByUrl('my-diligrams');
                        }
                    })
            );
            this.subscription.add(
                this._actions$
                    .pipe(ofType<UserLoginFailed>(UserActionType.LOGIN_FAILED))
                    .subscribe((action) => {
                        if (action.payload.errorMessage.status === 400) {
                            this.loginForm.controls['password'].setValue(null);
                            this.errorMessage = 'user.login.error-message';
                        }
                    })
            );
        }
    }

    navigateTo(params: string): void {
        this.router.navigate([`home/${params}`]);
    }

    socialLogin(socialPlatform: string): void {
        let socialPlatformProvider;
        if (socialPlatform === 'facebook') {
            socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
        } else if (socialPlatform === 'google') {
            socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
        }
        this.socialAuthService
            .signIn(socialPlatformProvider)
            .then((userData) => {
                const socialLogin: Login = {};
                if (socialPlatform === 'facebook') {
                    socialLogin.fb_token = userData['token'];
                    socialLogin.imageLink = userData['image'];
                } else if (socialPlatform === 'google') {
                    socialLogin.google_token = userData['authToken'];
                    socialLogin.imageLink = userData['photoUrl'];
                }
                this.store.dispatch(
                    new UserLogin({
                        loginData: socialLogin,
                        rememberLogin: false,
                        socialLogin: true
                    })
                );
                this.subscription.add(
                    this._actions$
                        .pipe(
                            ofType<UserLoginSuccess>(
                                UserActionType.LOGIN_SUCCESS
                            )
                        )
                        .subscribe(() => {
                            const invitationUrl: string = sessionStorage.getItem(
                                'invitation'
                            );
                            if (invitationUrl) {
                                sessionStorage.removeItem('invitation');
                                this.router.navigateByUrl(invitationUrl);
                            } else {
                                this.router.navigateByUrl('my-diligrams');
                            }
                        })
                );
            })
            .catch((error) => console.error(error));
    }
}
