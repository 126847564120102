import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subscription, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { selectIsLoggedIn } from 'src/app/modules/user/store/user.selector';

@Component({
    selector: 'app-terms-and-conditions',
    templateUrl: './terms-and-conditions.component.html',
    styleUrls: ['./terms-and-conditions.component.css']
})
export class TermAndConditionsComponent implements OnInit, OnDestroy {
    isLoggedIn$: Observable<boolean>;
    language: string;
    private subscription: Subscription = new Subscription();

    constructor(
        private translateService: TranslateService,
        private router: Router,
        private store: Store<any>
    ) {}

    ngOnInit() {
        this.language = this.translateService.currentLang;

        this.subscription.add(
            this.translateService.onLangChange.subscribe(
                (event: LangChangeEvent) => {
                    this.language = event.lang;
                }
            )
        );
        this.isLoggedIn$ = this.store.pipe(select(selectIsLoggedIn));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    navigateTo(link: string): void {
        this.router.navigate([link]);
    }
}
