import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-welcome-message',
    templateUrl: './welcome-message.component.html',
    styleUrls: ['./welcome-message.component.css']
})
export class WelcomeMessageComponent {
    @Input() type: string;
}
