import { Component, OnInit, Input } from '@angular/core';
import { LanguageService } from '../../../../services/language.service';
import { ILanguage } from '../../../../models/language.model';
import { IDropDown } from '../../../../models/dropdown.model';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-language',
    templateUrl: './language.component.html',
    styleUrls: ['./language.component.css']
})
export class LanguageComponent implements OnInit {
    supportedLanguages: ILanguage[];
    currentLanguage: IDropDown;
    placeholder: string = 'languages.placeholder';
    dropdownClass = 'language';
    @Input() show: Observable<string>;

    constructor(
        private languageService: LanguageService,
        private router: Router
    ) {
        if (this.router.url.indexOf('home') !== -1) {
            this.dropdownClass = 'home-language';
        }
    }

    ngOnInit(): void {
        this.supportedLanguages = this.languageService.getSupportedLanguages();
        this.currentLanguage = {
            value: this.languageService.getCurrentLanguage(),
            translation: `languages.${this.languageService.getCurrentLanguage()}`
        };
    }

    buildDropDownValues(): IDropDown[] {
        const dropDownValues: IDropDown[] = [];
        this.supportedLanguages.forEach((value) => {
            dropDownValues.push({
                value: value.id,
                translation: `languages.${value.id}`
            });
        });
        return dropDownValues;
    }

    setLanguage(event: IDropDown): void {
        this.languageService.setLanguage(event.value);
    }
}
