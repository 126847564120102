<div class="account-wrapper">
    <div class="background-account"></div>
    <div class="container account-info">
        <div class="user-intro">
            <div class="user-data">
                <h1>{{ 'user.details.title' | translate }}</h1>
                <div class="user">
                    <img
                        [src]="
                            (diligramUser$ | async).image_url_medium
                                ? (diligramUser$ | async).image_url_medium
                                : '/assets/img/icons/guest_icon.svg'
                        "
                        class="profile-icon"
                        [ngClass]="{
                            'user-image': (diligramUser$ | async)
                                .image_url_medium
                        }"
                    />
                    <span class="user-name">
                        {{
                            capitalize((diligramUser$ | async).first_name) +
                                ' ' +
                                capitalize((diligramUser$ | async).last_name)
                        }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container data-container main-container">
    <form [formGroup]="userForm">
        <div class="profile-wrapper">
            <div class="part first-part">
                <h3 class="title">{{ 'user.details.edit' | translate }}</h3>
                <label>{{ 'user.details.name' | translate }}</label>
                <input type="text" formControlName="first_name" />
                <label>{{ 'user.details.surname' | translate }}</label>
                <input type="text" formControlName="last_name" />
                <label>{{ 'user.details.email' | translate }}</label>
                <input type="text" type="email" formControlName="email" />
            </div>
            <div class="part second-part">
                <h3 class="title">
                    {{ 'user.details.change-password' | translate }}
                </h3>
                <label>{{
                    'user.reset-password.new-password' | translate
                }}</label>
                <input
                    type="password"
                    formControlName="password"
                    (change)="onPasswordChange()"
                />
                <label>{{
                    'user.details.confirm-new-password' | translate
                }}</label>
                <input
                    type="password"
                    formControlName="password_confirmation"
                    (change)="onPasswordChange()"
                />
                <label *ngIf="password_confirmation.hasError('mismatch')"
                    >Password do not match.</label
                >
            </div>
        </div>
        <div class="description">
            <h3 class="title">{{ 'user.details.edit-profile' | translate }}</h3>
            <textarea formControlName="profile_text"></textarea>
        </div>
    </form>
</div>
<div class="container data-container action">
    <button
        class="btn-primary center-btn"
        [disabled]="
            userForm.invalid || password_confirmation.hasError('mismatch')
        "
        (click)="updateUser()"
    >
        {{ 'user.save' | translate }}
    </button>
    <button class="btn btn-transparent" (click)="setForm()">
        {{ 'user.cancel' | translate }}
    </button>
</div>
