<div
    class="account-wrapper"
    [ngClass]="{
        'my-classroom-view': checkIfRouteIsMyDiligrams()
    }"
    #accountWrapper
>
    <div
        class="background-account"
        [ngClass]="{
            'board-view': checkIfRouteIsBoard()
        }"
        [style.height]="accountWrapperHeight + 'px'"
    ></div>
    <div class="container">
        <div class="user-intro">
            <div class="user-data">
                <h1 *ngIf="type === 'search'">
                    {{ 'modules.account-info.search' | translate }}:
                    {{ searchTerm }}
                </h1>
                <h1 *ngIf="checkIfRouteIsMyDiligrams()">
                    {{ 'modules.account-info.my-classroom' | translate }}
                </h1>
                <h1 *ngIf="checkIfRouteIsBoard()">
                    {{ board.title }}
                </h1>
                <div class="user" *ngIf="checkIfRouteIsBoard()">
                    <img
                        [src]="
                            board?.owner?.image_url_medium
                                ? board?.owner?.image_url_medium
                                : '/assets/img/icons/guest_icon.svg'
                        "
                        class="profile-icon"
                        [ngClass]="{
                            'user-image': board?.owner?.image_url_medium
                        }"
                    />
                    <span class="user-name">
                        {{
                            capitalize(board?.owner?.first_name) +
                                ' ' +
                                capitalize(board?.owner?.last_name)
                        }}
                    </span>
                </div>
            </div>
        </div>
        <div class="board-statistic-wrapper" *ngIf="checkIfRouteIsBoard()">
            <!-- <div class="board-statistic">
                <span class="bolded">{{ board.diliboard_contents_count }}</span>
                <span class="hide-mobile">
                    {{
                        board.diliboard_contents_count === 1
                            ? ' ' + ('modules.account-info.item' | translate)
                            : ' ' + ('modules.account-info.items' | translate)
                    }}</span
                >
                <span class="hide-desktop">
                    <img src="/assets/img/icons/union.svg"
                /></span>
            </div> -->
            <div class="board-statistic" *ngIf="!board.user_role">
                <span class="bolded">{{
                    !!(totalCollaborators$ | async)
                        ? (totalCollaborators$ | async)
                        : 0
                }}</span>
                <span class="hide-mobile">{{
                    'modules.account-info.collaborators' | translate
                }}</span>
                <span class="hide-desktop">
                    <img src="/assets/img/icons/users.svg"
                /></span>
            </div>
            <div class="board-statistic" *ngIf="!board.user_role">
                <span class="bolded">{{
                    !!(totalViewers$ | async) ? (totalViewers$ | async) : 0
                }}</span>
                <span class="hide-mobile">{{
                    'modules.account-info.viewers' | translate
                }}</span>
                <span class="hide-desktop">
                    <img src="/assets/img/icons/viewes.svg"
                /></span>
            </div>
            <ng-template
                [ngIf]="
                    board.capabilities.invite_users &&
                    board.capabilities.list_users
                "
            >
                <button
                    class="btn btn-transparent add-remove"
                    (click)="openDialog('add-friend')"
                >
                    {{ 'modules.account-info.members' | translate
                    }}{{
                        boardUsers.length > 0
                            ? '  (' + boardUsers.length + ')'
                            : ''
                    }}
                </button>
            </ng-template>
        </div>
    </div>
</div>
