import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { UserImage } from 'src/app/models/user';
import { Store } from '@ngrx/store';
import { UserUploadImage } from '../../store/user.actions';

@Component({
    selector: 'app-user-photo',
    templateUrl: './user-photo.component.html',
    styleUrls: ['./user-photo.component.css']
})
export class UserPhotoComponent implements OnDestroy {
    image: any;
    private subscription: Subscription = new Subscription();

    constructor(private dialog: MatDialog, private store: Store<any>) {}

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    changeListener($event): void {
        this.readThis($event.target);
    }

    saveImage(): void {
        const userImage: UserImage = {
            image: this.image
        };
        this.store.dispatch(new UserUploadImage({ userImage: userImage }));
        this.closeDialog();
    }

    closeDialog(): void {
        this.image = null;
        this.dialog.closeAll();
    }

    private readThis(inputValue: any): void {
        const file: File = inputValue.files[0];

        const fileReader: FileReader = new FileReader();

        fileReader.onloadend = (e) => {
            this.image = fileReader.result;
        };
        fileReader.readAsDataURL(file);
    }
}
