import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/services/language.service';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { selectIsLoggedIn } from './modules/user/store/user.selector';
import { CookiesService } from './services/cookie.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    isLoggedIn$: Observable<boolean>;
    public mobile: boolean = false;
    public socialLogin: boolean = false;
    public rememberLogin: boolean = false;
    public showPadding: boolean = false;
    showCookieBanner$: Observable<boolean>;

    constructor(
        private router: Router,
        private languageService: LanguageService,
        private store: Store<any>,
        private cookiesService: CookiesService
    ) {
        const language = !!this.cookiesService.getCookie('displayLanguage')
            ? this.cookiesService.getCookie('displayLanguage')
            : 'en';
        this.languageService.setLanguage(language);
        this.isLoggedIn$ = this.store.pipe(select(selectIsLoggedIn));
        this.showCookieBanner$ = this.cookiesService.showCookieBanner;
    }

    checkIfPageIsHomePage(): boolean {
        return this.router.url.includes('home');
    }

    checkIfPageIsImprint(): boolean {
        return this.router.url === '/imprint';
    }
}
