<div class="edit-dialog-wrapper">
    <img
        src="/assets/img/icons/cancel_icon.svg"
        alt="cancel_icon"
        class="close-icon"
        (click)="closeDialog()"
    />
    <h1 class="dialog-title">
        {{ 'modules.edit-board.title' | translate }}
    </h1>
    <span class="label">
        {{ 'modules.create-board.label-placeholder' | translate }}</span
    >
    <form class="board-form" [formGroup]="videoForm">
        <input
            [ngClass]="{ invalid: videoForm.invalid && videoForm.touched }"
            type="text"
            placeholder="{{
                'modules.edit-board.title-placeholder' | translate
            }}"
            formControlName="title"
            (keyup.enter)="saveVideo()"
        />
        <img
            src="/assets/img/icons/invalid-attention.svg"
            alt="invalid-attention"
            class="invalid-attention"
            *ngIf="videoForm.invalid && videoForm.touched"
        />
    </form>
    <button
        class="btn btn-primary"
        type="submit"
        [disabled]="!videoForm || videoForm.invalid"
        (click)="saveVideo()"
    >
        {{ 'modules.edit-board.save' | translate }}
    </button>
</div>
