import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserResetPassword } from '../../store/user.actions';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
    resetPasswordForm: FormGroup;
    isResetSuccess: boolean;
    token: string = null;
    private subscription: Subscription = new Subscription();

    constructor(
        private resetPasswordBuilder: FormBuilder,
        private store: Store<any>,
        private router: Router
    ) {}

    ngOnInit() {
        const url = window.location.href.split('?token=');
        const reset_password_token = url[1];
        if (!!reset_password_token) {
            this.token = reset_password_token;
            this.setForm();
        } else {
            this.router.navigateByUrl('home');
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    setForm(): void {
        this.resetPasswordForm = this.resetPasswordBuilder.group(
            {
                password: ['', [Validators.required]],
                confirmPassword: ['']
            },
            { validator: this.checkPasswords }
        );
    }

    checkPasswords(group: FormGroup) {
        const pass = group.controls.password.value;
        const confirmPass = group.controls.confirmPassword.value;

        return pass === confirmPass ? null : { notSame: true };
    }

    changePassword(): void {
        this.store.dispatch(
            new UserResetPassword({
                resetToken: this.token,
                newPassword: this.resetPasswordForm.value.password
            })
        );
        this.isResetSuccess = true;
        this.router.navigateByUrl('/home');
    }
}
